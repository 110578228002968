import dayjs from 'dayjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { AddTask, Campaign, Error, People, Upload } from '@mui/icons-material'
import { Alert, Badge, Box, Button, Card, CardContent, Stack, Typography } from '@mui/joy'
import { cleanupRequestStatus, getProjectDetails } from 'features/projectsSlice'
import { handleErrorMessage, isDateAfterOrToday, removeDuplicates, sortBy, standarizeStatus } from 'utils/helpers'
import { projectService } from 'services/projectsService'
import { DATE_FORMAT, dateFilters, dateFiltersOptions, PROJECT_JOIN_STATUS, PROJECT_TYPES, STATUS } from 'utils/config'
import { getAdminTasksHeaders } from 'utils/headers/getAdminTasksHeaders'
import routes from 'routes'
import useSearchParamValue from 'hooks/useSearchParamValue'
import Breadcrumbs from 'components/Breadcrumbs'
import EnchantedTable from 'components/tables/EnchantedTable'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import usePagination from 'hooks/usePagination'
import PageLoader from 'components/PageLoader'
import DeleteModal from 'components/DeleteModal'
import FilterSelect from 'components/tables/FilterSelect'
import FilterSearch from 'components/tables/FilterSearch'
import SmsProjectModal from 'components/SmsProjectModal'
import { getAdminStatus } from 'utils/getStatus'

const getSearchPlaceholder = (project) => {
  if (project.type === 1) return 'Szukaj... [nr, lokalizacja, pracownik]'
  else if (project.type === 2) return 'Szukaj... [nr, pracownik]'
  return 'Szukaj...'
}

const AdminProjectsDetailsView = () => {
  const pagination = usePagination()
  const shouldCleanLocalizationFilter = useRef(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)
  const [deleteTaskObject, setDeleteTaskObject] = useState(false)
  const [deleteTasksArray, setDeleteTasksArray] = useState(false)
  const [smsModalOpen, setSmsModalOpen] = useState(false)
  const [smsMessage, setSmsMessage] = useState(false)

  const [dateFilter, setDateFilter] = useSearchParamValue('date', dateFilters.current.value)
  const [localizationFilter, setLocalizationFilter] = useSearchParamValue('localization', '')
  const [statusFilter, setStatusFilter] = useSearchParamValue('status', '')
  const [searchFilter, setSearchFilter] = useSearchParamValue('search', '')

  const { projectId } = useParams()
  const { currentProject, loading } = useSelector((s) => s.projects)
  const { serverTime } = useSelector((s) => s.auth)

  const enchantedTasks =
    currentProject?.tasks.map((t) => ({ ...t, computedStatus: getAdminStatus(t, serverTime) })) || []

  // Reset state to prevent bugs (when lozalization is picked, but dateFilter remove matching task)
  useEffect(() => {
    if (shouldCleanLocalizationFilter.current) setLocalizationFilter('')
    else shouldCleanLocalizationFilter.current = true
  }, [dateFilter])

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const filteredTasks = useMemo(() => {
    if (!currentProject || !currentProject.tasks) return []

    let tempTasks = enchantedTasks

    if (dateFilter === dateFilters.current.value)
      tempTasks = tempTasks.filter((t) => isDateAfterOrToday(t.date, serverTime))

    if (statusFilter) tempTasks = tempTasks.filter((t) => t.computedStatus.label == statusFilter)

    // Type 1 specific
    if (currentProject.type === 1 && localizationFilter) {
      tempTasks = tempTasks.filter((t) => t.localization.toLowerCase() == localizationFilter.toLowerCase())
    }

    if (searchFilter)
      tempTasks = tempTasks.filter((t) =>
        [t.localization, t.computedAssignmentFullName, t.lp]
          .filter(Boolean)
          .map((s) => s.toLowerCase())
          .some((s) => s.includes(searchFilter.toLowerCase()))
      )

    return tempTasks
  }, [currentProject, serverTime, dateFilter, searchFilter, localizationFilter, statusFilter])

  const filterLocalizations = useMemo(() => {
    if (!currentProject || !currentProject.tasks) return []
    let tempTasks = enchantedTasks
    if (dateFilter === dateFilters.current.value)
      tempTasks = tempTasks.filter((t) => isDateAfterOrToday(t.date, serverTime))
    return removeDuplicates(tempTasks.map((t) => t.localization))
  }, [currentProject, serverTime, dateFilter])

  const filterStatuses = useMemo(() => {
    if (!currentProject || !currentProject.tasks) return []
    let tempTasks = enchantedTasks
    if (dateFilter === dateFilters.current.value)
      tempTasks = tempTasks.filter((t) => isDateAfterOrToday(t.date, serverTime))

    return removeDuplicates(tempTasks.map((t) => t.computedStatus.label))
  }, [currentProject, serverTime, dateFilter])

  const handleGoToTaskDetailsPage = (taskId) =>
    navigate(routes.adminProjectsDetailsTask.replace(':projectId', projectId).replace(':taskId', taskId), {
      state: { from: currentProject.name },
    })

  const handleGoToProjectTaskEditPage = (taskId) =>
    navigate(routes.adminProjectsEditTask.replace(':projectId', projectId).replace(':taskId', taskId), {
      state: { from: currentProject.name },
    })

  const handleSendSms = (values, helpers) => {
    setInnerLoading(true)
    projectService
      .adminSendSmsToProjectUsers(projectId, values)
      .then((res) => {
        console.log(res)
        helpers.resetForm()
        setSmsMessage('Wiadomość SMS została wysłana.')
        enqueueSnackbar('Wiadomość SMS została wysłana.', { variant: 'success' })
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  // Singles
  const handleSingleAction = (action, item, successMessage, successCb) => {
    setInnerLoading(true)
    action(projectId, item.id)
      .then((res) => {
        console.log(res)
        enqueueSnackbar(successMessage, { variant: 'success' })
        if (successCb) successCb()
        dispatch(getProjectDetails(projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleApproveTask = (task) =>
    handleSingleAction(projectService.adminApproveProjectTask, task, 'Zadanie zostało zaakceptowane.')

  const handleRevertApproveTask = (task) =>
    handleSingleAction(projectService.adminRevertApproveProjectTask, task, 'Cofnięto akceptacje zadania.')

  const handleDeleteTask = (task) =>
    handleSingleAction(projectService.deleteProjectTask, task, 'Zadanie zostało usunięte.', () =>
      setDeleteTaskObject(false)
    )

  // Bulks
  const handleBulkAction = (action, items, successMessage, errorMessage, successCb) => {
    setInnerLoading(true)
    action(
      projectId,
      items.map((item) => item.id)
    )
      .then((res) => {
        console.log(res)
        enqueueSnackbar(successMessage, { variant: 'success' })
        if (successCb) successCb()
        dispatch(getProjectDetails(projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 10000 })
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error', autoHideDuration: 10000 })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleApproveTaskBulk = (tasks) => {
    handleBulkAction(
      projectService.adminApproveProjectTasksBulk,
      tasks,
      'Wybrane zadania zostały zaakceptowane.',
      'W wyniku błędu żadne z wybranych zadań nie zostało zaakceptowane.'
    )
  }

  const handleDeleteTasksBulk = (deleteTasksArray) => {
    handleBulkAction(
      projectService.deleteProjectTasksBulk,
      deleteTasksArray,
      'Wybrane zadania zostały usunięte.',
      'W wyniku błędu żadne z wybranych zadań nie zostało usunięte.',
      () => setDeleteTasksArray(false)
    )
  }

  if (!projectId) return <Navigate to={routes.adminProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  if (!currentProject) return <MainLayout></MainLayout>

  return (
    <MainLayout>
      <SmsProjectModal
        open={smsModalOpen}
        setObject={(val) => {
          setSmsModalOpen(val)
          setSmsMessage(false)
        }}
        handleSubmit={handleSendSms}
        loading={innerLoading}
      >
        <Typography>
          Wyślij wiadomość SMS do użytkowników projektu: <Typography color="primary">{currentProject?.name}</Typography>
        </Typography>
        {smsMessage && (
          <Alert color="success" variant="soft" sx={{ mt: 2, mb: 1 }}>
            {smsMessage}
          </Alert>
        )}
      </SmsProjectModal>
      <DeleteModal
        open={deleteTaskObject || deleteTasksArray}
        handleDelete={() => {
          if (deleteTaskObject) handleDeleteTask(deleteTaskObject)
          else if (deleteTasksArray) handleDeleteTasksBulk(deleteTasksArray)
        }}
        loading={innerLoading}
        setObject={(val) => {
          setDeleteTaskObject(val)
          setDeleteTasksArray(val)
        }}
      >
        {deleteTaskObject && 'Czy jesteś pewny, ze chcesz usunąć zadanie?'}
        {deleteTasksArray && 'Czy jesteś pewny, ze chcesz usunąć wybrane zadania? '}
      </DeleteModal>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[{ label: 'Projekty', to: routes.adminProjects }, { label: currentProject?.name || '?' }]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Projekt:&nbsp;
          <Typography color="primary">{currentProject?.name}</Typography>
        </Typography>
        <Stack rowGap={1} columnGap={2} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
          <Button
            size="sm"
            component={Link}
            to={routes.adminProjectsCreateTasks.replace(':projectId', projectId)}
            color="primary"
            state={{ from: currentProject?.name }}
            startDecorator={<AddTask fontSize="small" />}
          >
            Dodaj zadania
          </Button>
          <Button
            size="sm"
            component={Link}
            to={routes.adminProjectsImportTasks.replace(':projectId', projectId)}
            color="primary"
            state={{ from: currentProject?.name }}
            variant="outlined"
            startDecorator={<Upload fontSize="small" />}
          >
            Importuj zadania
          </Button>
          <Button
            size="sm"
            color="warning"
            variant="outlined"
            startDecorator={<Campaign fontSize="small" />}
            onClick={() => setSmsModalOpen(true)}
          >
            Wyślij SMS
          </Button>
          {currentProject.type == 2 && (
            <Badge
              color="warning"
              variant="soft"
              size="sm"
              badgeContent={
                currentProject.joinRequests.length
                  ? `+${currentProject.joinRequests.filter((r) => r.status == PROJECT_JOIN_STATUS.pending.value).length}`
                  : null
              }
            >
              <Button
                size="sm"
                component={Link}
                to={routes.adminProjectsDetailsUsers.replace(':projectId', projectId)}
                color="primary"
                variant="outlined"
                state={{ from: currentProject?.name }}
                startDecorator={<People fontSize="small" />}
              >
                Uczestnicy{' '}
                {currentProject.joinRequests.filter((r) => r.status == PROJECT_JOIN_STATUS.approved.value).length}
              </Button>
            </Badge>
          )}
        </Stack>
      </Box>

      {currentProject && (
        <>
          {currentProject.infoBanner && (
            <Alert startDecorator={<Error />} variant="soft" color="warning" sx={{ whiteSpace: 'pre-line' }}>
              {currentProject.infoBanner}
            </Alert>
          )}
          <Card orientation="horizontal" variant="outlined">
            <CardContent>
              <Stack rowGap={0.5} columnGap={3} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
                <Typography level="body-sm">
                  <Typography color="primary" fontWeight={500}>
                    Typ projektu:
                  </Typography>{' '}
                  {PROJECT_TYPES.find((p) => p.value === currentProject.type)?.label}
                </Typography>
                <Typography level="body-sm">
                  <Typography color="primary" fontWeight={500}>
                    Lokalizacja:
                  </Typography>{' '}
                  {currentProject.location}
                </Typography>
                <Typography level="body-sm">
                  <Typography color="primary" fontWeight={500}>
                    Data rozpoczęcia:
                  </Typography>{' '}
                  {dayjs(currentProject.startDate).format(DATE_FORMAT)}
                </Typography>
                <Typography level="body-sm">
                  <Typography color="primary" fontWeight={500}>
                    Data zakończenia:
                  </Typography>{' '}
                  {dayjs(currentProject.endDate).format(DATE_FORMAT)}
                </Typography>
                {currentProject.type === 1 && (
                  <Typography level="body-sm">
                    <Typography color="primary" fontWeight={500}>
                      Stawka godzinowa brutto:
                    </Typography>{' '}
                    {currentProject.hourlyRate} zł/h
                  </Typography>
                )}
              </Stack>
              <Typography level="body-sm">
                <Typography color="primary" fontWeight={500}>
                  Opis projektu:
                </Typography>{' '}
                {currentProject.description}
              </Typography>
            </CardContent>
          </Card>
        </>
      )}

      <FiltersWrapper
        renderSearch={
          <FilterSearch
            label="Wyszukiwanie zadania"
            placeholder={getSearchPlaceholder(currentProject)}
            value={searchFilter}
            setValue={setSearchFilter}
          />
        }
        renderFilters={
          <>
            {currentProject.type === 1 && (
              <FilterSelect
                label="Filtrowanie po lokalizacji"
                placeholder="Wszystkie"
                options={sortBy(
                  filterLocalizations.map((l) => ({ label: l, value: l })),
                  'value'
                )}
                value={localizationFilter}
                setValue={setLocalizationFilter}
                sx={{ ml: { sm: 'auto' } }}
                allowDelete={true}
              />
            )}
            <FilterSelect
              label="Filtrowanie po statusie"
              placeholder="Wszystkie"
              options={sortBy(
                filterStatuses.map((l) => ({ label: l, value: l })),
                'value'
              )}
              value={statusFilter}
              setValue={setStatusFilter}
              sx={{ ml: { sm: 'auto' } }}
              allowDelete={true}
            />
            <FilterSelect
              label="Filtrowanie po dacie"
              placeholder={dateFilters.all.label}
              options={dateFiltersOptions}
              value={dateFilter}
              setValue={setDateFilter}
            />
          </>
        }
      />

      <EnchantedTable
        headers={currentProject ? getAdminTasksHeaders(currentProject) : []}
        data={filteredTasks}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        actions={[
          {
            label: 'Podgląd zadania',
            color: 'primary',
            func: (el) => handleGoToTaskDetailsPage(el.id),
          },
          {
            label: 'Zaakceptuj',
            color: 'success',
            divider: true,
            func: handleApproveTask,
            checkIfDisable: (el) => !el.assignment || standarizeStatus(el.status) === STATUS.done.value,
          },
          {
            label: 'Cofnij akceptację',
            color: 'warning',
            divider: true,
            func: handleRevertApproveTask,
            checkIfDisable: (el) => standarizeStatus(el.status) !== STATUS.done.value,
          },
          {
            label: 'Edytuj zadanie',
            color: 'warning',
            divider: true,
            func: (el) => handleGoToProjectTaskEditPage(el.id),
            checkIfDisable: (el) => standarizeStatus(el.status) === STATUS.done.value,
          },
          { label: 'Usuń zadanie', color: 'danger', divider: true, func: (el) => setDeleteTaskObject(el) },
        ]}
        bulkActions={[
          {
            label: 'Zaakceptuj',
            color: 'success',
            func: handleApproveTaskBulk,
          },
          {
            label: 'Usuń zadania',
            color: 'danger',
            divider: true,
            func: (selectedItems) => setDeleteTasksArray(selectedItems),
          },
        ]}
        noDataText="Brak zadań do wyświetlenia."
      />
    </MainLayout>
  )
}

export default AdminProjectsDetailsView
