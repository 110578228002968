/* eslint-disable react/prop-types */
import { AspectRatio, Card, CardContent, Chip, Stack, Typography } from '@mui/joy'
import { InsertDriveFileOutlined } from '@mui/icons-material'
import { displayHumanBytes } from 'utils/helpers'

export default function FileUpload({
  icon,
  handleDelete,
  handleDownload = null,
  fileName,
  fileSize,
  sx,
  isLocal,
  ...other
}) {
  return (
    <Card
      size="sm"
      variant={isLocal ? 'outlined' : 'soft'}
      orientation="horizontal"
      {...other}
      sx={[
        {
          gap: 1,
          alignItems: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <AspectRatio
        ratio="1"
        variant="soft"
        color="neutral"
        sx={{ minWidth: 28, borderRadius: '50%', '--Icon-fontSize': '16px' }}
      >
        <div>{icon ?? <InsertDriveFileOutlined color={handleDownload ? 'primary' : 'success'} fontSize="small" />}</div>
      </AspectRatio>
      <CardContent>
        <Typography level="body-xs" onClick={handleDownload} sx={{ cursor: handleDownload ? 'pointer' : 'default' }}>
          <Typography
            color={handleDownload ? 'primary' : 'success'}
            level="body-xs"
            sx={{ textDecoration: handleDownload ? 'underline' : 'none' }}
          >
            {fileName}
          </Typography>{' '}
          <br />
          <Typography color="warning" sx={{ whiteSpace: 'nowrap' }}>
            {displayHumanBytes(fileSize)}
          </Typography>
        </Typography>
      </CardContent>
      {handleDelete && (
        <Stack>
          <Chip size="sm" variant="outlined" color="danger" onClick={handleDelete}>
            Usuń
          </Chip>
        </Stack>
      )}
    </Card>
  )
}
