import customAxios, { customAxiosFile } from 'services/customAxios'

const urls = {
  projects: 'projects',
  projectsDetails: 'projects/:projectId',

  adminAddProject: 'admin/projects',
  adminEditProject: 'admin/projects/:projectId',
  adminDeleteProject: 'admin/projects/:projectId',

  adminApproveProjectJoin: 'admin/projects/:projectId/approve-join/:joinRequestId',
  adminApproveProjectJoinBulk: 'admin/projects/:projectId/bulk-approve-join',
  adminRejectProjectJoin: 'admin/projects/:projectId/reject-join/:joinRequestId',
  adminRejectProjectJoinBulk: 'admin/projects/:projectId/bulk-reject-join',
  adminDeleteProjectJoin: 'admin/projects/:projectId/join-request/:joinRequestId',
  adminDeleteProjectJoinBulk: 'admin/projects/:projectId/bulk-join-request',

  adminAddProjectTasks: 'admin/projects/:projectId/tasks',
  adminUploadProjectTaskFiles: 'admin/:projectId/tasks/:taskId/files',
  adminDeleteProjectTaskFile: 'admin/:projectId/tasks/:taskId/files/:fileId',

  adminApproveProjectTask: 'admin/projects/:projectId/tasks/:taskId/approve',
  adminApproveProjectTasksBulk: 'admin/projects/:projectId/tasks/bulk-approve',
  adminRevertApproveProjectTask: 'admin/projects/:projectId/tasks/:taskId/revert',

  adminImportProjectTasks: 'admin/projects/:projectId/tasks/import-csv',
  adminTaskDetails: 'admin/projects/:projectId/tasks/:taskId',
  adminTaskReserveUsers: 'admin/projects/:projectId/tasks/:taskId/reserve-list',
  adminRemoveTaskReserveUser: 'admin/projects/:projectId/tasks/:taskId/reserve-list/:userId',

  adminAssignProjectTaskUser: 'admin/projects/:projectId/tasks/:taskId/assign/:userId',
  adminUnassignProjectTaskUser: 'admin/projects/:projectId/tasks/:taskId/unassign/:userId',

  adminUpdateProjectTask: 'admin/projects/:projectId/tasks/:taskId',
  adminDeleteProjectTask: 'admin/projects/:projectId/tasks/:taskId',
  adminDeleteProjectTasksBulk: 'admin/projects/:projectId/tasks/bulk-delete',

  adminDownloadImportSample: 'admin/projects/sample-csv',
  adminSendSmsToProjectUsers: 'admin/projects/:projectId/send-sms',

  userProjectJoin: 'projects/:projectId/join',
  userTaskApply: 'projects/:projectId/tasks/:taskId/apply',
  userTaskApplyReserve: 'projects/:projectId/tasks/:taskId/apply-reserve',
  userTaskLeaveReserve: 'projects/:projectId/tasks/:taskId/leave-reserve',
  userUploadProjectTaskFiles: 'projects/:projectId/tasks/:taskId/files',
  userTaskDetails: 'projects/:projectId/tasks/:taskId',

  // common
  downloadProjectTaskfile: 'projects/:projectId/tasks/:taskId/files/:fileId',
}

const getProjects = ({ params = null }) =>
  customAxios({
    method: 'get',
    url: urls.projects,
    params,
  })

const getProjectDetails = (projectId) =>
  customAxios({
    method: 'get',
    url: urls.projectsDetails.replace(':projectId', projectId),
  })

const deleteProject = (projectId) =>
  customAxios({
    method: 'delete',
    url: urls.adminDeleteProject.replace(':projectId', projectId),
  })

const adminApproveProjectJoin = (projectId, joinRequestId) =>
  customAxios({
    method: 'post',
    url: urls.adminApproveProjectJoin.replace(':projectId', projectId).replace(':joinRequestId', joinRequestId),
  })

const adminApproveProjectJoinBulk = (projectId, joinRequestIds) =>
  customAxios({
    method: 'post',
    url: urls.adminApproveProjectJoinBulk.replace(':projectId', projectId),
    data: { joinRequestIds },
  })

const adminRejectProjectJoin = (projectId, joinRequestId) =>
  customAxios({
    method: 'post',
    url: urls.adminRejectProjectJoin.replace(':projectId', projectId).replace(':joinRequestId', joinRequestId),
  })

const adminRejectProjectJoinBulk = (projectId, joinRequestIds) =>
  customAxios({
    method: 'post',
    url: urls.adminRejectProjectJoinBulk.replace(':projectId', projectId),
    data: { joinRequestIds },
  })

const adminDeleteProjectJoin = (projectId, joinRequestId) =>
  customAxios({
    method: 'delete',
    url: urls.adminDeleteProjectJoin.replace(':projectId', projectId).replace(':joinRequestId', joinRequestId),
  })

const adminDeleteProjectJoinBulk = (projectId, joinRequestIds) =>
  customAxios({
    method: 'delete',
    url: urls.adminDeleteProjectJoinBulk.replace(':projectId', projectId),
    data: { joinRequestIds },
  })

const adminGetTaskDetails = (projectId, taskId) =>
  customAxios({
    method: 'get',
    url: urls.adminTaskDetails.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const getTaskReserveUsers = (projectId, taskId) =>
  customAxios({
    method: 'get',
    url: urls.adminTaskReserveUsers.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const adminRemoveProjectTaskReserveUser = (projectId, taskId, userId) =>
  customAxios({
    method: 'delete',
    url: urls.adminRemoveTaskReserveUser
      .replace(':projectId', projectId)
      .replace(':taskId', taskId)
      .replace(':userId', userId),
  })

const adminAssignProjectTaskUser = ({ projectId, taskId, userId, force = null }) =>
  customAxios({
    method: 'post',
    url: urls.adminAssignProjectTaskUser
      .replace(':projectId', projectId)
      .replace(':taskId', taskId)
      .replace(':userId', userId),
    params: { force },
  })

const adminUnassignProjectTaskUser = (projectId, taskId, userId) =>
  customAxios({
    method: 'delete',
    url: urls.adminUnassignProjectTaskUser
      .replace(':projectId', projectId)
      .replace(':taskId', taskId)
      .replace(':userId', userId),
  })

const createProject = (data) =>
  customAxios({
    method: 'post',
    url: urls.adminAddProject,
    data,
  })

const updateProject = (projectId, data) =>
  customAxios({
    method: 'put',
    url: urls.adminEditProject.replace(':projectId', projectId),
    data,
  })

const addProjectTasks = (projectId, data) =>
  customAxios({
    method: 'post',
    url: urls.adminAddProjectTasks.replace(':projectId', projectId),
    data,
  })

const adminUploadTaskFiles = (projectId, taskId, files) =>
  customAxiosFile({
    method: 'post',
    url: urls.adminUploadProjectTaskFiles.replace(':projectId', projectId).replace(':taskId', taskId),
    data: { files },
  })

const adminDeleteTaskFile = (projectId, taskId, fileId) =>
  customAxios({
    method: 'delete',
    url: urls.adminDeleteProjectTaskFile
      .replace(':projectId', projectId)
      .replace(':taskId', taskId)
      .replace(':fileId', fileId),
  })

const importProjectTasks = (projectId, data) =>
  customAxiosFile({
    method: 'post',
    url: urls.adminImportProjectTasks.replace(':projectId', projectId),
    data,
  })

const adminSendSmsToProjectUsers = (projectId, data) =>
  customAxios({
    method: 'post',
    url: urls.adminSendSmsToProjectUsers.replace(':projectId', projectId),
    data,
  })

// User
const userProjectJoin = (projectId) =>
  customAxios({
    method: 'post',
    url: urls.userProjectJoin.replace(':projectId', projectId),
  })
const userTaskApply = (projectId, taskId) =>
  customAxios({
    method: 'post',
    url: urls.userTaskApply.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const userTaskApplyReserve = (projectId, taskId) =>
  customAxios({
    method: 'post',
    url: urls.userTaskApplyReserve.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const userTaskLeaveReserve = (projectId, taskId) =>
  customAxios({
    method: 'delete',
    url: urls.userTaskLeaveReserve.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const userUploadTaskFiles = (projectId, taskId, data) =>
  customAxiosFile({
    method: 'post',
    url: urls.userUploadProjectTaskFiles.replace(':projectId', projectId).replace(':taskId', taskId),
    data,
  })

const userGetTaskDetails = (projectId, taskId) =>
  customAxios({
    method: 'get',
    url: urls.userTaskDetails.replace(':projectId', projectId).replace(':taskId', taskId),
  })

// USER END
const adminApproveProjectTask = (projectId, taskId) =>
  customAxios({
    method: 'post',
    url: urls.adminApproveProjectTask.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const adminApproveProjectTasksBulk = (projectId, taskIds) =>
  customAxios({
    method: 'post',
    url: urls.adminApproveProjectTasksBulk.replace(':projectId', projectId),
    data: { taskIds },
  })

const adminRevertApproveProjectTask = (projectId, taskId) =>
  customAxios({
    method: 'put',
    url: urls.adminRevertApproveProjectTask.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const adminUpdateProjectTask = (projectId, taskId, data) =>
  customAxios({
    method: 'put',
    url: urls.adminUpdateProjectTask.replace(':projectId', projectId).replace(':taskId', taskId),
    data,
  })

const deleteProjectTask = (projectId, taskId) =>
  customAxios({
    method: 'delete',
    url: urls.adminDeleteProjectTask.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const deleteProjectTasksBulk = (projectId, taskIds) =>
  customAxios({
    method: 'delete',
    url: urls.adminDeleteProjectTasksBulk.replace(':projectId', projectId),
    data: { taskIds },
  })

const downloadImportSample = (type) =>
  customAxios({
    method: 'get',
    url: urls.adminDownloadImportSample,
    params: { type },
    responseType: 'blob',
  })

const downloadProjectTaskFile = (projectId, taskId, fileId) =>
  customAxios({
    method: 'get',
    url: urls.downloadProjectTaskfile
      .replace(':projectId', projectId)
      .replace(':taskId', taskId)
      .replace(':fileId', fileId),
    responseType: 'blob',
  })

export const projectService = {
  getProjects,
  getProjectDetails,
  deleteProject,
  adminApproveProjectJoin,
  adminApproveProjectJoinBulk,
  adminRejectProjectJoin,
  adminRejectProjectJoinBulk,
  adminDeleteProjectJoin,
  adminDeleteProjectJoinBulk,
  adminGetTaskDetails,
  getTaskReserveUsers,
  adminRemoveProjectTaskReserveUser,
  adminAssignProjectTaskUser,
  adminUnassignProjectTaskUser,
  createProject,
  updateProject,
  addProjectTasks,
  adminUploadTaskFiles,
  adminDeleteTaskFile,
  importProjectTasks,
  adminSendSmsToProjectUsers,
  userProjectJoin,
  userTaskApply,
  userTaskApplyReserve,
  userTaskLeaveReserve,
  userUploadTaskFiles,
  userGetTaskDetails,
  adminApproveProjectTask,
  adminRevertApproveProjectTask,
  adminApproveProjectTasksBulk,
  adminUpdateProjectTask,
  deleteProjectTask,
  deleteProjectTasksBulk,
  downloadImportSample,
  downloadProjectTaskFile,
}
