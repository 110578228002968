import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/joy'
import { projectService } from 'services/projectsService'
import { handleErrorMessage } from 'utils/helpers'
import { cleanupRequestStatus, getProjectDetails } from 'features/projectsSlice'
import routes from 'routes'
import uploadTaskFilesUserSchema from 'validations/project/uploadTaskFilesUserSchema'
import MainLayout from 'components/templates/MainLayout'
import Breadcrumbs from 'components/Breadcrumbs'
import PageLoader from 'components/PageLoader'
import UserFilesUploadSubView from './common/UserFilesUploadSubView'

const UserTaskUploadView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { projectId, taskId } = useParams()
  const { state } = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)
  const { currentProject, loading } = useSelector((s) => s.projects)

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const handleUploadFiles = (values) => {
    setInnerLoading(true)

    projectService
      .userUploadTaskFiles(projectId, taskId, values)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Pliki z rozwiązaniem zostały wysłane', { variant: 'success' })
        navigate(routes.userProjectsDetailsTask.replace(':projectId', projectId).replace(':taskId', taskId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const formik = useFormik({
    initialValues: { files: [] },
    validationSchema: uploadTaskFilesUserSchema,
    onSubmit: handleUploadFiles,
  })

  if (!projectId || !taskId) return <Navigate to={routes.userProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  if (!currentProject) return <MainLayout></MainLayout>

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.userProjects },
            {
              label: currentProject?.name || state?.from || projectId,
              to: routes.userProjectsDetails.replace(':projectId', projectId),
            },
            { label: 'Importuj' },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Wyślij rozwiązanie
        </Typography>
      </Box>

      <UserFilesUploadSubView formik={formik} loading={innerLoading} />
    </MainLayout>
  )
}

export default UserTaskUploadView
