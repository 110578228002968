import PropTypes from 'prop-types'
import { FieldArray, useFormik, FormikProvider } from 'formik'
import { Box, Button, Card, CardActions, CardOverflow, Chip, Divider, Sheet, Stack, Typography } from '@mui/joy'
import { Info } from '@mui/icons-material'
import { newTaskType1Object } from 'utils/config'
import { addTasksType1Schema } from 'validations/project/addTasksSchema'
import CustomDatePickerControl from 'components/forms/CustomDatePickerControl'
import CustomInputControl from 'components/forms/CustomInputControl'
import CustomDateTimePickerControl from 'components/forms/CustomDateTimePickerControl'
import dayjs from 'dayjs'

const TasksFormType1SubView = ({
  handleSubmit,
  initialTasks = [{ ...newTaskType1Object }],
  loading = false,
  singleMode = false,
}) => {
  const formik = useFormik({
    initialValues: {
      tasks: initialTasks,
    },
    validationSchema: addTasksType1Schema,
    onSubmit: handleSubmit,
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Card
          sx={{
            display: 'flex',
            maxWidth: '100%',
            width: '100%',
            mx: 'auto',
            px: { xs: 2, md: 3 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md" startDecorator={<Info />} color="primary">
              Uzupełnij dane {singleMode ? 'zadania' : 'zadań'}
            </Typography>
          </Box>
          <Divider />
          <FieldArray
            name="tasks"
            render={(arrayHelpers) => (
              <>
                {formik.values.tasks && formik.values.tasks.length > 0 && (
                  <Stack direction="column" spacing={2} sx={{ mt: 1, mb: 1 }}>
                    {[...formik.values.tasks].map((task, index) => (
                      <div key={index}>
                        <Sheet variant="plain" color="primary">
                          <Stack direction="row" spacing={2} sx={{ mb: 1, justifyContent: 'space-between' }}>
                            {!singleMode && (
                              <Typography level="title-sm" color="primary">
                                Zadanie {index + 1}
                              </Typography>
                            )}
                            {index > 0 && (
                              <Chip
                                size="sm"
                                variant="outlined"
                                color="danger"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Usuń
                              </Chip>
                            )}
                          </Stack>
                          <Stack
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: {
                                xs: 'repeat( auto-fit, minmax(320px, 1fr) )',
                                xl: 'repeat( 4, 1fr )',
                              },
                              gap: 2,
                              marginBottom: 2,
                            }}
                          >
                            <CustomInputControl
                              name={['tasks', index, 'lp']}
                              label="Numer porządkowy"
                              formik={formik}
                            />
                            <CustomDatePickerControl name={['tasks', index, 'date']} label="Data" formik={formik} />
                            <CustomInputControl
                              name={['tasks', index, 'localization']}
                              label="Przystanek/Stacja"
                              formik={formik}
                            />
                            <CustomInputControl name={['tasks', index, 'hours']} label="Godziny" formik={formik} />
                            <CustomInputControl
                              type="number"
                              name={['tasks', index, 'hoursCount']}
                              label="Liczba godzin"
                              formik={formik}
                            />
                            <CustomInputControl
                              name={['tasks', index, 'notes']}
                              label={
                                <Typography level="body-sm" textColor="text.primary">
                                  Uwagi&nbsp;
                                  <Typography level="body-xs" textColor="text.tertiary">
                                    - opcjonalnie
                                  </Typography>
                                </Typography>
                              }
                              formik={formik}
                            />
                            <CustomDateTimePickerControl
                              name={['tasks', index, 'publishedAt']}
                              minDate={dayjs()}
                              label={
                                <Typography level="body-sm" textColor="text.primary">
                                  Data publikacji&nbsp;
                                  <Typography level="body-xs" textColor="text.tertiary">
                                    - opcjonalnie
                                  </Typography>
                                </Typography>
                              }
                              formik={formik}
                              sx={{ flex: '1', minWidth: '1px' }}
                            />
                          </Stack>
                        </Sheet>
                      </div>
                    ))}
                  </Stack>
                )}
                {!singleMode && (
                  <CardActions sx={{ alignSelf: 'flex-start', pt: 0, my: 1 }}>
                    <Button
                      type="button"
                      onClick={() => arrayHelpers.push({ ...newTaskType1Object })}
                      size="sm"
                      variant="solid"
                    >
                      Dodaj kolejne zadanie
                    </Button>
                  </CardActions>
                )}
              </>
            )}
          />
          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 3 }}>
              <Button type="submit" disabled={!formik.dirty} size="sm" variant="solid" loading={loading}>
                {singleMode ? 'Zaktualizuj zadanie' : 'Dodaj zadania do projektu'}
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>
      </form>
    </FormikProvider>
  )
}

export const task1Schape = PropTypes.shape({
  lp: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  hoursCount: PropTypes.number.isRequired,
  localization: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  publishedAt: PropTypes.string,
})

TasksFormType1SubView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialTasks: PropTypes.arrayOf(task1Schape),
  loading: PropTypes.bool,
  singleMode: PropTypes.bool,
}

export default TasksFormType1SubView
