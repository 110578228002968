import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
} from '@mui/joy'
import { useDispatch, useSelector } from 'react-redux'
import { WarningRounded } from '@mui/icons-material'
import { cleanupRequestStatus as cleanUsers } from 'features/usersSlice'
import { cleanupRequestStatus as cleanProjects } from 'features/projectsSlice'
import { cleanupRequestStatus as cleanTasks } from 'features/tasksSlice'
import { cleanupRequestStatus as cleanBillings } from 'features/billingsSlice'

const GlobalErrorPopup = () => {
  const dispatch = useDispatch()
  const [error, setError] = useState(false)

  const { loading: projectsLoading, error: projectsError } = useSelector((s) => s.projects)
  const { loading: usersLoading, error: usersError } = useSelector((s) => s.users)
  const { loading: tasksLoading, error: tasksError } = useSelector((s) => s.tasks)
  const { loading: billingsLoading, error: billingsError } = useSelector((s) => s.billings)

  useEffect(() => {
    if (projectsError || usersError || tasksError || billingsError) setError(true)
    else setError(false)
  }, [projectsError, usersError, tasksError, billingsError])

  const handleCleanErrors = () => {
    setError(false)
    dispatch(cleanUsers())
    dispatch(cleanProjects())
    dispatch(cleanTasks())
    dispatch(cleanBillings())
  }

  const globalLoading = projectsLoading || usersLoading || tasksLoading || billingsLoading
  const globalError = projectsError || usersError || tasksError || billingsError

  return (
    <Modal open={error && !globalLoading} onClose={handleCleanErrors}>
      <ModalDialog variant="outlined" role="alertdialog" sx={{ width: '100%', maxWidth: '400px' }}>
        <ModalClose />
        <DialogTitle>
          <WarningRounded />
          Wystapił błąd
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Alert color="danger" variant="soft">
            {globalError}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleCleanErrors} loading={globalLoading}>
            Rozumiem
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}

export default GlobalErrorPopup
