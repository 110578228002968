import { Box, Tooltip, Typography } from '@mui/joy/'
import { Info } from '@mui/icons-material'
import PropTypes from 'prop-types'
import React from 'react'

const AdvancedTooltip = ({ title, description, children, iconColor = 'primary', hideIcon = false, sx }) => {
  return (
    <Tooltip
      variant="outlined"
      arrow
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 320,
            justifyContent: 'center',
            p: 1,
          }}
        >
          <Box sx={{ display: 'flex', gap: 1, width: '100%', mt: 0 }}>
            {!hideIcon && <Info color={iconColor} fontSize="small" />}
            <div>
              <Typography level="title-sm">{title}</Typography>
              {React.isValidElement(description) || Array.isArray(description) ? (
                description
              ) : (
                <Typography textColor="text.secondary" sx={{ fontSize: 'sm' }}>
                  {description}
                </Typography>
              )}
            </div>
          </Box>
        </Box>
      }
    >
      <Typography
        underline="none"
        sx={[{ display: 'flex', alignItems: 'center', justifyContent: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}
        endDecorator={<Info color={iconColor} fontSize="small" />}
      >
        {children}
      </Typography>
    </Tooltip>
  )
}

AdvancedTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
  sx: PropTypes.object,
  iconColor: PropTypes.string,
  hideIcon: PropTypes.bool,
}

export default AdvancedTooltip
