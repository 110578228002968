import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/joy'
import { projectService } from 'services/projectsService'
import { handleErrorMessage } from 'utils/helpers'
import { cleanupRequestStatus, getProjectDetails } from 'features/projectsSlice'
import routes from 'routes'
import MainLayout from 'components/templates/MainLayout'
import Breadcrumbs from 'components/Breadcrumbs'
import TasksFormType1SubView from './common/TasksFormType1SubView'
import TasksFormType2SubView from './common/TasksFormType2SubView'
import PageLoader from 'components/PageLoader'

const AdminTaskCreateView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { projectId } = useParams()
  const { state } = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)
  const { currentProject, loading } = useSelector((s) => s.projects)

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const handleAddTaskType1 = (values) => {
    setInnerLoading(true)
    projectService
      .addProjectTasks(projectId, values.tasks)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Zadania zostały dodane.', { variant: 'success' })
        navigate(routes.adminProjectsDetails.replace(':projectId', projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
        setInnerLoading(false)
      })
      .finally(() => setInnerLoading(false))
  }

  const handleAddTaskType2 = (values) => {
    setInnerLoading(true)
    const localTasks = values.tasks
    projectService
      .addProjectTasks(projectId, localTasks)
      .then((res) => {
        console.log(res)
        const createdTasks = res.data
        enqueueSnackbar(
          `Zadania zostały dodane. ${createdTasks.some((task) => task.files.length > 0) ? 'Trwa wysyłanie załączników.' : ''}`,
          { variant: 'info' }
        )
        Promise.all(
          createdTasks.map((createdTask, i) =>
            localTasks[i].files.length == 0
              ? true
              : projectService.adminUploadTaskFiles(projectId, createdTask.id, localTasks[i].files)
          )
        )
          .then((values) => {
            console.log(values)
            enqueueSnackbar('Załączniki zostały wysłane.', { variant: 'success' })
            navigate(routes.adminProjectsDetails.replace(':projectId', projectId))
          })
          .catch((err) => {
            console.log(err)
            enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
          })
          .finally(() => setInnerLoading(false))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
        setInnerLoading(false)
      })
  }

  const getTaskForm = (projectType) => {
    switch (projectType) {
      case 1:
        return <TasksFormType1SubView handleSubmit={handleAddTaskType1} loading={innerLoading} />
      case 2:
        return <TasksFormType2SubView handleSubmit={handleAddTaskType2} loading={innerLoading} />
      default:
        break
    }
  }

  if (!projectId) return <Navigate to={routes.adminProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  if (!currentProject) return <MainLayout></MainLayout>

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.adminProjects },
            {
              label: currentProject?.name || state?.from || projectId,
              to: routes.adminProjectsDetails.replace(':projectId', projectId),
            },
            { label: 'Dodaj zadanie' },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Nowe zadanie
        </Typography>
      </Box>

      {getTaskForm(currentProject.type)}
    </MainLayout>
  )
}

export default AdminTaskCreateView
