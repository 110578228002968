import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { FormikProvider, useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Card, CardActions, CardOverflow, Divider, Link, Stack, Typography } from '@mui/joy'
import { Download, Info, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { projectService } from 'services/projectsService'
import { handleErrorMessage, removeNullUndefined, resolveAndDownloadBlob } from 'utils/helpers'
import { cleanupRequestStatus, getProjectDetails } from 'features/projectsSlice'
import routes from 'routes'
import CustomUploadFileControl from 'components/forms/CustomDragAndDropFileControl'
import CustomDateTimePickerControl from 'components/forms/CustomDateTimePickerControl'
import importTasksSchema from 'validations/project/importTasksSchema'
import MainLayout from 'components/templates/MainLayout'
import Breadcrumbs from 'components/Breadcrumbs'
import PageLoader from 'components/PageLoader'
import FileUpload from 'components/FileUpload'
import dayjs from 'dayjs'

const AdminProjectsImportTasksView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { projectId } = useParams()
  const { state } = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [showPublishDate, setShowPublishDate] = useState(false)
  const [innerLoading, setInnerLoading] = useState(false)
  const { currentProject, loading } = useSelector((s) => s.projects)

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const handleImportTasks = (values) => {
    setInnerLoading(true)

    projectService
      .importProjectTasks(projectId, removeNullUndefined(values))
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Zadania zostały zaimportowane', { variant: 'success' })
        navigate(routes.adminProjectsDetails.replace(':projectId', projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleDownloadSample = () => {
    setInnerLoading(true)

    projectService
      .downloadImportSample(currentProject.type)
      .then((res) => {
        resolveAndDownloadBlob(res, 'sample_tasks.csv')
        enqueueSnackbar('Pobieranie pliku szablonu.', { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const formik = useFormik({
    initialValues: { file: null, publishedAt: null },
    validationSchema: importTasksSchema,
    onSubmit: handleImportTasks,
  })

  if (!projectId) return <Navigate to={routes.adminProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  if (!currentProject) return <MainLayout></MainLayout>

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.adminProjects },
            {
              label: currentProject?.name || state?.from || projectId,
              to: routes.adminProjectsDetails.replace(':projectId', projectId),
            },
            { label: 'Importuj' },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Importuj zadania
        </Typography>
      </Box>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Card
            sx={{
              display: 'flex',
              maxWidth: '700px',
              width: '100%',
              mx: 'auto',
              px: { xs: 2, md: 3 },
              py: { xs: 2, md: 3 },
            }}
          >
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md" startDecorator={<Info />} color="primary">
                Wgraj plik&nbsp;<Typography color="danger">.csv</Typography>
              </Typography>
            </Box>
            <Divider />
            <Stack spacing={2} sx={{ mt: 1 }}>
              <Typography level="title-md" startDecorator={<Download fontSize="small" />} color="primary">
                Wzór odpowiedniego pliku znajdziesz&nbsp;
                <Link component="button" onClick={handleDownloadSample} color="danger">
                  tutaj
                </Link>
              </Typography>
            </Stack>
            <Stack spacing={2} sx={{ my: 1 }}>
              <CustomUploadFileControl
                name="file"
                accept={{
                  'text/csv': ['.csv'],
                }}
                formik={formik}
                label={
                  <>
                    Importuj plik z zadaniami
                    <Typography level="body-sm" fontWeight={400} color="primary">
                      [.csv]
                    </Typography>
                  </>
                }
              />
              {formik.values.file &&
                formik.values.file.map((file, i) => (
                  <FileUpload
                    key={i}
                    fileName={file.name}
                    fileSize={file.size}
                    handleDelete={() => {
                      formik.setFieldValue(
                        'file',
                        formik.values.file.filter((f) => f.path !== file.path)
                      )
                    }}
                  />
                ))}
            </Stack>
            <Stack spacing={2} sx={{ mt: 1 }}>
              <Typography
                level="title-md"
                startDecorator={showPublishDate ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                color="primary"
                onClick={() => {
                  formik.setFieldValue('publishedAt', null)
                  setShowPublishDate((s) => !s)
                }}
                sx={{ cursor: 'pointer', userSelect: 'none' }}
              >
                Zaplanuj publikacje
                <Typography level="title-sm" color="neutral">
                  &nbsp;(opcjonalne)
                </Typography>
              </Typography>
              {showPublishDate && (
                <CustomDateTimePickerControl
                  minDate={dayjs()}
                  name="publishedAt"
                  label="Data publikacji"
                  formik={formik}
                  sx={{ flex: '1', minWidth: '1px' }}
                />
              )}
            </Stack>
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
              <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                <Button type="submit" disabled={!formik.dirty} size="sm" variant="solid" loading={innerLoading}>
                  Importuj
                </Button>
              </CardActions>
            </CardOverflow>
          </Card>
        </form>
      </FormikProvider>
    </MainLayout>
  )
}

export default AdminProjectsImportTasksView
