import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { Box, Chip, LinearProgress, Link, Typography } from '@mui/joy'
import { Link as RouterLink } from 'react-router-dom'
import { CheckCircle, HighlightOff, Lock, LockOpen } from '@mui/icons-material'
import { DATE_FORMAT } from 'utils/config'
import { formatMoney, isBoolean, resolveObjProp } from 'utils/helpers'
import StatusChip from 'components/UI/StatusChip'
import AdvancedTooltip from 'components/UI/AdvancedTooltip'
import { getAdminStatus, getUserStatus } from 'utils/getStatus'

const TableRowContent = ({ row, header, isListContent = false }) => {
  const { userInfo, serverTime } = useSelector((s) => s.auth)

  const propValue = resolveObjProp(header.propName, row)
  let content = null

  switch (header.type) {
    case 'notes':
      if (isListContent) content = <Typography level="body-xs">{row.notes}</Typography>
      else content = row.notes ? <AdvancedTooltip title="Uwagi do zadania:" description={row.notes} /> : ''
      break
    case 'computed':
      content = <Typography level="body-xs">{header.fn ? header.fn(row) : ''}</Typography>
      break
    case 'route':
      if (!header.condition || header.condition(row))
        content = (
          <Link
            component={RouterLink}
            to={header.route.replace(
              header.routeReplace,
              header.routeReplaceWith ? resolveObjProp(header.routeReplaceWith, row) : row.id
            )}
            level="title-sm"
          >
            <Typography color="primary" level="body-xs">
              {propValue}
            </Typography>
          </Link>
        )
      else content = <Typography level="body-xs">{propValue}</Typography>
      break
    case 'date':
      content = dayjs(propValue).format(DATE_FORMAT)
      break
    case 'status':
      content = <StatusChip status={propValue} statusObj={header.statusObj || undefined} />
      break
    case 'assigmentModeUser':
      {
        const userStatus = getUserStatus(row, userInfo, serverTime)
        content = (
          <Chip size="sm" color={userStatus.color} variant="plain">
            {userStatus.label}
          </Chip>
        )
      }
      break
    case 'assigmentModeAdmin':
      {
        const adminStatus = getAdminStatus(row, serverTime)
        content = (
          <Chip size="sm" color={adminStatus.color} variant="plain">
            {adminStatus.label}
          </Chip>
        )
      }
      break
    case 'check':
      content = propValue ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircle fontSize="small" color="success" />
        </Box>
      ) : (
        <Box xs={{ display: 'flex', alignItems: 'center' }}>
          <HighlightOff fontSize="small" color="disabled" />
        </Box>
      )
      break
    case 'blocked':
      content = propValue ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Lock fontSize="small" color="error" />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LockOpen fontSize="small" color="disabled" />
        </Box>
      )
      break
    case 'moneyRate':
      content = <Typography level="body-xs">{propValue} zł/h</Typography>
      break
    case 'money':
      content = <Typography level="body-xs">{formatMoney(propValue)}</Typography>
      break
    case 'progress':
      content = (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <LinearProgress determinate variant="solid" value={Number(propValue)} />
          <Typography level="body-xs">{Math.round(Number(propValue))}%</Typography>
        </Box>
      )
      break
    default:
      content = (
        <Typography level="body-xs">{isBoolean(propValue || propValue === null) ? '' : propValue || '–'}</Typography>
      )
  }

  return content
}

export default TableRowContent
