import routes from 'routes'
import { formatMoney } from '../helpers'

export const getUserMyTasksHeaders = () => {
  const headers = [
    {
      label: 'Data',
      propName: 'standardized.date',
      type: 'date',
      rowClassName: 'text-center',
      className: 'text-center width-sm',
    },
    {
      label: 'Nazwa projektu',
      propName: 'project.name',
      type: 'route',
      route: routes.userProjectsDetails,
      routeReplace: ':projectId',
      routeReplaceWith: 'project.id',
      rowClassName: 'text-center',
      className: 'text-center',
    },
    {
      label: 'Lokalizacja',
      propName: 'standardized.localization',
      rowClassName: 'text-center',
      className: 'text-center',
    },
    {
      label: 'Wynagrodzenie brutto',
      propName: 'computed1',
      type: 'computed',
      rowClassName: 'text-center',
      className: 'text-center',
      fn: (task) => formatMoney(task.standardized.rate),
    },
    {
      label: 'Uwagi',
      propName: 'standardized.notes',
      type: 'notes',
      rowClassName: 'text-center',
      className: 'width-sm text-center',
    },
    {
      label: 'Status',
      propName: 'assignment',
      type: 'assigmentModeUser',
      mobileOrder: -1,
      rowClassName: 'text-center',
      className: 'text-center',
    },
  ]

  return headers
}
