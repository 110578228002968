import { CustomYup } from 'validations/CustomYup'
import {
  globalPassportFieldSchema,
  ibanFieldSchema,
  peselFieldSchema,
  polishOrUkrainianPassportFieldSchema,
  zipCodeFieldSchema,
} from '../helpers'
import { ROLES } from 'utils/config'

export const getAdditionalDataSchema = (loggedUser) => {
  const isAdmin = loggedUser && loggedUser.role === ROLES.admin.value
  const isBillingInfoComplete = loggedUser && loggedUser.isBillingInfoComplete

  return CustomYup.object().shape({
    street: CustomYup.string(),
    buildingNumber: isAdmin ? CustomYup.string() : CustomYup.string().required(),
    apartmentNumber: CustomYup.string(),
    city: isAdmin ? CustomYup.string() : CustomYup.string().required(),
    postalCode: isAdmin ? zipCodeFieldSchema : zipCodeFieldSchema.required(),
    nationality: isAdmin
      ? CustomYup.object({
          id: CustomYup.string().required(),
          label: CustomYup.string().required(),
        })
      : CustomYup.object({
          id: CustomYup.string().required(),
          label: CustomYup.string().required(),
        }).required(),
    birthDate: isAdmin || isBillingInfoComplete ? CustomYup.string().nullable() : CustomYup.string().required(),
    pesel: CustomYup.mixed().when('nationality', {
      is: (nationality) => nationality?.id === 'PL' || nationality?.id === 'UA',
      then: () =>
        CustomYup.mixed().when('nationality', {
          is: (nationality) => nationality?.id === 'PL',
          then: () => peselFieldSchema.required(),
          otherwise: () => peselFieldSchema,
        }),
      otherwise: () =>
        CustomYup.string()
          .test('format', 'Wybrana narodowość nie posiada numeru PESEL.', (value) => {
            if (value === '' || value === undefined) return true
            return false
          })
          .nullable(),
    }),
    passportNumber: CustomYup.mixed().when('nationality', {
      is: (nationality) => nationality?.id !== 'PL',
      then: () =>
        CustomYup.mixed().when('nationality', {
          is: (nationality) => nationality?.id === 'UA',
          then: () => polishOrUkrainianPassportFieldSchema.required(),
          otherwise: () => globalPassportFieldSchema.required(),
        }),
      otherwise: () => CustomYup.string().nullable(),
    }),
    bankAccountNumber: isAdmin ? ibanFieldSchema : ibanFieldSchema.required(),
    taxOffice: isAdmin ? CustomYup.string() : CustomYup.string().required(),
    voivodship: isAdmin
      ? CustomYup.object({
          id: CustomYup.string().required(),
          label: CustomYup.string().required(),
        }).required()
      : CustomYup.object({
          id: CustomYup.string().required(),
          label: CustomYup.string().required(),
        }).required(),
    commune: isAdmin ? CustomYup.string() : CustomYup.string().required(),
    // Ankieta
    isStudentUnder26: CustomYup.bool().nullable(),
    hasOtherJobAndLessThenMinimumWage: CustomYup.bool().nullable(),
    hasOtherJobAndMoreThenMinimumWage: CustomYup.bool().nullable(),
    hasBusinessAndContributions60: CustomYup.bool().nullable(),
    hasBusinessAndContributions30: CustomYup.bool().nullable(),
    hasBussinesAndSameServices: CustomYup.bool().nullable(),
    hasOtherJobButIsOnUnpaidOrParentalLeave: CustomYup.bool().nullable(),
    hasOtherJobButIsOnMaternityLeave: CustomYup.bool().nullable(),
    hasNoOtherSocialInsurance: CustomYup.bool().nullable(),
    isRetired: CustomYup.bool().nullable(),
    hasDisability: CustomYup.bool().nullable(),
    hasDisabilityOther: CustomYup.mixed().when('hasDisability', {
      is: (hasDisability) => hasDisability === true,
      then: () => CustomYup.string().required(),
      otherwise: () => CustomYup.string(),
    }),
    healthContribution: CustomYup.bool().oneOf([true, false], 'To pole jest wymagane.').required(),
    otherCircumstances: CustomYup.bool().nullable(),
    otherCircumstancesOther: CustomYup.mixed().when('otherCircumstances', {
      is: (otherCircumstances) => otherCircumstances === true,
      then: () => CustomYup.string().required(),
      otherwise: () => CustomYup.string(),
    }),
    exemptionPIT: CustomYup.bool().oneOf([true, false], 'To pole jest wymagane.').required(),
    taxResidenceCertificate: isAdmin
      ? CustomYup.mixed()
          .when('nationality', {
            is: (nationality) => nationality?.id !== 'PL',
            then: () => CustomYup.bool().oneOf([true, false], 'To pole jest wymagane.').nullable(),
            otherwise: () => CustomYup.string().nullable(),
          })
          .nullable()
      : CustomYup.mixed().when('nationality', {
          is: (nationality) => nationality?.id !== 'PL',
          then: () => CustomYup.bool().oneOf([true, false], 'To pole jest wymagane.').required(),
          otherwise: () => CustomYup.string().nullable(),
        }),
    taxResidenceCertificateStartDate: CustomYup.mixed().when('taxResidenceCertificate', {
      is: (taxResidenceCertificate) => taxResidenceCertificate === true,
      then: () => CustomYup.string().required(),
      otherwise: () => CustomYup.string().nullable(),
    }),
    taxResidenceCertificateEndDate: CustomYup.mixed().when('taxResidenceCertificate', {
      is: (taxResidenceCertificate) => taxResidenceCertificate === true,
      then: () => CustomYup.string().required(),
      otherwise: () => CustomYup.string().nullable(),
    }),
    officeNFZ: isAdmin
      ? CustomYup.object({
          id: CustomYup.string().required(),
          label: CustomYup.string().required(),
        })
      : CustomYup.object({
          id: CustomYup.string().required(),
          label: CustomYup.string().required(),
        }).required(),
    termsChanges: isAdmin ? CustomYup.bool() : CustomYup.bool().oneOf([true], 'Pole jest wymagane.'),
    termsRodo: isAdmin ? CustomYup.bool() : CustomYup.bool().oneOf([true], 'Pole jest wymagane.'),
  })
}
