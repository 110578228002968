import dayjs from 'dayjs'
import { Card, CardContent, Stack, Typography } from '@mui/joy'
import { DATE_FORMAT } from 'utils/config'
import { task1Schape } from '../Admin/common/TasksFormType1SubView'
import { projectShape } from '../Admin/common/ProjectFormSubView'
import { formatMoney } from 'utils/helpers'

const TaskInfoType1SubView = ({ project, task }) => (
  <Card orientation="horizontal" variant="outlined">
    <CardContent>
      <Stack rowGap={0.5} columnGap={2} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
        <Typography level="body-sm">
          <Typography color="primary" fontWeight={500}>
            Nr:
          </Typography>{' '}
          {task.lp || '-'}
        </Typography>
        <Typography level="body-sm">
          <Typography color="primary" fontWeight={500}>
            Data:
          </Typography>{' '}
          {dayjs(task.date).format(DATE_FORMAT)}
        </Typography>
        <Typography level="body-sm">
          <Typography color="primary" fontWeight={500}>
            Przystanek/Stacja:
          </Typography>{' '}
          {task.localization}
        </Typography>
        <Typography level="body-sm">
          <Typography color="primary" fontWeight={500}>
            Godziny:
          </Typography>{' '}
          {task.hours}
        </Typography>
        <Typography level="body-sm">
          <Typography color="primary" fontWeight={500}>
            Ilość godzin:
          </Typography>{' '}
          {task.hoursCount}
        </Typography>
        {project && project.hourlyRate && (
          <Typography level="body-sm">
            <Typography color="primary" fontWeight={500}>
              Stawka projektowa brutto:
            </Typography>{' '}
            {formatMoney(Number(project.hourlyRate))}
          </Typography>
        )}
        {project && project.hourlyRate && task.hoursCount && (
          <Typography level="body-sm">
            <Typography color="primary" fontWeight={500}>
              Wynagrodzenie brutto:
            </Typography>{' '}
            {formatMoney(Number(project.hourlyRate) * Number(task.hoursCount))}
          </Typography>
        )}
      </Stack>
      {task.notes && (
        <Typography level="body-sm">
          <Typography color="primary" fontWeight={500}>
            Notatki:
          </Typography>{' '}
          {task.notes}
        </Typography>
      )}
    </CardContent>
  </Card>
)

TaskInfoType1SubView.propTypes = {
  task: task1Schape,
  project: projectShape,
}

export default TaskInfoType1SubView
