import { Dashboard, FormatListBulleted, Group, Payments, Settings, TableChart } from '@mui/icons-material'
import routes from 'routes'
// eslint-disable-next-line no-undef
export const API_BASE_URL = process.env.REACT_APP_SERVER_URL
export const ACCESS_TOKEN_NAME = 'vistula_access_token'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:MM'

export const APP_LAUNCH_YEAR = 2024

export const PROJECT_TYPES = [
  { value: 1, label: 'Klasyczny' },
  { value: 2, label: 'Wideo - akceptacja uczestników' },
]
export const PROJECT_TYPES_OBJECT = PROJECT_TYPES.reduce((acc, o) => {
  if (!acc[o.value]) acc[o.value] = o
  return acc
}, {})

export const ROLES = {
  user: {
    value: 'USER',
    label: 'Użytkownik',
  },
  admin: {
    value: 'ADMIN',
    label: 'Administrator',
  },
}

export const STATUS = {
  open: {
    value: 'open',
    label: 'W trakcie',
  },
  closed: {
    value: 'closed',
    label: 'Zakończony',
  },
  cancelled: {
    value: 'cancelled',
    label: 'Anulowano',
  },
  pending: {
    value: 'pending',
    label: 'W trakcie',
  },
  approved: {
    value: 'approved',
    label: 'Zaakceptowane',
  },
  notapproved: {
    value: 'notapproved',
    label: 'Niezaakceptowane',
  },
  done: {
    value: 'done',
    label: 'Zakończono',
  },
}

export const CUSTOM_ERROR_CODES = {
  EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED',
  BILLING_INFO_INCOMPLETE: 'BILLING_INFO_INCOMPLETE',
  TASK_ALREADY_ASSIGNED_SAME_DAY: 'TASK_ALREADY_ASSIGNED_SAME_DAY',
}

export const PROJECT_JOIN_STATUS = {
  pending: {
    value: 'PENDING',
    label: 'W trakcie',
  },
  rejected: {
    value: 'REJECTED',
    label: 'Odrzucony',
  },
  approved: {
    value: 'APPROVED',
    label: 'Zaakceptowane',
  },
}

export const MAIN_MENU = {
  [ROLES.user.value]: [
    { label: 'Dashboard', icon: <Dashboard fontSize="small" />, to: routes.user, end: true },
    { label: 'Projekty', icon: <TableChart fontSize="small" />, to: routes.userProjects },
    {
      label: 'Moje zadania',
      icon: <FormatListBulleted fontSize="small" />,
      to: routes.userMyTasks,
      injectUsersTasksCount: true,
    },
    { label: 'Rozliczenia', icon: <Payments fontSize="small" />, to: routes.userBillings },
  ],
  [ROLES.admin.value]: [
    // { label: 'Dashboard', icon: <Dashboard fontSize="small" />, to: routes.admin, end: true },
    { label: 'Projekty', icon: <TableChart fontSize="small" />, to: routes.adminProjects },
    { label: 'Rozliczenia', icon: <Payments fontSize="small" />, to: routes.adminBillings },
    { label: 'Użytkownicy', icon: <Group fontSize="small" />, to: routes.adminUsers },
    { label: 'Ustawienia aplikacji', icon: <Settings fontSize="small" />, to: routes.adminSettings },
  ],
}

export const newTaskType1Object = {
  lp: '',
  date: '',
  hours: '',
  hoursCount: '',
  localization: '',
  notes: '',
  publishedAt: '',
}

export const newTaskType2Object = {
  lp: '',
  pointNumber: '',
  pointType: '',
  numberOfCameras: '',
  numberOfDirections: '',
  date: '',
  duration: '',
  sdr: '',
  rate: '',
  notes: '',
  publishedAt: '',
  links: [''],
  files: [],
}

export const dateFilters = {
  current: { value: 'current', label: 'Bieżące' },
  all: { value: 'all', label: 'Wszystkie' },
}
export const dateFiltersOptions = Object.values(dateFilters)

export const openFilters = {
  open: { value: 'open', label: 'Otwarte' },
  all: { value: 'all', label: 'Wszystkie' },
}
export const openFiltersOptions = Object.values(openFilters)
