import PropTypes from 'prop-types'
import { FormikProvider, useFormik } from 'formik'
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog, Stack } from '@mui/joy'
import sendSmsToProjectUsersSchema from 'validations/project/sendSmsToProjectUsersSchema'
import CustomEditorControl from './forms/CustomEditorControl'
import { useSelector } from 'react-redux'
import CustomSelectControl from './forms/CustomSelectControl'
import { useEffect } from 'react'

const SmsProjectModal = ({ open, setObject, handleSubmit, title = 'Powiadomienie SMS', children, loading }) => {
  const { currentProject } = useSelector((s) => s.projects)

  const formik = useFormik({
    initialValues: { message: '', group: null },
    validationSchema: sendSmsToProjectUsersSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    formik.resetForm()
  }, [open])

  return (
    <Modal open={Boolean(open)} onClose={() => setObject(false)}>
      <ModalDialog variant="outlined" role="alertdialog" sx={{ maxWidth: '500px', width: '100%' }}>
        <DialogTitle>{title}</DialogTitle>
        <Divider />
        <DialogContent>{children}</DialogContent>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <CustomSelectControl
                label="Grupa docelowa"
                name="group"
                options={[
                  { value: 'assignment', label: 'Zapisani', type: [1, 2] },
                  { value: 'reserveList', label: 'Lista rezerwowa', type: [1, 2] },
                  { value: 'assignment_reserveList', label: 'Zapisani + lista rezerwowa', type: [1, 2] },
                  { value: 'approved', label: 'Wszyscy zapisani do projektu', type: [2] },
                ].filter((o) => o.type.includes(currentProject.type))}
                formik={formik}
              />
              <CustomEditorControl name="message" label="Treść wiadomości" formik={formik} />
            </Stack>
          </form>
        </FormikProvider>
        <DialogActions>
          <Button
            variant="solid"
            color="primary"
            onClick={formik.handleSubmit}
            loading={loading}
            disabled={!formik.isValid || loading}
          >
            Wyślij SMS
          </Button>
          <Button variant="plain" color="neutral" onClick={() => setObject(false)} disabled={loading} loading={loading}>
            Anuluj
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}

SmsProjectModal.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.array]).isRequired,
  setObject: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  loading: PropTypes.bool,
}

export default SmsProjectModal
