import { useEffect, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/joy'
import { cleanupRequestStatus, getProjectDetails } from 'features/projectsSlice'
import { handleErrorMessage } from 'utils/helpers'
import { PROJECT_JOIN_STATUS } from 'utils/config'
import { projectService } from 'services/projectsService'
import routes from 'routes'
import useSearchParamValue from 'hooks/useSearchParamValue'
import Breadcrumbs from 'components/Breadcrumbs'
import EnchantedTable from 'components/tables/EnchantedTable'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import PageLoader from 'components/PageLoader'
import FilterSearch from 'components/tables/FilterSearch'
import DeleteModal from 'components/DeleteModal'

const headers = [
  { label: 'Imię', propName: 'user.firstName' },
  { label: 'Nazwisko', propName: 'user.lastName' },
  { label: 'Telefon', propName: 'user.phoneNumber' },
  { label: 'E-mail', propName: 'user.email' },
  { label: 'Status', propName: 'status', type: 'status', statusObj: PROJECT_JOIN_STATUS },
]

const AdminProjectsDetailsUsersView = () => {
  const dispatch = useDispatch()
  const { projectId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)
  const [deleteJoinObject, setDeleteJoinObject] = useState(false)
  const [deleteJoinArray, setDeleteJoinArray] = useState(false)

  const [searchFilter, setSearchFilter] = useSearchParamValue('search', '')

  const { currentProject, currentProjectJoinRequests, loading } = useSelector((s) => s.projects)

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const filteredJoinRequests = useMemo(() => {
    if (!currentProjectJoinRequests) return []
    let tempRequests = currentProjectJoinRequests.map((tr) => ({
      ...tr,
      user: { ...tr.user, fullName: `${tr.user.firstName} ${tr.user.lastName}` },
    }))
    if (searchFilter)
      tempRequests = tempRequests.filter((tr) =>
        [tr.user.email, tr.user.fullName]
          .filter(Boolean)
          .map((s) => s.toLowerCase())
          .some((s) => s.includes(searchFilter.toLowerCase()))
      )
    return tempRequests
  }, [currentProject, searchFilter])

  // Singles
  const handleSingleAction = (action, joinRequest, successMessage, successCb) => {
    setInnerLoading(true)
    action(projectId, joinRequest.id)
      .then((res) => {
        console.log(res)
        enqueueSnackbar(successMessage, { variant: 'success' })
        if (successCb) successCb()
        dispatch(getProjectDetails(projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleApproveJoin = (joinRequest) =>
    handleSingleAction(
      projectService.adminApproveProjectJoin,
      joinRequest,
      'Zgłoszenie użytkownika zostało zaakceptowane.'
    )

  const handleRejectJoin = (joinRequest) =>
    handleSingleAction(projectService.adminRejectProjectJoin, joinRequest, 'Zgłoszenie użytkownika zostało odrzucone.')

  const handleDeleteJoin = (joinRequest) =>
    handleSingleAction(
      projectService.adminDeleteProjectJoin,
      joinRequest,
      'Zgłoszenie użytkownika zostało usunięte.',
      () => setDeleteJoinObject(false)
    )

  // Bulks
  const handleBulkAction = (action, joinRequests, successMessage, errorMessage, successCb) => {
    setInnerLoading(true)
    action(
      projectId,
      joinRequests.map((jr) => jr.id)
    )
      .then((res) => {
        console.log(res)
        enqueueSnackbar(successMessage, { variant: 'success' })
        if (successCb) successCb()
        dispatch(getProjectDetails(projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 10000 })
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error', autoHideDuration: 10000 })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleApproveJoinBulk = (joinRequests) =>
    handleBulkAction(
      projectService.adminApproveProjectJoinBulk,
      joinRequests,
      'Zgłoszenia wybranych użytkowników zostały zaakceptowane.',
      'W wyniku błędu żadne zgłoszenie nie zostało zaakceptowane.'
    )

  const handleRejectJoinBulk = (joinRequests) =>
    handleBulkAction(
      projectService.adminRejectProjectJoinBulk,
      joinRequests,
      'Zgłoszenia wybranych użytkowników zostały odrzucone.',
      'W wyniku błędu żadne zgłoszenie nie zostało odrzucone.'
    )

  const handleDeleteJoinBulk = (joinRequests) =>
    handleBulkAction(
      projectService.adminDeleteProjectJoinBulk,
      joinRequests,
      'Zgłoszenia wybranych użytkowników zostały usunięte.',
      'W wyniku błędu żadne zgłoszenie nie zostało usunięte.',
      () => setDeleteJoinArray(false)
    )

  if (!projectId) return <Navigate to={routes.adminProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  if (!currentProject) return <MainLayout></MainLayout>

  return (
    <MainLayout>
      <DeleteModal
        open={deleteJoinObject || deleteJoinArray}
        handleDelete={() => {
          if (deleteJoinObject) handleDeleteJoin(deleteJoinObject)
          else if (deleteJoinArray) handleDeleteJoinBulk(deleteJoinArray)
        }}
        loading={innerLoading}
        setObject={(val) => {
          setDeleteJoinObject(val)
          setDeleteJoinArray(val)
        }}
      >
        {deleteJoinObject && (
          <>
            Czy jesteś pewny, ze chcesz usunąć prośbe o dołączenie użytkownika:{' '}
            <Typography color="primary" fontWeight={500}>
              {deleteJoinObject.user.firstName} {deleteJoinObject.user.lastName}
            </Typography>
          </>
        )}
        {deleteJoinArray && (
          <>Czy jesteś pewny, ze chcesz usunąć {deleteJoinArray.length} zgłoszeń o dołączenie do projektu?</>
        )}
      </DeleteModal>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.adminProjects },
            { label: currentProject.name, to: routes.adminProjectsDetails.replace(':projectId', projectId) },
            { label: 'Użytkownicy' },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Lista użytkowników projektu:&nbsp;
          <Typography color="primary">{currentProject?.name}</Typography>
        </Typography>
      </Box>

      <FiltersWrapper
        renderSearch={
          <FilterSearch
            label="Wyszukiwanie uczestnika"
            placeholder="Szukaj... [imię, nazwisko, email]"
            value={searchFilter}
            setValue={setSearchFilter}
          />
        }
      />

      <EnchantedTable
        headers={headers}
        data={filteredJoinRequests}
        actions={[
          {
            label: 'Zaakceptuj zgłoszenie',
            color: 'success',
            divider: true,
            loading: innerLoading,
            func: handleApproveJoin,
            checkIfDisable: (jr) => jr.status === PROJECT_JOIN_STATUS.approved.value,
          },
          {
            label: 'Odrzuć zgłoszenie',
            color: 'warning',
            divider: true,
            loading: innerLoading,
            func: handleRejectJoin,
            checkIfDisable: (jr) => jr.status === PROJECT_JOIN_STATUS.rejected.value,
          },
          {
            label: 'Usuń zgłoszenie',
            color: 'danger',
            divider: true,
            loading: innerLoading,
            func: (el) => setDeleteJoinObject(el),
          },
        ]}
        bulkActions={[
          {
            label: 'Zaakceptuj zgłoszenia',
            color: 'success',
            divider: true,
            func: handleApproveJoinBulk,
          },
          {
            label: 'Odrzuć zgłoszenia',
            color: 'warning',
            divider: true,
            func: handleRejectJoinBulk,
          },
          {
            label: 'Usuń zgłoszenia',
            color: 'danger',
            divider: true,
            func: (selectedItems) => setDeleteJoinArray(selectedItems),
          },
        ]}
        noDataText="Brak użytkowników do wyświetlenia."
      />
    </MainLayout>
  )
}

export default AdminProjectsDetailsUsersView
