import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { FileUploadRounded, InfoOutlined } from '@mui/icons-material'
import { AspectRatio, Card, FormControl, FormHelperText, FormLabel, Typography } from '@mui/joy'

const CustomUploadFileControl = ({ name, label, formik, sx, accept = null, multiple = false, maxSize = null }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    multiple,
    maxSize,
    onDrop: (acceptedFiles, fileRejections) => {
      formik.setFieldValue(name, acceptedFiles, false)
      fileRejections.forEach((file) =>
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') formik.setFieldError(name, 'Plik jest za duży.')
          if (err.code === 'file-invalid-type') formik.setFieldError(name, 'Nieprawidłowy format.')
        })
      )
    },
  })
  return (
    <FormControl error={formik.touched[name] && Boolean(formik.errors[name])} sx={sx}>
      {label && <FormLabel>{label}</FormLabel>}
      <div>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Card
            variant="soft"
            sx={[
              {
                borderRadius: 'sm',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center',
                px: 3,
                flexGrow: 1,
                boxShadow: 'none',
                cursor: 'pointer',
              },
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
          >
            <AspectRatio
              ratio="1"
              variant="solid"
              color="primary"
              sx={{ minWidth: 32, borderRadius: '50%', '--Icon-fontSize': '16px' }}
            >
              <div>
                <FileUploadRounded />
              </div>
            </AspectRatio>
            <Typography level="body-sm" sx={{ textAlign: 'center' }}>
              {isDragActive ? (
                <span>Upuść pliki tutaj ...</span>
              ) : (
                <>
                  <Typography color="primary">Kliknij</Typography>, aby przesłać lub przeciągnij i upuść plik
                </>
              )}
            </Typography>
          </Card>
          {formik.touched[name] && formik.errors[name] && (
            <FormHelperText color="danger" sx={{ gap: 1 }}>
              <InfoOutlined fontSize="small" />
              {formik.errors[name]}
            </FormHelperText>
          )}
        </div>
      </div>
    </FormControl>
  )
}

CustomUploadFileControl.propTypes = {
  sx: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  formik: PropTypes.object.isRequired,
  accept: PropTypes.object,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
}

export default CustomUploadFileControl
