import PropTypes from 'prop-types'
import { Box, Typography, List, ListItem, ListDivider, Button } from '@mui/joy'
import { actionsTypesSchema, tablePropTypesSchema } from './helpers'
import TableRowContent from './TableRowContent'

const RenderButtons = ({ actions, row }) => {
  if (actions.every((action) => action.display && !action.display(row))) return <Box sx={{ mb: 1 }} />

  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: 2,
        rowGap: 1,
        gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
        alignItems: 'center',
        mt: 2,
        mb: 1,
      }}
    >
      {actions.map((action) => {
        if (action.display && !action.display(row)) return ''
        return (
          <Button
            key={action.label}
            size="sm"
            variant="outlined"
            color={action.color || 'primary'}
            onClick={() => action.func(row)}
            loading={action.loading || false}
            startDecorator={action.decor ? action.decor : null}
          >
            {action.label}
          </Button>
        )
      })}
    </Box>
  )
}

RenderButtons.propTypes = {
  actions: actionsTypesSchema,
  row: PropTypes.object,
}

// Sort the array based on the 'value' property

const BaseTableList = (props) => {
  const { headers, data = [], actions, buttonActions } = props

  return (
    <Box sx={{ display: { sm: 'block', lg: 'none' } }}>
      {data.map((row, index) => (
        <List key={row.id || index} size="sm" sx={{ '--ListItem-paddingX': 0 }}>
          <ListItem sx={{ display: 'block' }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 1,
                gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
              }}
            >
              {headers.map((header) => {
                if (header.ignoreOnMobile) return null
                return (
                  <Box
                    key={header.propName + row.id}
                    sx={{
                      order: header.mobileOrder || null,
                      display: {
                        xs: 'flex',
                        sm: 'block',
                      },
                      alignItems: 'baseline',
                      flexWrap: 'wrap',
                      columnGap: 1,
                    }}
                  >
                    <Typography level="title-sm">{header.label}:</Typography>
                    <Typography level="body-xs">
                      <TableRowContent row={row} header={header} isListContent />
                    </Typography>
                  </Box>
                )
              })}
            </Box>
            {buttonActions && buttonActions.length && <RenderButtons actions={buttonActions} row={row} />}
            {actions && actions.length && <RenderButtons actions={actions} row={row} />}
          </ListItem>
          <ListDivider />
        </List>
      ))}
    </Box>
  )
}

BaseTableList.propTypes = tablePropTypesSchema

export default BaseTableList
