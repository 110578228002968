import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { Launch } from '@mui/icons-material'
import { Card, CardContent, Link, Stack, Typography } from '@mui/joy'
import { formatMoney, handleErrorMessage, resolveAndDownloadBlob } from 'utils/helpers'
import { DATE_FORMAT } from 'utils/config'
import { task2Schape } from '../Admin/common/TasksFormType2SubView'
import { projectService } from 'services/projectsService'
import FileUpload from 'components/FileUpload'

const TaskInfoType2SubView = ({ task }) => {
  const { enqueueSnackbar } = useSnackbar()

  const handleDownloadFile = (file) => {
    projectService
      .downloadProjectTaskFile(file.projectId, file.taskId, file.id)
      .then((res) => {
        resolveAndDownloadBlob(res, file.fileName)
        enqueueSnackbar('Pobieranie pliku.', { variant: 'success' })
      })
      .catch((err) => enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' }))
  }
  return (
    <>
      <Card orientation="horizontal" variant="outlined">
        <CardContent>
          <Stack rowGap={0.5} columnGap={2} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Nr:
              </Typography>{' '}
              {task.lp || '-'}
            </Typography>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Termin:
              </Typography>{' '}
              {dayjs(task.date).format(DATE_FORMAT)}
            </Typography>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Nr punktu:
              </Typography>{' '}
              {task.pointNumber}
            </Typography>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Typ punktu:
              </Typography>{' '}
              {task.pointType}
            </Typography>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Ilość kamer:
              </Typography>{' '}
              {task.numberOfCameras}
            </Typography>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Ilość kierunków:
              </Typography>{' '}
              {task.numberOfDirections}
            </Typography>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Czas trwania wideo:
              </Typography>{' '}
              {task.duration}
            </Typography>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                SDR:
              </Typography>{' '}
              {task.sdr}
            </Typography>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Wynagrodzenie brutto:
              </Typography>{' '}
              {formatMoney(task.rate)}
            </Typography>
          </Stack>
          {task.notes && (
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Notatki:
              </Typography>{' '}
              {task.notes}
            </Typography>
          )}
          {task.links && task.links.length > 0 && (
            <Stack rowGap={0.5} columnGap={1} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
              <Typography level="body-sm" color="primary" fontWeight={500}>
                Linki:
              </Typography>{' '}
              {task.links.map((link, i) => (
                <Link
                  key={i}
                  color="neutral"
                  variant="plain"
                  level="body-xs"
                  href={link}
                  startDecorator={<Launch fontSize="inherit" />}
                  underline="always"
                  target="_blank"
                >
                  {link}
                </Link>
              ))}
            </Stack>
          )}
        </CardContent>
      </Card>

      {task.files && task.files.length > 0 && (
        <>
          <Typography color="primary">Załączniki:</Typography>
          <Stack
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat( auto-fit, minmax(320px, 1fr) )',
                xl: 'repeat( 4, 1fr )',
              },
              gap: 2,
            }}
          >
            {task.files.map((file, fileIndex) => (
              <FileUpload
                isLocal={!file.id}
                key={fileIndex}
                fileName={file.fileName}
                fileSize={file.fileSize}
                handleDownload={() => handleDownloadFile(file)}
                handleDelete={null}
              />
            ))}
          </Stack>
        </>
      )}

      {task.userFiles && task.userFiles.length > 0 && (
        <>
          <Typography color="success">Rozwiązanie:</Typography>
          <Stack
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat( auto-fit, minmax(320px, 1fr) )',
                xl: 'repeat( 4, 1fr )',
              },
              gap: 2,
            }}
          >
            {task.userFiles.map((file, fileIndex) => (
              <FileUpload
                isLocal={!file.id}
                key={fileIndex}
                fileName={file.fileName}
                fileSize={file.fileSize}
                handleDownload={() => handleDownloadFile(file)}
                handleDelete={null}
              />
            ))}
          </Stack>
        </>
      )}
    </>
  )
}

TaskInfoType2SubView.propTypes = {
  task: task2Schape,
}

export default TaskInfoType2SubView
