import PropTypes from 'prop-types'
import { InfoOutlined } from '@mui/icons-material'
import { FormControl, FormHelperText, FormLabel, Textarea } from '@mui/joy'

const CustomEditorControl = ({ name, label, formik, sx, ...rest }) => (
  <FormControl error={formik.touched[name] && Boolean(formik.errors[name])} sx={sx}>
    <FormLabel>{label}</FormLabel>
    <Textarea
      minRows={4}
      id={name}
      name={name}
      label={label}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      {...rest}
    />
    {formik.touched[name] && formik.errors[name] && (
      <FormHelperText sx={{ gap: 1 }}>
        <InfoOutlined fontSize="small" />
        {formik.errors[name]}
      </FormHelperText>
    )}
  </FormControl>
)

export default CustomEditorControl

CustomEditorControl.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  formik: PropTypes.object.isRequired,
}
