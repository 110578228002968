import PropTypes from 'prop-types'
import { Chip } from '@mui/joy'
import { PROJECT_JOIN_STATUS, STATUS } from 'utils/config'
import { standarizeStatus } from 'utils/helpers'

const StatusChip = ({ status: innerStatus, statusObj = STATUS }) => {
  innerStatus = standarizeStatus(innerStatus)

  return (
    <Chip
      variant="soft"
      size="sm"
      startDecorator={
        {
          [STATUS.open.value]: null,
          [STATUS.closed.value]: null,
          [STATUS.cancelled.value]: null,
          [STATUS.pending.value]: null,
          [STATUS.done.value]: null,
          [STATUS.approved.value]: null,
          [STATUS.notapproved.value]: null,
          [PROJECT_JOIN_STATUS.pending.value]: null,
          [PROJECT_JOIN_STATUS.approved.value]: null,
          [PROJECT_JOIN_STATUS.rejected.value]: null,
        }[innerStatus]
      }
      color={
        {
          [STATUS.open.value]: 'warning',
          [STATUS.closed.value]: 'danger',
          [STATUS.cancelled.value]: 'danger',
          [STATUS.pending.value]: 'warning',
          [STATUS.done.value]: 'success',
          [STATUS.approved.value]: 'success',
          [STATUS.notapproved.value]: 'warning',
          [PROJECT_JOIN_STATUS.pending.value]: 'warning',
          [PROJECT_JOIN_STATUS.approved.value]: 'success',
          [PROJECT_JOIN_STATUS.rejected.value]: 'danger',
        }[innerStatus]
      }
    >
      {statusObj[innerStatus]?.label || ''}
    </Chip>
  )
}

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  statusObj: PropTypes.object,
}

export default StatusChip
