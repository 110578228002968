import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Alert, Box, Card, Stack, Typography } from '@mui/joy'
import { Warning } from '@mui/icons-material'
import Breadcrumbs from 'components/Breadcrumbs'
import MainLayout from 'components/templates/MainLayout'

const UserDashboardView = () => {
  const { state } = useLocation()
  const { userInfo } = useSelector((s) => s.auth)

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Dashboard' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Dashboard
        </Typography>
      </Box>

      {state && state.error && (
        <Stack sx={{ mt: 2, width: '100%' }}>
          <Alert startDecorator={<Warning fontSize="small" />} variant="soft" color="danger">
            {state.error}
          </Alert>
        </Stack>
      )}

      {userInfo && !userInfo.isAccountConfirmed && (
        <Stack
          sx={{
            mt: 2,
            display: 'flex',
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <Card
            variant="outlined"
            sx={{
              px: { xs: 2, md: 4 },
              py: { xs: 2, md: 3 },
            }}
          >
            <Typography level="h3" component="h2" color="warning">
              Twoje konto oczekuje na akceptację.
            </Typography>
            <Typography>
              Gdy Twoje konto zostanie zweryfikowane, zostaniesz o tym poinformowany na wskazany adres e-mail.
            </Typography>
          </Card>
        </Stack>
      )}

      {userInfo && userInfo.isAccountConfirmed && !userInfo.isBillingInfoComplete && (
        <Stack
          sx={{
            mt: 2,
            display: 'flex',
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <Card
            variant="outlined"
            sx={{
              px: { xs: 2, md: 4 },
              py: { xs: 2, md: 3 },
            }}
          >
            <Typography level="h3" component="h2" color="success">
              Twoje konto zostało zaakceptowane.
            </Typography>
            <Typography>
              Przejdź do &quot;Mój Profil&quot; -&gt; &quot;Dane do umowy&quot; i uzupełnij formularz.
            </Typography>
          </Card>
        </Stack>
      )}

      {userInfo && userInfo.isAccountConfirmed && userInfo.isBillingInfoComplete && !userInfo.birthDate && (
        <Stack
          sx={{
            mt: 2,
            display: 'flex',
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <Card
            variant="outlined"
            sx={{
              px: { xs: 2, md: 4 },
              py: { xs: 2, md: 3 },
            }}
          >
            <Typography level="h3" component="h2" color="warning">
              Brakujące informacje
            </Typography>
            <Typography>
              Prosimy o uzupełnienie daty urodzenia w zakładce &quot;Mój Profil&quot; -&gt; &quot;Dane do umowy&quot;.
            </Typography>
          </Card>
        </Stack>
      )}
    </MainLayout>
  )
}

export default UserDashboardView
