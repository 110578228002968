import { Navigate, Route, Routes } from 'react-router-dom'
import { ROLES } from 'utils/config'
import routes from 'routes'
import ProtectedRoute from 'routes/ProtectetRoute'

import HomeView from 'views/HomeView'
// import DashboardView from 'views/DashboardView'

import LoginView from 'views/Auth/LoginView'
import RegisterView from 'views/Auth/RegisterView'
import ResetPasswordView from 'views/Auth/ResetPasswordView'
import ConfirmEmaiView from 'views/Auth/ConfirmEmaiView'

import UserDashboardView from './User/UserDashboardView'
import UserProjectsView from './User/UserProjectsView'
import UserBillingsView from './User/UserBillingsView'

// import AdminDashboardView from './Admin/AdminDashboardView'
import AdminProjectsView from './Admin/AdminProjectsView'
import AdminProjectsCreateView from './Admin/AdminProjectsCreateView'
import AdminUsersView from './Admin/AdminUsersView'
import AdminUsersDetailsView from './Admin/AdminUsersDetailsView'

import AdminBillingsView from './Admin/AdminBillingsView'
import AdminBillingsUserDetails from './Admin/AdminBillingsUserDetails'

import AdminSettingsView from './Admin/AdminSettingsView'

import ProfileView from 'views/Profile/ProfileView'
import AdminProjectsDetailsView from './Admin/AdminProjectsDetailsView'
import AdminProjectsDetailsUsersView from './Admin/AdminProjectsDetailsUsersView'
import AdminTaskCreateView from './Admin/AdminTaskCreateView'
import AdminTaskImportView from './Admin/AdminTaskImportView'
import UserProjectsDetailsView from './User/UserProjectsDetailsView'
import AdminProjectsEditView from './Admin/AdminProjectsEditView'
import AdminTaskEditView from './Admin/AdminTaskEditView'
import AdminTaskDetailsView from './Admin/AdminTaskDetailsView'
import UserMyTasksView from './User/UserMyTasksView'
import LogicGetUserTasks from './logic/LogicGetUserTasks'
import UserTaskDetailsView from './User/UserTaskDetailsView'
import UserTaskUploadView from './User/UserTaskUploadView'

// import NoMatchView from 'views/NoMatchView'

const Router = () => (
  <Routes>
    <Route element={<ProtectedRoute />}>
      <Route index element={<HomeView />} />
    </Route>

    {/* Auth */}
    <Route path={routes.login} element={<LoginView />} />
    <Route path={routes.register} element={<RegisterView />} />
    <Route path={routes.resetPassword} element={<ResetPasswordView />} />
    <Route path={routes.confirmEmail} element={<ConfirmEmaiView />} />

    {/* Tylko dla zalogowanych */}
    <Route element={<ProtectedRoute />}>
      <Route path={routes.profile} element={<ProfileView />} />
    </Route>

    {/* Tylko dla zalogowanych jako USER*/}
    <Route
      element={
        <ProtectedRoute allowedRoles={[ROLES.user.value]}>
          <LogicGetUserTasks />
        </ProtectedRoute>
      }
    >
      <Route path={routes.user} element={<UserDashboardView />} />

      <Route path={routes.userProjects} element={<UserProjectsView />} />
      <Route path={routes.userProjectsDetails} element={<UserProjectsDetailsView />} />
      <Route path={routes.userProjectsDetailsTask} element={<UserTaskDetailsView />} />
      <Route path={routes.userProjectsDetailsTaskUpload} element={<UserTaskUploadView />} />
      <Route path={routes.userMyTasks} element={<UserMyTasksView />} />

      <Route path={routes.userBillings} element={<UserBillingsView />} />
    </Route>

    {/* Tylko dla zalogowanych jako ADMIN*/}
    <Route element={<ProtectedRoute allowedRoles={[ROLES.admin.value]} />}>
      {/* <Route path={routes.admin} element={<AdminDashboardView />} /> */}

      <Route path={routes.adminProjects} element={<AdminProjectsView />} />
      <Route path={routes.adminProjectsDetails} element={<AdminProjectsDetailsView />} />
      <Route path={routes.adminProjectsDetailsUsers} element={<AdminProjectsDetailsUsersView />} />
      <Route path={routes.adminProjectsCreate} element={<AdminProjectsCreateView />} />
      <Route path={routes.adminProjectsEdit} element={<AdminProjectsEditView />} />

      <Route path={routes.adminProjectsEditTask} element={<AdminTaskEditView />} />
      <Route path={routes.adminProjectsDetailsTask} element={<AdminTaskDetailsView />} />
      <Route path={routes.adminProjectsCreateTasks} element={<AdminTaskCreateView />} />
      <Route path={routes.adminProjectsImportTasks} element={<AdminTaskImportView />} />

      <Route path={routes.adminBillings} element={<AdminBillingsView />} />
      <Route path={routes.adminBillingsUserDetails} element={<AdminBillingsUserDetails />} />

      <Route path={routes.adminUsers} element={<AdminUsersView />} />
      <Route path={routes.adminUsersDetails} element={<AdminUsersDetailsView />} />

      <Route path={routes.adminSettings} element={<AdminSettingsView />} />
    </Route>

    {/* Others */}

    <Route path={routes.noMatch} element={<Navigate to={routes.home} />} />
  </Routes>
)

export default Router
