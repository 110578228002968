import PropTypes from 'prop-types'
import Box from '@mui/joy/Box'
import Sidebar from 'components/Sidebar'
import Header from 'components/Header'
import GlobalErrorPopup from './GlobalErrorPopup'

const MainLayout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
      <Header />
      <Sidebar />
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          height: '100dvh',
          gap: 1,
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <GlobalErrorPopup />
        {children}
      </Box>
    </Box>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node,
}

export default MainLayout
