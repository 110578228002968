import { formatMoney } from '../helpers'

const commonStatus = {
  label: 'Status',
  propName: 'assignment',
  type: 'assigmentModeUser',
  mobileOrder: -1,
  rowClassName: 'text-center',
  className: 'text-center',
}

const commonNotes = {
  label: 'Uwagi',
  propName: 'notes',
  type: 'notes',
  rowClassName: 'text-center',
  className: 'text-center width-sm',
}

export const getUserTasksHeaders = (project) => {
  let headers = []

  if (project.type === 1)
    headers.push(
      { label: 'Data', propName: 'date', type: 'date', rowClassName: 'text-center', className: 'text-center width-sm' },
      { label: 'Godziny', propName: 'hours', rowClassName: 'text-center', className: 'text-center' },
      {
        label: 'Ilość godzin',
        propName: 'hoursCount',
        ignoreOnMobile: true,
        rowClassName: 'text-center',
        className: 'text-center width-sm',
      },
      {
        label: 'Wynagrodzenie brutto',
        type: 'computed',
        propName: 'not_used',
        rowClassName: 'text-center',
        className: 'text-center',
        fn: (task) => {
          if (!project || !project.hourlyRate || !task.hoursCount) return '-'
          return formatMoney(project.hourlyRate * Number(task.hoursCount))
        },
      },
      { label: 'Lokalizacja', propName: 'localization', rowClassName: 'text-center', className: 'text-center' },
      commonNotes,
      commonStatus
    )
  else if (project.type === 2)
    headers.push(
      {
        label: 'Termin',
        propName: 'date',
        type: 'date',
        rowClassName: 'text-center',
        className: 'text-center width-sm',
      },
      { label: 'Nr punktu', propName: 'pointNumber', rowClassName: 'text-center', className: 'text-center' },
      { label: 'Typ punktu ', propName: 'pointType', rowClassName: 'text-center', className: 'text-center' },
      { label: 'Długość ', propName: 'duration', rowClassName: 'text-center', className: 'text-center' },
      { label: 'SDR ', propName: 'sdr', rowClassName: 'text-center', className: 'text-center' },
      {
        label: 'Wynagrodzenie brutto',
        type: 'computed',
        propName: 'not_used',
        rowClassName: 'text-center',
        className: 'text-center',
        fn: (task) => formatMoney(task.rate),
      },
      commonNotes,
      commonStatus
    )

  return headers
}
