import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authService } from 'services/authService'
import { ACCESS_TOKEN_NAME, CUSTOM_ERROR_CODES } from 'utils/config'
import { handleErrorMessage } from 'utils/helpers'

// initialize userToken from local storage
const userToken = localStorage.getItem(ACCESS_TOKEN_NAME) ? localStorage.getItem(ACCESS_TOKEN_NAME) : null

const initialState = {
  userToken,
  userInfo: null,
  serverTime: null,
  loading: false,
  error: null,
  success: false,
  message: null,
}

export const loginUser = createAsyncThunk('auth/login', async (data, { rejectWithValue }) => {
  try {
    const response = await authService.login(data)
    localStorage.setItem(ACCESS_TOKEN_NAME, response.data.accessToken)
    return response.data
  } catch (error) {
    if (error.response?.data?.errorCode == CUSTOM_ERROR_CODES.EMAIL_NOT_VERIFIED)
      return rejectWithValue(CUSTOM_ERROR_CODES.EMAIL_NOT_VERIFIED)
    return rejectWithValue(handleErrorMessage(error))
  }
})

export const registerUser = createAsyncThunk('auth/register', async (data, { rejectWithValue }) => {
  const validData = { ...data }
  delete validData.confirmPassword
  try {
    const response = await authService.register(validData)
    return response.data
  } catch (error) {
    return rejectWithValue(handleErrorMessage(error))
  }
})

export const getMe = createAsyncThunk('auth/me', async (_, { rejectWithValue }) => {
  try {
    const response = await authService.getMe()
    return response.data
  } catch (error) {
    return rejectWithValue(handleErrorMessage(error))
  }
})

export const confirmEmail = createAsyncThunk('auth/confirm-email', async (token, { rejectWithValue }) => {
  try {
    const response = await authService.confirmEmail(token)
    return response.data
  } catch (error) {
    return rejectWithValue(handleErrorMessage(error))
  }
})

export const resetPassword = createAsyncThunk('auth/reset-password', async (data, { rejectWithValue }) => {
  try {
    const response = await authService.resetPassword(data)
    return response.data
  } catch (error) {
    return rejectWithValue(handleErrorMessage(error))
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem(ACCESS_TOKEN_NAME)
      return { ...initialState, userToken: null }
    },
    cleanupRequestStatus: (state) => {
      state.error = false
      state.loading = false
      state.success = false
    },
    setUserInfo: (state, { payload }) => {
      state.userInfo = payload
    },
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(loginUser.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false
        state.userToken = payload.accessToken
      })
      .addCase(loginUser.rejected, (state, { payload, test }) => {
        state.loading = false
        state.error = payload
        console.log(payload)
        console.log(test)
      })
      // register
      .addCase(registerUser.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false
        state.success = true
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      // Get Me
      .addCase(getMe.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getMe.fulfilled, (state, { payload }) => {
        state.loading = false
        state.userInfo = payload
        state.serverTime = payload.serverTime
      })
      .addCase(getMe.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        localStorage.removeItem(ACCESS_TOKEN_NAME)
      })
      // Confirm email
      .addCase(confirmEmail.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(confirmEmail.fulfilled, (state) => {
        state.loading = false
        state.success = true
      })
      .addCase(confirmEmail.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      // Password reset
      .addCase(resetPassword.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false
        state.success = true
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export const { logout, cleanupRequestStatus, setUserInfo } = authSlice.actions

export default authSlice.reducer
