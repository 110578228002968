import CircularProgress from '@mui/joy/CircularProgress'

const PageLoader = () => {
  return (
    <CircularProgress
      size="lg"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
      }}
    />
  )
}

export default PageLoader
