import { Box, Stack, Tooltip, Typography } from '@mui/joy/'
import { Email, Person, Phone } from '@mui/icons-material'
import PropTypes from 'prop-types'

const UserTooltip = ({ user, children, iconColor = 'primary', sx }) => {
  return (
    <Tooltip
      variant="outlined"
      arrow
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 320,
            justifyContent: 'center',
            p: 1,
          }}
        >
          <Box sx={{ display: 'flex', gap: 1, width: '100%', mt: 0 }}>
            <Stack spacing={1}>
              <Typography
                level="body-sm"
                startDecorator={<Person color={iconColor} fontSize="inherit" sx={{ marginRight: 0.5 }} />}
              >
                {user.firstName} {user.lastName}
              </Typography>
              <Typography
                level="body-sm"
                startDecorator={<Email color={iconColor} fontSize="inherit" sx={{ marginRight: 0.5 }} />}
              >
                {user.email || '-'}
              </Typography>
              <Typography
                level="body-sm"
                startDecorator={<Phone color={iconColor} fontSize="inherit" sx={{ marginRight: 0.5 }} />}
              >
                {user.phoneNumber || '-'}
              </Typography>
            </Stack>
          </Box>
        </Box>
      }
    >
      <Typography
        underline="none"
        sx={[{ display: 'flex', alignItems: 'center', justifyContent: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}
        startDecorator={<Person color={iconColor} fontSize="inherit" />}
      >
        {children}
      </Typography>
    </Tooltip>
  )
}

UserTooltip.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.node,
  sx: PropTypes.object,
  iconColor: PropTypes.string,
}

export default UserTooltip
