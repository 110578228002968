import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { cleanupRequestStatus, getProjects } from 'features/projectsSlice'
import { projectService } from 'services/projectsService'
import { handleErrorMessage } from 'utils/helpers'
import { PROJECT_JOIN_STATUS } from 'utils/config'
import routes from 'routes'
import useSearchParamValue from 'hooks/useSearchParamValue'
import usePagination from 'hooks/usePagination'
import Breadcrumbs from 'components/Breadcrumbs'
import FilterSearch from 'components/tables/FilterSearch'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import PageLoader from 'components/PageLoader'
import EnchantedTable from 'components/tables/EnchantedTable'

const headers = [
  {
    label: 'Nazwa projektu',
    propName: 'name',
    type: 'route',
    route: routes.userProjectsDetails,
    routeReplace: ':projectId',
    condition: (project) => {
      if (project.type == 1) return true
      return (
        project.type == 2 && project.joinRequest && project.joinRequest.status == PROJECT_JOIN_STATUS.approved.value
      )
    },
  },
  { label: 'Lokalizacja', propName: 'location', rowClassName: 'text-center', className: 'text-center' },
  {
    label: 'Stawka brutto',
    propName: 'hourlyRate',
    rowClassName: 'text-center',
    className: 'text-center',
    type: 'computed',
    fn: (project) => <Typography level="body-xs">{project.type == 2 ? '-' : `${project.hourlyRate} zł/h`}</Typography>,
  },
]

const UserProjectsView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const pagination = usePagination()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)

  const [searchFilter, setSearchFilter] = useSearchParamValue('search', '')

  const { projects, loading } = useSelector((s) => s.projects)

  const params = useMemo(() => ({ search: searchFilter || null })[searchFilter])

  const handleGoToProjectPage = (projectId) => navigate(routes.userProjectsDetails.replace(':projectId', projectId))

  const handleJoinToProject = (projectId) => {
    setInnerLoading(true)
    projectService
      .userProjectJoin(projectId)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Prośba o dołączenie do projektu została wysłana.', { variant: 'success' })
        dispatch(getProjects({ params }))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  useEffect(() => {
    dispatch(getProjects({ params }))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch, searchFilter])

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Projekty' }]} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Projekty
        </Typography>
      </Box>

      <FiltersWrapper
        renderSearch={<FilterSearch label="Wyszukiwanie projektu" value={searchFilter} setValue={setSearchFilter} />}
      />

      <EnchantedTable
        headers={headers}
        data={projects}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        buttonActions={[
          {
            label: 'Zobacz projekt',
            color: 'primary',
            loading: innerLoading,
            func: (project) => handleGoToProjectPage(project.id),
            display: (project) =>
              project.type == 1 ||
              (project.joinRequest && project.joinRequest.status == PROJECT_JOIN_STATUS.approved.value),
          },
          {
            label: 'Dołącz do projektu',
            color: 'success',
            loading: innerLoading,
            func: (project) => handleJoinToProject(project.id),
            display: (project) => project.type == 2 && !project.joinRequest,
          },
          {
            label: 'Trwa weryfikacja',
            color: 'danger',
            func: () => {},
            display: (project) =>
              project.type == 2 &&
              project.joinRequest &&
              project.joinRequest.status == PROJECT_JOIN_STATUS.pending.value,
            checkIfDisable: () => true,
          },
          {
            label: 'Zgłoszenie odrzucone',
            color: 'danger',
            func: () => {},
            display: (project) =>
              project.type == 2 &&
              project.joinRequest &&
              project.joinRequest.status == PROJECT_JOIN_STATUS.rejected.value,
            checkIfDisable: () => true,
          },
        ]}
        noDataText="Niedługo pojawi się tutaj pierwszy projekt."
      />

      {/* <TablePaginationDesktop pagination={pagination} /> */}
    </MainLayout>
  )
}

export default UserProjectsView
