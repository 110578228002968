import PropTypes from 'prop-types'
import { InfoOutlined } from '@mui/icons-material'
import { FormControl, FormHelperText, FormLabel, Option, Select } from '@mui/joy'
import { useEffect } from 'react'

const CustomSelectControl = ({ name, label, options, formik, sx, ...rest }) => {
  let fieldName = name
  let error = formik.errors[name]
  let touched = formik.touched[name]
  let value = formik.values[name]

  if (Array.isArray(name)) {
    const [mainProp, index, fieldProp] = name
    fieldName = name.join('.')
    error = formik.errors[mainProp] && formik.errors[mainProp][index] && formik.errors[mainProp][index][fieldProp]
    touched = formik.touched[mainProp] && formik.touched[mainProp][index] && formik.touched[mainProp][index][fieldProp]
    value = formik.values[mainProp][index][fieldProp]
  }

  useEffect(() => {
    formik.validateField(name)
  }, [value, touched])

  return (
    <FormControl error={touched && Boolean(error)} sx={sx} disabled={rest.disabled}>
      <FormLabel>{label}</FormLabel>
      <Select
        defaultValue={value}
        id={fieldName}
        name={fieldName}
        label={label}
        value={value}
        onChange={(e, newValue) => formik.setFieldValue(name, newValue, true)}
        onClose={() => formik.setFieldTouched(name, true)}
        {...rest}
      >
        {options.map((opt) => (
          <Option key={opt.value} value={opt.value}>
            {opt.label}
          </Option>
        ))}
      </Select>
      {touched && error && (
        <FormHelperText sx={{ gap: 1 }}>
          <InfoOutlined fontSize="small" />
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}
export default CustomSelectControl

CustomSelectControl.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  formik: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
}
