import PropTypes from 'prop-types'
import { Info, Payments, Phone, Place } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardOverflow, Divider, Stack, Typography } from '@mui/joy'
import { useFormik } from 'formik'
import { PROJECT_TYPES } from 'utils/config'
import newProjectSchema from 'validations/project/newProjectSchema'
import CustomDatePickerControl from 'components/forms/CustomDatePickerControl'
import CustomEditorControl from 'components/forms/CustomEditorControl'
import CustomInputControl from 'components/forms/CustomInputControl'
import CustomSelectControl from 'components/forms/CustomSelectControl'
import { useEffect, useState } from 'react'

const ProjectFormSubView = ({ handleSubmit, initialValues, loading = false }) => {
  const [disableType2Fields, setDisableType2Fields] = useState(false)

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: newProjectSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    if (formik.values.type == 2) {
      formik.setFieldValue('hourlyRate', 0)
      formik.setFieldValue('location', 'Online')
      setDisableType2Fields(true)
    } else {
      formik.setFieldValue('hourlyRate', initialValues.hourlyRate)
      formik.setFieldValue('location', initialValues.location)
      setDisableType2Fields(false)
    }
  }, [formik.values.type])
  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        sx={{
          display: 'flex',
          maxWidth: '800px',
          width: '100%',
          mx: 'auto',
          px: { xs: 2, md: 3 },
          py: { xs: 2, md: 3 },
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md" startDecorator={<Info />} color="primary">
            Uzupełnij dane do projektu
          </Typography>
        </Box>
        <Divider />
        <Stack direction="column" spacing={2} sx={{ mt: 1, mb: 2 }}>
          <Stack flexWrap="wrap" useFlexGap direction={{ lg: 'row' }} spacing={{ xs: 2 }}>
            <CustomSelectControl
              label="Typ projektu"
              name="type"
              options={PROJECT_TYPES}
              formik={formik}
              sx={{ flex: '0 1 50%' }}
            />
          </Stack>
          <CustomInputControl name="name" label="Tytuł projektu" formik={formik} />
          <CustomEditorControl name="description" label="Opis projektu" formik={formik} />
          <Stack flexWrap="wrap" useFlexGap direction={{ lg: 'row' }} spacing={{ xs: 2 }}>
            <CustomDatePickerControl
              name="startDate"
              label="Data startowa"
              formik={formik}
              sx={{ flex: '1', width: '100%' }}
            />
            <CustomDatePickerControl
              name="endDate"
              label="Data końcowa"
              formik={formik}
              sx={{ flex: '1', width: '100%' }}
            />
            <CustomInputControl
              name="location"
              label="Lokalizacja"
              formik={formik}
              startDecorator={<Place fontSize="small" />}
              sx={{ flex: '1', width: '100%' }}
            />
            <CustomInputControl
              disabled={disableType2Fields}
              info={disableType2Fields ? 'Wybrany typ projektu nie pozwala na edycję pola' : ''}
              type="number"
              name="hourlyRate"
              label="Stawka za godzinę pomiaru brutto"
              formik={formik}
              startDecorator={<Payments fontSize="small" />}
              endDecorator="PLN"
              sx={{ flex: '1', width: '100%' }}
            />
          </Stack>
          <CustomInputControl
            type="text"
            name="coordinationPhone"
            label="Telefon koordynacyjny"
            formik={formik}
            startDecorator={<Phone fontSize="small" />}
            sx={{ flex: '1', width: '100%' }}
          />
          <CustomEditorControl
            name="infoBanner"
            label="Banner informacyjny"
            formik={formik}
            sx={{ flex: '1', width: '100%' }}
          />
        </Stack>
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button onClick={() => formik.resetForm()} size="sm" variant="outlined" color="neutral" disabled={loading}>
              Anuluj
            </Button>
            <Button type="submit" disabled={!formik.dirty} size="sm" variant="solid" loading={loading}>
              Zapisz zmiany
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </form>
  )
}

export const projectShape = PropTypes.shape({
  type: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  hourlyRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  coordinationPhone: PropTypes.string.isRequired,
  infoBanner: PropTypes.string,
})

ProjectFormSubView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: projectShape,
  loading: PropTypes.bool,
}

export default ProjectFormSubView
