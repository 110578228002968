import { createRoot } from 'react-dom/client'
// import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@mui/material'
import { GlobalStyles, CssVarsProvider, CssBaseline } from '@mui/joy'
import { THEME_ID } from '@mui/joy/styles'
import { store } from 'app/store'
import App from './App'
import myAppTheme, { materialTheme } from 'theme'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <ThemeProvider theme={materialTheme}>
      <CssVarsProvider theme={{ [THEME_ID]: myAppTheme }}>
        <CssBaseline />
        <GlobalStyles
          styles={(theme) => ({
            ':root': {
              '--Form-maxWidth': '800px',
              '--Transition-duration': '0.4s', // set to `none` to disable transition
              '--Header-height': '52px',
              '--Sidebar-width': '220px',
              '--joy-zIndex-modal': '15000',
              '--joy-zIndex-tooltip': '14000',
            },
            [theme.breakpoints.up('md')]: {
              '--Header-height': '0px',
            },
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
            '.MuiListItemButton-root.active': {
              backgroundColor: `${theme.palette.neutral.plainActiveBg}`,
            },
            '.MuiPopper-root.MuiPickersPopper-root': {
              zIndex: '19000',
            },
            '.notistack-index-must-go-brr': {
              zIndex: '20000',
            },
          })}
        />
        <BrowserRouter>
          <SnackbarProvider
            classes={{ containerRoot: 'notistack-index-must-go-brr' }}
            maxSnack={5}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </CssVarsProvider>
    </ThemeProvider>
  </Provider>
)
