import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { handleErrorMessage, standarizeStatus } from 'utils/helpers'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { PersonSearch, Warning } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Modal,
  ModalDialog,
  Typography,
} from '@mui/joy'
import { cleanCurrentTask, getProjectDetails, getTaskDetails, getTaskReserveUsers } from 'features/projectsSlice'
import { getAutocompleteUsers } from 'features/autocompleteSlice'
import { CUSTOM_ERROR_CODES, STATUS } from 'utils/config'
import { projectService } from 'services/projectsService'
import routes from 'routes'
import Breadcrumbs from 'components/Breadcrumbs'
import MainLayout from 'components/templates/MainLayout'
import usePagination from 'hooks/usePagination'
import PageLoader from 'components/PageLoader'
import CustomAutocompleteControl from 'components/forms/CustomAutocompleteControl'
import manualAssignUserSchema from 'validations/task/manualAssignUserSchema'
import DeleteModal from 'components/DeleteModal'
import EnchantedTable from 'components/tables/EnchantedTable'
import TaskInfoType1SubView from 'views/common/TaskInfoType1SubView'
import TaskInfoType2SubView from 'views/common/TaskInfoType2SubView'

const getTaskName = (project, task) => {
  let name = ''
  if (!project || !task) return '?'
  if (project.type === 1) name = `${task.lp || task.id} - ${task.localization}`
  else if (project.type === 2) name = `${task.lp || task.id}`
  return name
}
const headers = [
  { label: 'Imię', propName: 'firstName' },
  { label: 'Nazwisko', propName: 'lastName' },
  { label: 'E-mail', propName: 'email' },
]

const AdminTaskDetailsView = () => {
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { projectId, taskId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [addCustomUser, setAddCustomUser] = useState(false)
  const [innerLoading, setInnerLoading] = useState(false)
  const [unassignUserObject, setUnassignUserObject] = useState(false)
  const [removeFromListUserObject, setRemoveFromListUserObject] = useState(false)
  const [forcedMode, setForcedMode] = useState(false)

  const { usersList, loading: autocompleteLoading, error: autocompleteError } = useSelector((s) => s.autocomplete)
  const { currentTask, currentProject, currentTaskReserveUsers, loading } = useSelector((s) => s.projects)

  const pagination = usePagination()

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    dispatch(getTaskDetails({ projectId, taskId }))
    dispatch(getTaskReserveUsers({ projectId, taskId }))
    return () => dispatch(cleanCurrentTask())
  }, [])

  const handleAssignProjectTaskUser = (userId) => {
    setInnerLoading(true)
    projectService
      .adminAssignProjectTaskUser({ projectId, taskId, userId, force: Boolean(forcedMode) || null })
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Użytkownik został przypisany.', { variant: 'success' })
        setAddCustomUser(false)
        dispatch(getTaskDetails({ projectId, taskId }))
        dispatch(getTaskReserveUsers({ projectId, taskId }))
      })
      .catch((err) => {
        console.log(err)
        if (
          err.response?.data?.errorCode &&
          err.response.data.errorCode == CUSTOM_ERROR_CODES.TASK_ALREADY_ASSIGNED_SAME_DAY
        ) {
          setForcedMode(err.response.data.errorCode)
          enqueueSnackbar('Kolejna próba przypisania zignoruje ograniczenie jednego zadania dziennie.', {
            variant: 'info',
            autoHideDuration: 10000,
          })
        }

        if (
          err.response?.data?.errorCode &&
          err.response.data.errorCode == CUSTOM_ERROR_CODES.BILLING_INFO_INCOMPLETE
        ) {
          setForcedMode(err.response.data.errorCode)
          enqueueSnackbar('Kolejna próba przypisania zignoruje ograniczenie braku uzupełnienia danych do umowy.', {
            variant: 'info',
            autoHideDuration: 10000,
          })
        }

        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error', autoHideDuration: 10000 })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleAction = (action, successMessage, callback) => {
    setInnerLoading(true)
    action
      .then((res) => {
        console.log(res)
        enqueueSnackbar(successMessage, { variant: 'success' })
        callback()
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleUnassignProjectTaskUser = (userId) => {
    handleAction(
      projectService.adminUnassignProjectTaskUser(projectId, taskId, userId),
      'Użytkownik został odpięty.',
      () => {
        setUnassignUserObject(false)
        dispatch(getTaskDetails({ projectId, taskId }))
      }
    )
  }

  const handleRemoveUserFromReserve = (userId) => {
    handleAction(
      projectService.adminRemoveProjectTaskReserveUser(projectId, taskId, userId),
      'Użytkownik został usunięty z lity.',
      () => {
        setRemoveFromListUserObject(false)
        dispatch(getTaskReserveUsers({ projectId, taskId }))
      }
    )
  }

  const getTaskInfo = () => {
    switch (currentProject.type) {
      case 1:
        return <TaskInfoType1SubView project={currentProject} task={currentTask} />
      case 2:
        return <TaskInfoType2SubView project={currentProject} task={currentTask} />
      default:
        break
    }
  }

  if (!projectId || !taskId) return <Navigate to={routes.adminProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  if (!currentProject || !currentTask) return <MainLayout></MainLayout>

  return (
    <MainLayout>
      <DeleteModal
        open={unassignUserObject}
        handleDelete={() => handleUnassignProjectTaskUser(unassignUserObject.userId)}
        loading={innerLoading}
        setObject={setUnassignUserObject}
        btnText="Odepnij"
      >
        Czy jesteś pewny, ze chcesz odpiąć:{' '}
        <Typography color="primary" fontWeight={500}>
          {unassignUserObject.firstName} {unassignUserObject.lastName}
        </Typography>
      </DeleteModal>
      <DeleteModal
        open={removeFromListUserObject}
        handleDelete={() => handleRemoveUserFromReserve(removeFromListUserObject.userId)}
        loading={innerLoading}
        setObject={setRemoveFromListUserObject}
      >
        Czy jesteś pewny, ze chcesz usunąć z listy rezerwowej:{' '}
        <Typography color="primary" fontWeight={500}>
          {removeFromListUserObject.firstName} {removeFromListUserObject.lastName}
        </Typography>
      </DeleteModal>
      <Modal
        open={Boolean(addCustomUser)}
        onClose={() => {
          setAddCustomUser(false)
          setForcedMode(false)
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog" sx={{ width: '100%', maxWidth: '500px' }}>
          <DialogTitle>Ręczne przypisanie użytkownika</DialogTitle>
          <Divider />
          {forcedMode && forcedMode == CUSTOM_ERROR_CODES.TASK_ALREADY_ASSIGNED_SAME_DAY && (
            <Alert color="warning" variant="soft">
              Uwaga! Kolejna próba przypisania zignoruje ograniczenie maksymalnie jednego zadania dziennie.
            </Alert>
          )}
          {forcedMode && forcedMode == CUSTOM_ERROR_CODES.BILLING_INFO_INCOMPLETE && (
            <Alert color="warning" variant="soft">
              Uwaga! Kolejna próba przypisania zignoruje ograniczenie braku uzupełnienia danych do umowy.
            </Alert>
          )}
          <Formik
            initialValues={{ user: null }}
            validationSchema={manualAssignUserSchema}
            onSubmit={(values) => {
              const { user } = values
              if (user.id) handleAssignProjectTaskUser(user.id)
            }}
          >
            {({ handleSubmit, resetForm, ...props }) => (
              <form onSubmit={handleSubmit}>
                {autocompleteError && (
                  <Alert color="danger" variant="soft" startDecorator={<Warning />}>
                    {autocompleteError}
                  </Alert>
                )}
                {autocompleteLoading && <LinearProgress sx={{ my: 2 }} />}
                {usersList && (
                  <DialogContent sx={{ overflow: 'visible' }}>
                    <CustomAutocompleteControl
                      name="user"
                      label="Szukaj użytkownika"
                      options={usersList}
                      formik={props}
                      onSelect={() => setForcedMode(false)}
                    />
                    <DialogActions>
                      <Button
                        type="submit"
                        variant="solid"
                        color="danger"
                        loading={innerLoading || autocompleteLoading}
                      >
                        {forcedMode &&
                          forcedMode == CUSTOM_ERROR_CODES.TASK_ALREADY_ASSIGNED_SAME_DAY &&
                          'Przypisz igrnoując limit'}
                        {forcedMode &&
                          forcedMode == CUSTOM_ERROR_CODES.BILLING_INFO_INCOMPLETE &&
                          'Przypisz igrnoując brak danych'}
                        {!forcedMode && 'Przypisz'}
                      </Button>
                      <Button
                        variant="plain"
                        color="neutral"
                        onClick={() => {
                          setAddCustomUser(false)
                          setForcedMode(false)
                          resetForm()
                        }}
                        loading={innerLoading || autocompleteLoading}
                      >
                        Anuluj
                      </Button>
                    </DialogActions>
                  </DialogContent>
                )}
              </form>
            )}
          </Formik>
        </ModalDialog>
      </Modal>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.adminProjects },
            {
              label: currentProject?.name || state?.from || projectId,
              to: routes.adminProjectsDetails.replace(':projectId', projectId),
            },
            { label: getTaskName(currentProject, currentTask) },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Zadanie:&nbsp;
          <Typography color="primary">{currentTask?.localization}</Typography>
        </Typography>
        <Button
          color="primary"
          startDecorator={<PersonSearch fontSize="small" />}
          onClick={() => {
            setAddCustomUser(true)
            dispatch(getAutocompleteUsers())
          }}
        >
          Przypisz użytkownika ręcznie
        </Button>
      </Box>

      {getTaskInfo()}

      <Typography color="primary" fontWeight={500} sx={{ mt: 2 }}>
        Przypisany:
      </Typography>
      <EnchantedTable
        headers={headers}
        data={currentTask.assignment ? [{ ...currentTask.assignment }] : []}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        noDataText="Brak przypisanej osoby."
        buttonActions={[
          {
            label: 'Odepnij',
            color: 'danger',
            func: (el) => setUnassignUserObject(el),
            loading: innerLoading,
            display: () => standarizeStatus(currentTask.status) !== STATUS.done.value,
          },
        ]}
      />

      {currentTaskReserveUsers && (
        <>
          <Typography color="primary" fontWeight={500} sx={{ mt: 2 }}>
            Lista rezerwowa:
          </Typography>
          <EnchantedTable
            headers={headers}
            data={currentTaskReserveUsers}
            offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
            noDataText="Brak listy rezerwowej."
            buttonActions={[
              {
                label: 'Przypisz do zadania',
                color: 'primary',
                func: (el) => handleAssignProjectTaskUser(el.userId),
                loading: innerLoading,
                display: () => standarizeStatus(currentTask.status) !== STATUS.done.value,
              },
              {
                label: 'Usuń z listy',
                color: 'danger',
                func: (user) => setRemoveFromListUserObject(user),
                loading: innerLoading,
              },
            ]}
          />
        </>
      )}
    </MainLayout>
  )
}

export default AdminTaskDetailsView
