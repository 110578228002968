import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/joy'
import { cleanupRequestStatus, getUsers } from 'features/usersSlice'
import { ROLES } from 'utils/config'
import { usersService } from 'services/usersService'
import { handleErrorMessage } from 'utils/helpers'
import routes from 'routes'
import usePagination from 'hooks/usePagination'
import useSearchParamValue from 'hooks/useSearchParamValue'
import Breadcrumbs from 'components/Breadcrumbs'
import FilterSearch from 'components/tables/FilterSearch'
import FilterSelect from 'components/tables/FilterSelect'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import PageLoader from 'components/PageLoader'
import DeleteModal from 'components/DeleteModal'
import EnchantedTable from 'components/tables/EnchantedTable'
import AdvancedTooltip from 'components/UI/AdvancedTooltip'
import UserEngagementTooltip from 'components/UI/UserEngagementTooltip'

const headers = [
  { label: 'Imię', propName: 'firstName' },
  {
    label: 'Nazwisko',
    propName: 'lastName',
    type: 'computed',
    fn: (user) => {
      if (user.adminNote)
        return (
          <AdvancedTooltip
            title=""
            description={user.adminNote}
            sx={{ justifyContent: 'flex-start' }}
            iconColor="warning"
          >
            {user.lastName}
          </AdvancedTooltip>
        )
      return user.lastName
    },
  },
  { label: 'E-mail', propName: 'email' },
  { label: 'Telefon', propName: 'phoneNumber' },
  {
    label: 'Aktywność',
    propName: 'engagement',
    type: 'computed',
    className: 'width-md',
    fn: (user) => <UserEngagementTooltip user={user} />,
  },
  {
    label: 'Weryfikacja',
    propName: 'isEmailVerified',
    type: 'check',
    className: 'width-sm',
  },
  {
    label: 'Akceptacja',
    propName: 'isAccountConfirmed',
    type: 'check',
    className: 'width-sm',
  },
  {
    label: 'Umowa',
    propName: 'isBillingInfoComplete',
    type: 'check',
    className: 'width-xm',
  },
  {
    label: 'Blokada',
    propName: 'isBlocked',
    type: 'blocked',
    className: 'width-xm',
  },
]

const AdminUsersView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { users, loading } = useSelector((s) => s.users)

  const [innerLoading, setInnerLoading] = useState(false)
  const [blockUserObject, setBlockUserObject] = useState(false)
  const [deleteUserObject, setDeleteUserObject] = useState(false)
  const [deleteUsersArray, setDeleteUsersArray] = useState(false)

  const [roleFilter, setRoleFilter] = useSearchParamValue('role', ROLES.user.value)
  const [searchFilter, setSearchFilter] = useSearchParamValue('search', '')

  const pagination = usePagination()

  const params = useMemo(
    () => ({
      role: roleFilter || null,
      search: searchFilter || null,
    }),
    [searchFilter, roleFilter]
  )

  useEffect(() => {
    dispatch(getUsers({ params }))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch, params])

  const handleGoToUserPage = (userId) => navigate(routes.adminUsersDetails.replace(':userId', userId))

  const handleActivateUser = (userId) => {
    usersService
      .adminUsersActivate(userId)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Użytownik został aktywowany', { variant: 'success' })
        dispatch(getUsers({ params }))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
  }

  const handleUnblockUser = (userId) => {
    setInnerLoading(true)
    usersService
      .unblockUser(userId)
      .then((res) => {
        console.log(res)
        enqueueSnackbar(res.data?.message || 'Użytkownik został odblokowany', { variant: 'success' })
        dispatch(getUsers({ params }))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleDeleteUser = () => {
    setInnerLoading(true)

    let userId = deleteUserObject.id
    if (blockUserObject) userId = blockUserObject.id

    usersService
      .deleteUser(userId)
      .then((res) => {
        console.log(res)
        enqueueSnackbar(res.data?.message || 'Użytkownik został usunięty', { variant: 'success' })
        setDeleteUserObject(false)
        setBlockUserObject(false)
        dispatch(getUsers({ params }))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleActivateBulk = (users) => {
    usersService
      .adminUsersActivateBulk(users.map((u) => u.id))
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Użytkownicy zostali aktywowani', { variant: 'success' })
        dispatch(getUsers({ params }))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar('W wyniku błędu żaden z wybranych użytkowników nie został aktywowany.', {
          variant: 'error',
          autoHideDuration: 10000,
        })
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error', autoHideDuration: 10000 })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleDeleteUsersBulk = () => {
    setInnerLoading(true)
    usersService
      .deleteUsersBulk(deleteUsersArray.map((u) => u.id))
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Użytkownicy zostali usunięci.', { variant: 'success' })
        setDeleteUsersArray(false)
        dispatch(getUsers({ params }))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar('W wyniku błędu żaden z wybranych użytkowników nie został usunięty.', {
          variant: 'error',
          autoHideDuration: 10000,
        })
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error', autoHideDuration: 10000 })
      })
      .finally(() => setInnerLoading(false))
  }

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <DeleteModal
        open={deleteUserObject || blockUserObject || deleteUsersArray}
        handleDelete={() => {
          if (deleteUserObject || blockUserObject) handleDeleteUser()
          else if (deleteUsersArray) handleDeleteUsersBulk()
        }}
        loading={innerLoading}
        setObject={(val) => {
          setDeleteUserObject(val)
          setBlockUserObject(val)
          setDeleteUsersArray(val)
        }}
        btnText={deleteUserObject || deleteUsersArray ? 'Usuń' : 'Zablokuj'}
      >
        {deleteUserObject && (
          <>
            Czy jesteś pewny, ze chcesz usunąć użytkownika:{' '}
            <Typography color="primary" fontWeight={500}>
              {deleteUserObject.email}
            </Typography>
          </>
        )}
        {blockUserObject && (
          <>
            Czy jesteś pewny, ze chcesz zablokować użytkownika:{' '}
            <Typography color="primary" fontWeight={500}>
              {blockUserObject.email}
            </Typography>
          </>
        )}
        {deleteUsersArray && <>Czy jesteś pewny, ze chcesz usunąć {deleteUsersArray.length} użytkowników?</>}
      </DeleteModal>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Użytkownicy' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Użytkownicy
        </Typography>
      </Box>

      <FiltersWrapper
        renderSearch={
          <FilterSearch label="Wyszukiwanie użytkowników" value={searchFilter} setValue={setSearchFilter} />
        }
        renderFilters={
          <FilterSelect
            label="Rola"
            placeholder="Filtruj po roli"
            options={[
              { value: ROLES.user.value, label: ROLES.user.label },
              { value: ROLES.admin.value, label: ROLES.admin.label },
            ]}
            value={roleFilter}
            setValue={setRoleFilter}
          />
        }
      />

      <EnchantedTable
        headers={headers}
        data={users}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        actions={[
          { label: 'Zobacz', func: (user) => handleGoToUserPage(user.id) },
          {
            label: 'Aktywuj',
            divider: true,
            func: (user) => handleActivateUser(user.id),
            display: (user) => !user.isAccountConfirmed,
          },
          {
            label: 'Zablokuj',
            color: 'warning',
            divider: true,
            func: (user) => setBlockUserObject(user),
            display: (user) => user.hasProblematicRelation && !user.isBlocked,
          },
          {
            label: 'Odblokuj',
            color: 'warning',
            divider: true,
            func: (user) => handleUnblockUser(user.id),
            display: (user) => user.isBlocked,
          },
          {
            label: 'Usuń',
            color: 'danger',
            divider: true,
            func: (user) => setDeleteUserObject(user),
            display: (user) => !user.hasProblematicRelation,
          },
        ]}
        bulkActions={[
          {
            label: 'Aktywuj użytkowników',
            color: 'primary',
            divider: true,
            func: (selectedUsers) => handleActivateBulk(selectedUsers),
          },
          {
            label: 'Usuń użytkowników',
            color: 'danger',
            divider: true,
            func: (selectedUsers) => setDeleteUsersArray(selectedUsers),
          },
        ]}
      />
    </MainLayout>
  )
}

export default AdminUsersView
