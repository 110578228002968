import { useSearchParams } from 'react-router-dom'

const useSearchParamValue = (key, defaultValue) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const param = searchParams.get(key)
  const value = param || param === '' ? param : defaultValue

  const setValue = (newValue) => {
    setSearchParams(
      (prev) => {
        prev.set(key, newValue)
        return prev
      },
      { replace: true }
    )
  }

  return [value, setValue]
}

export default useSearchParamValue
