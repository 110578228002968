import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Box, Typography } from '@mui/joy'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { projectService } from 'services/projectsService'
import { cleanCurrentTask, getProjectDetails, getTaskDetails } from 'features/projectsSlice'
import { handleErrorMessage } from 'utils/helpers'
import routes from 'routes'
import MainLayout from 'components/templates/MainLayout'
import Breadcrumbs from 'components/Breadcrumbs'
import TasksFormType1SubView from './common/TasksFormType1SubView'
import TasksFormType2SubView from './common/TasksFormType2SubView'
import PageLoader from 'components/PageLoader'

const AdminTaskEditView = () => {
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { projectId, taskId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)

  const { currentProject, currentTask, loading } = useSelector((s) => s.projects)

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    dispatch(getTaskDetails({ projectId, taskId }))
    return () => dispatch(cleanCurrentTask())
  }, [])

  const handleUpdateTask = (values) => {
    setInnerLoading(true)
    const { tasks } = values

    if (!tasks[0]) return enqueueSnackbar('Obiekt zadania jest nieprawidłowy.', { variant: 'error' })

    projectService
      .adminUpdateProjectTask(projectId, taskId, values.tasks[0])
      .then((res) => {
        console.log(res)

        enqueueSnackbar(
          `Zadanie zostało zaktualizowane. ${values.tasks[0].files.length > 0 ? 'Trwa wysyłanie załączników.' : ''}`,
          { variant: 'info' }
        )

        if (values.tasks[0].files && values.tasks[0].files.length > 0)
          projectService
            .adminUploadTaskFiles(projectId, taskId, values.tasks[0].files)
            .then((values) => {
              console.log(values)
              enqueueSnackbar('Załączniki zostały zaktualizowane.', { variant: 'success' })
              dispatch(getTaskDetails({ projectId, taskId }))
            })
            .catch((err) => {
              console.log(err)
              enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
            })
            .finally(() => setInnerLoading(false))
        else setInnerLoading(false)
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
        setInnerLoading(false)
      })
  }

  const getTaskForm = (projectType) => {
    switch (projectType) {
      case 1: {
        return (
          <TasksFormType1SubView
            handleSubmit={handleUpdateTask}
            loading={innerLoading}
            initialTasks={[{ ...(currentTask || {}), hoursCount: Number(currentTask.hoursCount) }]}
            singleMode
          />
        )
      }
      case 2: {
        return (
          <TasksFormType2SubView
            handleSubmit={handleUpdateTask}
            loading={innerLoading}
            initialTasks={[{ ...(currentTask || {}) }]}
            singleMode
          />
        )
      }
      default:
        break
    }
  }

  if (!projectId || !taskId) return <Navigate to={routes.adminProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  if (!currentProject || !currentTask) return <MainLayout></MainLayout>

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.adminProjects },
            {
              label: currentProject?.name || state?.from || projectId,
              to: routes.adminProjectsDetails.replace(':projectId', projectId),
            },
            { label: 'Edycja zadania' },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Edytuj zadanie
        </Typography>
      </Box>

      {getTaskForm(currentProject.type)}
    </MainLayout>
  )
}

export default AdminTaskEditView
