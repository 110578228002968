import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { Check, ChevronLeft, ChevronRight } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  Card,
  CardActions,
  CardOverflow,
  Stepper,
  Step,
  StepIndicator,
} from '@mui/joy'
import { nationalitiesArray, officeNFZArray, voivodshipsArray } from 'utils/constants'
import { usersService } from 'services/usersService'
import { profileService } from 'services/profileService'
import { handleErrorMessage } from 'utils/helpers'
import { setUserInfo } from 'features/authSlice'
import { setCurrentUser } from 'features/usersSlice'
import { getAdditionalDataSchema } from 'validations/profile/additionalDataSchema'
import CustomInputControl from 'components/forms/CustomInputControl'
import CustomAutocompleteControl from 'components/forms/CustomAutocompleteControl'
import CustomCheckboxControl from 'components/forms/CustomCheckboxControl'
import CustomRadioControl from 'components/forms/CustomRadioControl'
import CustomDatePickerControl from 'components/forms/CustomDatePickerControl'

const getDescription = (mode) => {
  if (mode === 'profile') return 'Uzupełnij dane do umowy'
  if (mode === 'editUser') return 'Edytuj dane do umowy użytkownika'
}

const getDescriptionQuestionnaire = (mode) => {
  if (mode === 'profile') return 'Uzupełnij ankietę'
  if (mode === 'editUser') return 'Edytuj ankietę użytkownika'
}

const isPolishNationality = (nationality) => {
  if (nationality && nationality.id && nationality.id === 'PL') return true
  if (nationality && nationality === 'PL') return true
  return false
}

const isUkrainianNationality = (nationality) => {
  if (nationality && nationality.id && nationality.id === 'UA') return true
  if (nationality && nationality === 'UA') return true
  return false
}

const stepOneFields = [
  'street',
  'buildingNumber',
  'apartmentNumber',
  'voivodship',
  'postalCode',
  'city',
  'pesel',
  'passportNumber',
  'bankAccountNumber',
  'taxOffice',
  'commune',
  'nationality',
  'birthDate',
]

const hasStepOneError = (errors) => stepOneFields.some((field) => errors[field])

const AdditionalDataSubView = ({ userInfo, mode }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { userInfo: loggedUser } = useSelector((s) => s.auth)

  const [activeStep, setActiveStep] = useState(0)
  const [innerLoading, setInnerLoading] = useState(false)

  const { id } = userInfo

  // I dont use destruction, bc props can be null, and null makes some other errors so '' is better
  const street = userInfo.street || ''
  const buildingNumber = userInfo.buildingNumber || ''
  const apartmentNumber = userInfo.apartmentNumber || ''
  const postalCode = userInfo.postalCode || ''
  const city = userInfo.city || ''
  const birthDate = userInfo.birthDate || ''
  const pesel = userInfo.pesel || ''
  const passportNumber = userInfo.passportNumber || ''
  const bankAccountNumber = userInfo.bankAccountNumber || ''
  const taxOffice = userInfo.taxOffice || ''
  const commune = userInfo.commune || ''
  // Ankieta

  const {
    isStudentUnder26 = false,
    hasOtherJobAndLessThenMinimumWage = false,
    hasOtherJobAndMoreThenMinimumWage = false,
    hasBusinessAndContributions60 = false,
    hasBusinessAndContributions30 = false,
    hasBussinesAndSameServices = false,
    hasOtherJobButIsOnUnpaidOrParentalLeave = false,
    hasOtherJobButIsOnMaternityLeave = false,
    hasNoOtherSocialInsurance = false,
    isRetired = false,
    hasDisability = false,
    hasDisabilityOther = '',
    healthContribution = '',
    otherCircumstances = false,
    otherCircumstancesOther = '',
    exemptionPIT = '',
    taxResidenceCertificate = '',
    taxResidenceCertificateStartDate = '',
    taxResidenceCertificateEndDate = '',
    termsChanges = false,
    termsRodo = false,
  } = userInfo.UserEmploymentDetails

  // Check if data is { id: 'XX', label: 'YYY' } if not replace
  const nationality =
    userInfo.nationality && userInfo.nationality.id && userInfo.nationality.label
      ? userInfo.nationality
      : nationalitiesArray.find((el) => el.id === userInfo.nationality) || null

  const voivodship =
    userInfo.voivodship && userInfo.voivodship.id && userInfo.voivodship.label
      ? userInfo.voivodship
      : voivodshipsArray.find((el) => el.id === userInfo.voivodship) || null

  const officeNFZ =
    userInfo.UserEmploymentDetails.officeNFZ &&
    userInfo.UserEmploymentDetails.officeNFZ.id &&
    userInfo.UserEmploymentDetails.officeNFZ.label
      ? userInfo.UserEmploymentDetails.officeNFZ
      : officeNFZArray.find((el) => el.id === userInfo.UserEmploymentDetails.officeNFZ) || null

  const formik = useFormik({
    initialValues: {
      street,
      buildingNumber,
      apartmentNumber,
      postalCode,
      city,
      birthDate,
      nationality,
      pesel,
      passportNumber,
      bankAccountNumber,
      taxOffice,
      voivodship,
      commune,
      // Ankieta
      isStudentUnder26,
      hasOtherJobAndLessThenMinimumWage,
      hasOtherJobAndMoreThenMinimumWage,
      hasBusinessAndContributions60,
      hasBusinessAndContributions30,
      hasBussinesAndSameServices,
      hasOtherJobButIsOnUnpaidOrParentalLeave,
      hasOtherJobButIsOnMaternityLeave,
      hasNoOtherSocialInsurance,
      isRetired,
      hasDisability,
      hasDisabilityOther,
      healthContribution,
      otherCircumstances,
      otherCircumstancesOther,
      exemptionPIT,
      taxResidenceCertificate,
      taxResidenceCertificateStartDate,
      taxResidenceCertificateEndDate,
      officeNFZ,
      termsChanges,
      termsRodo,
    },
    validationSchema: getAdditionalDataSchema(loggedUser),
    onSubmit: (values) => {
      const nationality = values.nationality.id || values.nationality
      const voivodship = values.voivodship.id || values.voivodship
      const officeNFZ = values.officeNFZ.id || values.officeNFZ
      const taxResidenceCertificate = values.taxResidenceCertificate || ''

      setInnerLoading(true)

      let picketMethod = profileService.updateProfileContractData
      if (mode === 'editUser') picketMethod = usersService.updateUserContractData

      picketMethod(id, { ...values, nationality, voivodship, officeNFZ, taxResidenceCertificate })
        .then((res) => {
          console.log(res)
          enqueueSnackbar('Dane do umowy zostały zaktualizowane', { variant: 'success' })
          // Reload profile or current user object based on mode
          if (mode === 'profile') dispatch(setUserInfo(res.data))
          else if (mode === 'editUser') dispatch(setCurrentUser(res.data))
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
        })
        .finally(() => setInnerLoading(false))
    },
  })

  const handleGoNextStep = () => {
    if (!hasStepOneError(formik.errors)) return setActiveStep((i) => i + 1)
    formik.setTouched(
      stepOneFields.reduce((obj, fieldName) => {
        obj[fieldName] = true
        return obj
      }, {})
    )
  }

  useEffect(() => {
    if (isPolishNationality(formik.values.nationality)) formik.setFieldValue('passportNumber', '')
    if (!isPolishNationality(formik.values.nationality) && !isUkrainianNationality(formik.values.nationality))
      formik.setFieldValue('pesel', '')
  }, [formik.values.nationality])

  const StepOneMarkup = (
    <Stack direction="column" spacing={2} sx={{ mt: 1, mb: 2 }}>
      <CustomInputControl name="street" label="Ulica" formik={formik} />
      <Stack direction={{ lg: 'row' }} spacing={{ xs: 2 }}>
        <CustomInputControl name="buildingNumber" label="Numer domu" formik={formik} sx={{ flexBasis: '100%' }} />
        <CustomInputControl name="apartmentNumber" label="Numer lokalu" formik={formik} sx={{ flexBasis: '100%' }} />
      </Stack>
      <Stack direction={{ lg: 'row' }} spacing={{ xs: 2 }}>
        <CustomAutocompleteControl
          name="voivodship"
          label="Województwo"
          options={voivodshipsArray}
          formik={formik}
          sx={{ flexBasis: '100%' }}
        />
        <CustomInputControl name="commune" label="Gmina" formik={formik} sx={{ flexBasis: '100%' }} />
      </Stack>
      <Stack direction={{ lg: 'row' }} spacing={{ xs: 2 }}>
        <CustomInputControl name="postalCode" label="Kod pocztowy" formik={formik} sx={{ flexBasis: '100%' }} />
        <CustomInputControl name="city" label="Miejscowość" formik={formik} sx={{ flexBasis: '100%' }} />
      </Stack>
      <CustomDatePickerControl
        name="birthDate"
        label="Data urodzenia"
        formik={formik}
        sx={{ flex: '1', minWidth: '1px' }}
      />
      <CustomAutocompleteControl name="nationality" label="Obywatelstwo" options={nationalitiesArray} formik={formik} />
      <CustomInputControl
        name="pesel"
        label="PESEL"
        formik={formik}
        disabled={
          !isPolishNationality(formik.values.nationality) && !isUkrainianNationality(formik.values.nationality)
            ? true
            : false
        }
      />
      <CustomInputControl
        name="passportNumber"
        label="Numer paszportu"
        formik={formik}
        disabled={isPolishNationality(formik.values.nationality) ? true : false}
      />
      <CustomInputControl name="bankAccountNumber" label="Numer konta" formik={formik} />
      <CustomInputControl name="taxOffice" label="Urząd Skarbowy" formik={formik} />
    </Stack>
  )

  const StepTwoMarkup = (
    <Stack direction="column" spacing={2} sx={{ mt: 1, mb: 2 }}>
      <CustomCheckboxControl
        name="isStudentUnder26"
        label="Jestem studentem/uczniem szkoły ponadpodstawowej i nie ukończyłem(am) 26 lat"
        formik={formik}
      />
      <CustomCheckboxControl
        name="hasOtherJobAndLessThenMinimumWage"
        label="Jestem dodatkowo zatrudniony/a gdzie indziej i z tego tytułu moja łączna podstawa składek społecznych w
          danym miesiącu wynosi mniej niż minimalne wynagrodzenie za pracę"
        formik={formik}
      />
      <CustomCheckboxControl
        name="hasOtherJobAndMoreThenMinimumWage"
        label="Jestem dodatkowo zatrudniony/a gdzie indziej i z tego tytułu moja łączna podstawa składek społecznych w
          danym miesiącu wynosi co najmniej tyle ile minimalne wynagrodzenie za pracę"
        formik={formik}
      />
      <CustomCheckboxControl
        name="hasBusinessAndContributions60"
        label="Prowadzę pozarolniczą działalność gospodarczą, z tytułu której opłacam składki od podstawy wymiaru
          wynoszącej co najmniej 60% prognozowanej przeciętnej płacy miesięcznej"
        formik={formik}
      />
      <CustomCheckboxControl
        name="hasBusinessAndContributions30"
        label="Prowadzę pozarolniczą działalność gospodarczą, z tytułu której opłacam składki od preferencyjnej
          podstawy wynoszącej 30% minimalnego wynagrodzenia"
        formik={formik}
      />
      <CustomCheckboxControl
        name="hasBussinesAndSameServices"
        label="Usługi wykonywane w ramach umowy, której dotyczy to oświadczenie, wchodzą w zakres prowadzonej przeze
          mnie działalności gospodarczej stanowiącej tytuł do ubezpieczeń społecznych"
        formik={formik}
      />
      <CustomCheckboxControl
        name="hasOtherJobButIsOnUnpaidOrParentalLeave"
        label="Jestem pracownikiem innego zakładu lecz w okresie wykonywania umowy, której dotyczy to oświadczenie,
          przebywam na urlopie bezpłatnym lub wychowawczym"
        formik={formik}
      />
      <CustomCheckboxControl
        name="hasOtherJobButIsOnMaternityLeave"
        label="Jestem pracownikiem innego zakładu lecz w okresie wykonywania umowy, której dotyczy to oświadczenie,
          przebywam na urlopie macierzyńskim"
        formik={formik}
      />
      <CustomCheckboxControl
        name="hasNoOtherSocialInsurance"
        label="Nie posiadam innych tytułów do ubezpieczeń społecznych"
        formik={formik}
      />
      <CustomCheckboxControl name="isRetired" label="Jestem emerytem/rencistą" formik={formik} />
      {/* Połączone pola */}
      <CustomCheckboxControl
        name="hasDisability"
        label="Legitymuję się orzeczeniem o stopniu niepełnosprawności."
        formik={formik}
      />
      {formik.values.hasDisability && (
        <CustomInputControl name="hasDisabilityOther" label="Jestem niepełnosprawny/a w stopniu:" formik={formik} />
      )}
      {/* Połączone pola END */}
      <CustomRadioControl
        name="healthContribution"
        label={
          <>
            Składka zdrowotna <Typography color="danger">*</Typography>
          </>
        }
        options={[
          { label: 'Wnoszę o objęcie mnie dobrowolnym ubezpieczeniem chorobowym', value: true },
          { label: 'Nie wnoszę o objęcie mnie dobrowolnym ubezpieczeniem chorobowym', value: false },
        ]}
        formik={formik}
      />
      {/* Połączone pola */}
      <CustomCheckboxControl
        name="otherCircumstances"
        label="Inne okoliczności mające wpływ na ubezpieczenia w ZUS np. KRUS, Urząd Pracy"
        formik={formik}
      />
      {formik.values.otherCircumstances && (
        <CustomInputControl name="otherCircumstancesOther" label="Opisz okoliczności:" formik={formik} />
      )}
      {/* Połączone pola END */}
      <CustomRadioControl
        name="exemptionPIT"
        label={
          <>
            Zwolnienie z PIT <Typography color="danger">*</Typography>
          </>
        }
        options={[
          { label: 'Chcę, aby moje przychody zostały objęte zwolnieniem z PIT', value: true },
          { label: 'Nie chcę, aby moje przychody zostały objęte zwolnieniem z PIT', value: false },
        ]}
        formik={formik}
      />
      {!isPolishNationality(formik.values.nationality) && (
        <>
          <CustomRadioControl
            name="taxResidenceCertificate"
            label={
              <>
                Certyfikat rezydencji podatkowej <Typography color="danger">*</Typography>
              </>
            }
            options={[
              { label: 'Posiadam certyfikat rezydencji podatkowej', value: true },
              { label: 'Nie posiadam certyfikatu rezydencji podatkowej', value: false },
            ]}
            formik={formik}
          />
          {formik.values.taxResidenceCertificate && (
            <Box sx={{ mb: 2 }}>
              <Stack flexWrap="wrap" useFlexGap direction={{ lg: 'row' }} spacing={{ xs: 2 }}>
                <CustomDatePickerControl
                  name="taxResidenceCertificateStartDate"
                  label="Wydany na okres od"
                  formik={formik}
                  sx={{ flex: '1', minWidth: '1px' }}
                />
                <CustomDatePickerControl
                  name="taxResidenceCertificateEndDate"
                  label="Wydany na okres do"
                  formik={formik}
                  sx={{ flex: '1', minWidth: '1px' }}
                />
              </Stack>
            </Box>
          )}
        </>
      )}
      <CustomAutocompleteControl
        name="officeNFZ"
        label="Należę do oddziału Narodowego Funduszu Zdrowia"
        options={officeNFZArray}
        formik={formik}
      />
      <CustomCheckboxControl
        smallText
        name="termsChanges"
        label="Jednocześnie oświadczam, że o fakcie jakiejkolwiek zmiany powyższych danych niezwłocznie poinformuję Zleceniodawcę."
        formik={formik}
      />
      <CustomCheckboxControl
        smallText
        name="termsRodo"
        label="Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do zawarcia i realizacji umowy cywilnoprawnej zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych). "
        formik={formik}
      />
    </Stack>
  )

  const stepsArr = ['Dane do umowy', 'Ankieta']

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stepper sx={{ width: '100%', mt: 2, mb: 4 }}>
        {stepsArr.map((step, index) => (
          <Step
            key={step}
            orientation="vertical"
            indicator={
              <StepIndicator
                variant={activeStep <= index ? 'soft' : 'solid'}
                color={activeStep < index ? 'neutral' : 'primary'}
              >
                {activeStep <= index ? index + 1 : <Check fontSize="small" />}
              </StepIndicator>
            }
            sx={[activeStep > index && index !== 2 && { '&::after': { bgcolor: 'primary.solidBg' } }]}
          >
            {step}
          </Step>
        ))}
      </Stepper>

      <Card>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">{stepsArr[activeStep]}</Typography>
          <Typography level="body-sm">
            {[getDescription(mode), getDescriptionQuestionnaire(mode)][activeStep]}
          </Typography>
        </Box>
        <Divider />
        {[StepOneMarkup, StepTwoMarkup][activeStep]}
        {activeStep === 1 && hasStepOneError(formik.errors) && (
          <Typography level="body-sm" color="danger">
            Uzupełnij brakujące informacje na kroku &quot;Dane do umowy&quot;, aby zapisać zmiany.
          </Typography>
        )}
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ width: '100%', display: 'flex', pt: 2 }}>
            {activeStep === 0 && (
              <Button
                type="button"
                onClick={handleGoNextStep}
                size="sm"
                variant="solid"
                color="primary"
                disabled={innerLoading}
                endDecorator={<ChevronRight fontSize="small" />}
                sx={{ ml: 'auto' }}
                style={{ flex: '0 0 auto' }}
              >
                Uzupełnij ankietę
              </Button>
            )}
            {activeStep > 0 && (
              <Button
                type="button"
                onClick={() => setActiveStep((i) => i - 1)}
                size="sm"
                variant="outlined"
                color="primary"
                disabled={innerLoading}
                startDecorator={<ChevronLeft fontSize="small" />}
              >
                Poprzedni krok
              </Button>
            )}
            {activeStep === stepsArr.length - 1 && (
              <Button type="submit" size="sm" variant="solid" loading={innerLoading} sx={{ ml: 'auto' }}>
                Zapisz zmiany
              </Button>
            )}
          </CardActions>
        </CardOverflow>
      </Card>
    </form>
  )
}

AdditionalDataSubView.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    street: PropTypes.string,
    buildingNumber: PropTypes.string,
    apartmentNumber: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    birthDate: PropTypes.string,
    pesel: PropTypes.string,
    passportNumber: PropTypes.string,
    nationality: PropTypes.string,
    bankAccountNumber: PropTypes.string,
    taxOffice: PropTypes.string,
    voivodship: PropTypes.string,
    commune: PropTypes.string,
    // Ankieta
    UserEmploymentDetails: PropTypes.shape({
      isStudentUnder26: PropTypes.bool,
      hasOtherJobAndLessThenMinimumWage: PropTypes.bool,
      hasOtherJobAndMoreThenMinimumWage: PropTypes.bool,
      hasBusinessAndContributions60: PropTypes.bool,
      hasBusinessAndContributions30: PropTypes.bool,
      hasBussinesAndSameServices: PropTypes.bool,
      hasOtherJobButIsOnUnpaidOrParentalLeave: PropTypes.bool,
      hasOtherJobButIsOnMaternityLeave: PropTypes.bool,
      hasNoOtherSocialInsurance: PropTypes.bool,
      isRetired: PropTypes.bool,
      hasDisability: PropTypes.bool,
      hasDisabilityOther: PropTypes.string,
      healthContribution: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      otherCircumstances: PropTypes.bool,
      otherCircumstancesOther: PropTypes.string,
      exemptionPIT: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      taxResidenceCertificate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      taxResidenceCertificateStartDate: PropTypes.string,
      taxResidenceCertificateEndDate: PropTypes.string,
      officeNFZ: PropTypes.string,
      termsChanges: PropTypes.bool,
      termsRodo: PropTypes.bool,
    }),
  }).isRequired,
  mode: PropTypes.oneOf(['profile', 'editUser']).isRequired,
}
export default AdditionalDataSubView
