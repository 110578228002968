import { STATUS } from './config'
import {
  isDateAfter,
  isDateAfterOrToday,
  isDateBefore,
  isDateBeforeOrToday,
  isDateToday,
  standarizeStatus,
} from './helpers'

export const getUserStatus = (t, user, serverTime) => {
  const taskProjectType = t.computedType || 1

  const status = standarizeStatus(t.status)
  const dateAfter = isDateAfter(t.date, serverTime)
  const dateBefore = isDateBefore(t.date, serverTime)
  const dateBeforeOrToday = isDateBeforeOrToday(t.date, serverTime)
  const dateAfterOrToday = isDateAfterOrToday(t.date, serverTime)

  if (taskProjectType === 1) {
    // Typ 1
    if (!t.assignment && dateAfter) return { label: 'Otwarte', color: 'primary' }
    if (!t.assignment && dateBeforeOrToday) return { label: 'Przeterminowane', color: 'neutral' }
    if (t.assignment && t.assignment.userId === user.id && status === STATUS.done.value)
      return { label: 'Zaakceptowane', color: 'success' }
    if (t.assignment && t.assignment.userId === user.id && dateAfterOrToday)
      return { label: 'Twoje zadanie', color: 'success' }
    if (t.assignment && t.assignment.userId === user.id && dateBefore) return { label: 'Weryfikacja', color: 'warning' }
    if (t.reserveList && t.reserveList.some((id) => id === user.id) && dateAfter)
      return { label: 'Lista rezerwowa', color: 'primary' }
    if (t.assignment && t.assignment.userId !== user.id && dateBeforeOrToday)
      return { label: 'Zamknięte', color: 'neutral' }
    if (t.assignment && t.assignment.userId !== user.id) return { label: 'Zajęte', color: 'danger' }
  } else if (taskProjectType === 2) {
    // Typ 2
    if (!t.assignment && dateAfter) return { label: 'Otwarte', color: 'primary' }
    if (!t.assignment && dateBeforeOrToday) return { label: 'Przeterminowane', color: 'neutral' }
    if (t.assignment && t.assignment.userId === user.id && status === STATUS.done.value)
      return { label: 'Zaakceptowane', color: 'success' }
    if (t.assignment && t.userFiles && t.userFiles.length > 0) return { label: 'Weryfikacja', color: 'primary' }
    if (t.assignment && t.assignment.userId === user.id && dateAfterOrToday)
      return { label: 'Twoje zadanie', color: 'success' }
    if (t.assignment && t.assignment.userId === user.id && dateBefore) return { label: 'Weryfikacja', color: 'warning' }
    if (t.reserveList && t.reserveList.some((id) => id === user.id) && dateAfter)
      return { label: 'Lista rezerwowa', color: 'primary' }
    if (t.assignment && t.assignment.userId !== user.id && dateBeforeOrToday)
      return { label: 'Zamknięte', color: 'neutral' }
    if (t.assignment && t.assignment.userId !== user.id) return { label: 'Zajęte', color: 'danger' }
  }

  return { label: '?', color: 'danger' }
}

export const getAdminStatus = (t, serverTime) => {
  const taskProjectType = t.computedType || 1
  const status = standarizeStatus(t.status)
  const dateAfter = isDateAfter(t.date, serverTime)
  const dateToday = isDateToday(t.date, serverTime)
  const dateBefore = isDateBefore(t.date, serverTime)
  const dateBeforeOrToday = isDateBeforeOrToday(t.date, serverTime)

  if (taskProjectType === 1) {
    // Typ 1
    if (t.assignment && status === STATUS.done.value) return { label: 'Zaakceptowane', color: 'success' }
    if (t.assignment && dateAfter) return { label: 'Podjęte', color: 'primary' }
    if (t.assignment && dateToday) return { label: 'W trakcie', color: 'warning' }
    if (t.assignment && dateBefore && status !== STATUS.done.value) return { label: 'Weryfikacja', color: 'warning' }
    if (!t.assignment && dateAfter) return { label: 'Otwarte', color: 'primary' }
    if (!t.assignment && dateBeforeOrToday) return { label: 'Przeterminowane', color: 'neutral' }
  } else if (taskProjectType === 2) {
    // Typ 2
    if (t.assignment && status === STATUS.done.value) return { label: 'Zaakceptowane', color: 'success' }
    if (t.assignment && t.userFiles && t.userFiles.length > 0) return { label: 'Do weryfikacji', color: 'warning' }
    if (t.assignment && dateAfter) return { label: 'Podjęte', color: 'primary' }
    if (t.assignment && dateToday) return { label: 'W trakcie', color: 'warning' }
    if (t.assignment && dateBefore && status !== STATUS.done.value) return { label: 'Weryfikacja', color: 'warning' }
    if (!t.assignment && dateAfter) return { label: 'Otwarte', color: 'primary' }
    if (!t.assignment && dateBeforeOrToday) return { label: 'Przeterminowane', color: 'neutral' }
  }

  return { label: '?', color: 'danger' }
}
