import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/joy'
import { projectService } from 'services/projectsService'
import { handleErrorMessage } from 'utils/helpers'
import routes from 'routes'
import MainLayout from 'components/templates/MainLayout'
import Breadcrumbs from 'components/Breadcrumbs'
import ProjectFormSubView from './common/ProjectFormSubView'

const AdminProjectsCreateView = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)

  const handleCreateProjectSubmit = (values) => {
    setInnerLoading(true)
    projectService
      .createProject(values)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Projekt został utworzony', { variant: 'success' })
        navigate(routes.adminProjects)
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
        setInnerLoading(false)
      })
  }

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Projekty', to: routes.adminProjects }, { label: 'Dodaj nowy' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Nowy projekt
        </Typography>
      </Box>

      <ProjectFormSubView
        handleSubmit={handleCreateProjectSubmit}
        initialValues={{
          type: 1,
          name: '',
          description: '',
          startDate: '',
          endDate: '',
          location: '',
          hourlyRate: '',
          coordinationPhone: '',
          infoBanner: '',
        }}
        loading={innerLoading}
      />
    </MainLayout>
  )
}

export default AdminProjectsCreateView
