import PropTypes from 'prop-types'
import { InfoOutlined } from '@mui/icons-material'
import { FormControl, FormHelperText, FormLabel, Input, Typography } from '@mui/joy'

const CustomInputControl = ({ name, label, formik, info = '', sx, ...rest }) => {
  let fieldName = name
  let error = formik.errors[name]
  let touched = formik.touched[name]
  let value = formik.values[name]

  if (Array.isArray(name)) {
    const [mainProp, index, fieldProp, fieldIndex = false] = name
    fieldName = name.join('.')

    if (fieldIndex || fieldIndex === 0) {
      if (
        formik.errors[mainProp] &&
        formik.errors[mainProp][index] &&
        formik.errors[mainProp][index][fieldProp] &&
        Array.isArray(formik.errors[mainProp][index][fieldProp])
      )
        error = formik.errors[mainProp][index][fieldProp][fieldIndex]
      else
        error = formik.errors[mainProp] && formik.errors[mainProp][index] && formik.errors[mainProp][index][fieldProp]

      touched =
        formik.touched[mainProp] &&
        formik.touched[mainProp][index] &&
        formik.touched[mainProp][index][fieldProp] &&
        formik.touched[mainProp][index][fieldProp][fieldIndex]
    } else {
      error = formik.errors[mainProp] && formik.errors[mainProp][index] && formik.errors[mainProp][index][fieldProp]
      touched =
        formik.touched[mainProp] && formik.touched[mainProp][index] && formik.touched[mainProp][index][fieldProp]
    }

    if (fieldIndex || fieldIndex === 0) value = formik.values[mainProp][index][fieldProp][fieldIndex]
    else value = formik.values[mainProp][index][fieldProp]
  }

  return (
    <FormControl error={touched && Boolean(error)} sx={sx} disabled={rest.disabled}>
      <FormLabel>{label}</FormLabel>
      <Input
        id={fieldName}
        name={fieldName}
        label={label}
        value={value}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={touched && Boolean(error)}
        {...rest}
      />
      {touched && error && (
        <FormHelperText sx={{ gap: 1 }}>
          <InfoOutlined fontSize="small" />
          {error}
        </FormHelperText>
      )}
      {info && (
        <FormHelperText sx={{ gap: 1 }}>
          <Typography level="body-sm" startDecorator={<InfoOutlined fontSize="small" />} color="primary">
            {info}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  )
}
export default CustomInputControl

CustomInputControl.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  formik: PropTypes.object.isRequired,
  info: PropTypes.string,
}
