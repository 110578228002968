import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/joy'
import { isDateAfterOrToday } from 'utils/helpers'
import { dateFilters, dateFiltersOptions } from 'utils/config'
import { cleanupRequestStatus, getMyTasks } from 'features/tasksSlice'
import routes from 'routes'
import useSearchParamValue from 'hooks/useSearchParamValue'
import MainLayout from 'components/templates/MainLayout'
import Breadcrumbs from 'components/Breadcrumbs'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import usePagination from 'hooks/usePagination'
import PageLoader from 'components/PageLoader'
import FilterSelect from 'components/tables/FilterSelect'
import EnchantedTable from 'components/tables/EnchantedTable'
import { getUserMyTasksHeaders } from 'utils/headers/getUserMyTasksHeaders'

const UserMyTasksView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [dateFilter, setDateFilter] = useSearchParamValue('date', dateFilters.current.value)

  const { tasks, loading } = useSelector((s) => s.tasks)
  const { userInfo, serverTime } = useSelector((s) => s.auth)

  const pagination = usePagination()

  useEffect(() => {
    if (!userInfo) return
    dispatch(getMyTasks(userInfo.id))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch, userInfo])

  const filteredTasks = useMemo(() => {
    if (!tasks) return []
    if (dateFilter === dateFilters.current.value) return tasks.filter((t) => isDateAfterOrToday(t.date, serverTime))
    else return tasks
  }, [tasks, serverTime, dateFilter])

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Moje zadania' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Moje zadania
        </Typography>
      </Box>

      <FiltersWrapper
        sx={{ pt: 0 }}
        renderFilters={
          <FilterSelect
            label="Filtrowanie po dacie"
            placeholder={dateFilters.all.label}
            options={dateFiltersOptions}
            value={dateFilter}
            setValue={setDateFilter}
            sx={{ ml: { sm: 'auto' } }}
          />
        }
      />

      <EnchantedTable
        headers={getUserMyTasksHeaders()}
        data={filteredTasks}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        noDataText="Brak zadań do wyświetlenia."
        buttonActions={[
          {
            label: 'Zobacz projekt',
            color: 'primary',
            type: 'button',
            func: (task) => navigate(routes.userProjectsDetails.replace(':projectId', task.project.id)),
          },
        ]}
      />
    </MainLayout>
  )
}

export default UserMyTasksView
