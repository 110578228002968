import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cleanupRequestStatus, getUserBillings } from 'features/billingsSlice'
import { Box, Typography } from '@mui/joy'
import Breadcrumbs from 'components/Breadcrumbs'
import usePagination from 'hooks/usePagination'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import PageLoader from 'components/PageLoader'
import EnchantedTable from 'components/tables/EnchantedTable'

const headers = [
  {
    label: 'Rozliczenie',
    propName: 'monthYear',
    className: 'width-xl',
  },
  {
    label: 'Ilość zadań w miesiącu',
    propName: 'taskCount',
    className: 'width-xl',
  },
  {
    label: 'Wynagrodzenie - niezaakceptowane',
    propName: 'pendingAmount',
    type: 'money',
    rowClassName: 'text-right',
    className: 'text-right',
  },
  {
    label: 'Wynagrodzenie - zaakceptowane',
    propName: 'acceptedAmount',
    type: 'money',
    rowClassName: 'text-right',
    className: 'text-right',
  },
  {
    label: 'Wynagrodzenie - suma',
    propName: 'totalAmount',
    type: 'money',
    rowClassName: 'text-right',
    className: 'text-right',
  },
]

const UserBillingsView = () => {
  const pagination = usePagination()
  const dispatch = useDispatch()

  const { billings, loading } = useSelector((s) => s.billings)

  useEffect(() => {
    dispatch(getUserBillings())
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Rozliczenia' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Rozliczenia
        </Typography>
      </Box>

      <FiltersWrapper />

      <EnchantedTable
        headers={headers}
        data={billings}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        noDataText="Brak rozliczeń do wyświetlenia."
      />
    </MainLayout>
  )
}

export default UserBillingsView
