import PropTypes from 'prop-types'

export const actionsTypesSchema = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    color: PropTypes.string,
    divider: PropTypes.bool,
    func: PropTypes.func.isRequired,
    checkIfDisable: PropTypes.func,
    display: PropTypes.func,
    loading: PropTypes.bool,
  })
)

export const buttonActionsSchema = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    color: PropTypes.string,
    divider: PropTypes.bool,
    func: PropTypes.func.isRequired,
    checkIfDisable: PropTypes.func,
    loading: PropTypes.bool,
  })
)

export const tablePropTypesSchema = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      propName: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        'computed',
        'status',
        'progress',
        'check',
        'blocked',
        'money',
        'moneyRate',
        'date',
        'assigmentModeUser',
        'assigmentModeAdmin',
        'route',
        'notes',
      ]),
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: actionsTypesSchema,
  buttonActions: buttonActionsSchema,
  noDataText: PropTypes.string,

  onSelectAllClick: PropTypes.func,
  bulkActions: actionsTypesSchema,
}

export const EnhancedTableToolbarTypesSchema = {
  bulkActions: actionsTypesSchema.isRequired,
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
}
