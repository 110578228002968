import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/joy'
import AdvancedTooltip from './AdvancedTooltip'

const UserEngagementTooltip = ({ user, children = null }) => {
  if (user.engagement.length === 0) return '-'

  const totalTasks = user.engagement.reduce((acc, curr) => acc + curr.tasks.length, 0)

  return (
    <AdvancedTooltip
      title=""
      hideIcon
      description={user.engagement.map((eng) => (
        <Box key={eng.projectName} sx={{ display: 'flex' }}>
          <Typography level="body-xs">{eng.projectName}:&nbsp;</Typography>
          <Typography level="body-xs" color="success">
            {eng.tasks.length}
          </Typography>
        </Box>
      ))}
      sx={{ justifyContent: 'flex-start' }}
      iconColor="primary"
    >
      {children}
      {totalTasks}
    </AdvancedTooltip>
  )
}

UserEngagementTooltip.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.string,
}

export default UserEngagementTooltip
