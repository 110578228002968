import { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { Box, Button, Divider, Stack, Typography, Card, CardActions, CardOverflow } from '@mui/joy'
import { usersService } from 'services/usersService'
import { profileService } from 'services/profileService'
import { handleErrorMessage } from 'utils/helpers'
import { setUserInfo } from 'features/authSlice'
import { setCurrentUser } from 'features/usersSlice'
import CustomInputControl from 'components/forms/CustomInputControl'
import personalDataSchema from 'validations/profile/personalDataSchema'
import CustomEditorControl from 'components/forms/CustomEditorControl'
import { ROLES } from 'utils/config'

const getDescription = (mode) => {
  if (mode === 'profile') return 'Edytuj swoje dane'
  if (mode === 'editUser') return 'Edytuj dane użytkownika'
}

const PersonalDataSubView = ({ userInfo, mode }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)

  const { id, firstName = '', lastName = '', email = '', phoneNumber = '' } = userInfo

  const { userInfo: loggedUser } = useSelector((s) => s.auth)

  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      email,
      phoneNumber,
      ...(loggedUser.role === ROLES.admin.value ? { adminNote: userInfo.adminNote || '' } : {}),
    },
    validationSchema: personalDataSchema,
    onSubmit: (values) => {
      setInnerLoading(true)

      let picketMethod = profileService.updateProfileGeneralData
      if (mode === 'editUser') picketMethod = usersService.updateUserGeneralData

      picketMethod(id, values)
        .then((res) => {
          console.log(res)
          enqueueSnackbar('Dane zostały zaktualizowane', { variant: 'success' })
          // Reload profile or current user object based on mode
          if (mode === 'profile') dispatch(setUserInfo(res.data))
          else if (mode === 'editUser') dispatch(setCurrentUser(res.data))
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
        })
        .finally(() => setInnerLoading(false))
    },
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">Dane podstawowe</Typography>
          <Typography level="body-sm">{getDescription(mode)}</Typography>
        </Box>
        <Divider />
        <Stack direction="column" spacing={2} sx={{ mt: 1, mb: 2 }}>
          <CustomInputControl name="firstName" label="Imię" formik={formik} />
          <CustomInputControl name="lastName" label="Naziwsko" formik={formik} />
          <CustomInputControl name="email" label="E-mail" formik={formik} />
          <CustomInputControl name="phoneNumber" label="Numer telefonu" formik={formik} />
          {loggedUser.role === ROLES.admin.value && (
            <CustomEditorControl name="adminNote" label="Notatka" formik={formik} />
          )}
        </Stack>
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button
              onClick={() => formik.resetForm()}
              size="sm"
              variant="outlined"
              color="neutral"
              disabled={innerLoading}
            >
              Anuluj
            </Button>
            <Button type="submit" disabled={!formik.dirty} size="sm" variant="solid" loading={innerLoading}>
              Zapisz zmiany
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </form>
  )
}

PersonalDataSubView.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    adminNote: PropTypes.string,
  }).isRequired,
  mode: PropTypes.oneOf(['profile', 'editUser']).isRequired,
}
export default PersonalDataSubView
