import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { FieldArray, useFormik, FormikProvider } from 'formik'
import { Info, Payments, RemoveCircleOutline } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardOverflow,
  Chip,
  Divider,
  IconButton,
  Sheet,
  Stack,
  Typography,
} from '@mui/joy'
import { addTasksType2Schema } from 'validations/project/addTasksSchema'
import { newTaskType2Object } from 'utils/config'
import { projectService } from 'services/projectsService'
import { handleErrorMessage, resolveAndDownloadBlob } from 'utils/helpers'
import CustomDatePickerControl from 'components/forms/CustomDatePickerControl'
import CustomInputControl from 'components/forms/CustomInputControl'
import PropTypes from 'prop-types'
import CustomUploadFileControl from 'components/forms/CustomDragAndDropFileControl'
import FileUpload from 'components/FileUpload'
import DeleteModal from 'components/DeleteModal'

const TasksFormType2SubView = ({
  handleSubmit,
  initialTasks = [{ ...newTaskType2Object }],
  loading = false,
  singleMode = false,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [innerLoading, setInnerLoading] = useState(false)
  const [deleteFileObject, setDeleteFileObject] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState(initialTasks[0].files)

  const formik = useFormik({
    initialValues: {
      tasks: [
        ...initialTasks.map((t) => ({
          ...t,
          files: [],
        })),
      ],
    },
    validationSchema: addTasksType2Schema,
    onSubmit: handleSubmit,
  })

  const handleDownloadFile = (file) => {
    projectService
      .downloadProjectTaskFile(file.projectId, file.taskId, file.id)
      .then((res) => {
        resolveAndDownloadBlob(res, file.fileName)
        enqueueSnackbar('Pobieranie pliku.', { variant: 'success' })
      })
      .catch((err) => enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' }))
  }

  const handleDeleteFile = () => {
    setInnerLoading(true)
    projectService
      .adminDeleteTaskFile(deleteFileObject.projectId, deleteFileObject.taskId, deleteFileObject.id)
      .then(() => {
        setUploadedFiles((files) => files.filter((f) => f.id !== deleteFileObject.id))
        enqueueSnackbar('Plik został usunięty.', { variant: 'success' })
        setDeleteFileObject(false)
      })
      .catch((err) => enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' }))
      .finally(() => setInnerLoading(false))
  }

  return (
    <FormikProvider value={formik}>
      <DeleteModal
        open={deleteFileObject}
        handleDelete={handleDeleteFile}
        loading={innerLoading}
        setObject={setDeleteFileObject}
      >
        Czy jesteś pewny, ze chcesz usunąć plik:{' '}
        <Typography color="primary" fontWeight={500}>
          {deleteFileObject && deleteFileObject.fileName}
        </Typography>
      </DeleteModal>
      <form onSubmit={formik.handleSubmit}>
        <Card
          sx={{
            display: 'flex',
            maxWidth: '100%',
            width: '100%',
            mx: 'auto',
            px: { xs: 2, md: 3 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md" startDecorator={<Info />} color="primary">
              Uzupełnij dane {singleMode ? 'zadania' : 'zadań'}
            </Typography>
          </Box>
          <Divider />
          <FieldArray
            name="tasks"
            render={(arrayHelpers) => (
              <>
                {formik.values.tasks && formik.values.tasks.length > 0 && (
                  <Stack direction="column" spacing={2} sx={{ mt: 1, mb: 1 }}>
                    {[...formik.values.tasks].map((task, index) => (
                      <div key={index}>
                        <Sheet variant="plain" color="primary">
                          <Stack direction="row" spacing={2} sx={{ mb: 1, justifyContent: 'space-between' }}>
                            {!singleMode && (
                              <Typography level="title-sm" color="primary">
                                Zadanie {index + 1}
                              </Typography>
                            )}
                            {index > 0 && (
                              <Chip
                                size="sm"
                                variant="outlined"
                                color="danger"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Usuń
                              </Chip>
                            )}
                          </Stack>
                          <Stack
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: {
                                xs: 'repeat( auto-fit, minmax(320px, 1fr) )',
                                xl: 'repeat( 4, 1fr )',
                              },
                              gap: 2,
                              marginBottom: 2,
                            }}
                          >
                            <CustomInputControl
                              name={['tasks', index, 'lp']}
                              label="Numer porządkowy"
                              formik={formik}
                            />
                            <CustomInputControl
                              name={['tasks', index, 'pointNumber']}
                              label="Numer punktu"
                              formik={formik}
                            />
                            <CustomInputControl
                              name={['tasks', index, 'pointType']}
                              label="Typ punktu"
                              formik={formik}
                            />
                            <CustomInputControl
                              type="number"
                              name={['tasks', index, 'numberOfCameras']}
                              label="Liczba kamer"
                              formik={formik}
                            />
                            <CustomInputControl
                              type="number"
                              name={['tasks', index, 'numberOfDirections']}
                              label="Liczba kierunków"
                              formik={formik}
                            />
                            <CustomDatePickerControl
                              name={['tasks', index, 'date']}
                              label="Termin"
                              formik={formik}
                              sx={{ flex: '1', minWidth: '1px' }}
                            />
                            <CustomInputControl name={['tasks', index, 'duration']} label="Długość" formik={formik} />
                            <CustomInputControl name={['tasks', index, 'sdr']} label="SDR" formik={formik} />
                            <CustomInputControl
                              type="number"
                              name={['tasks', index, 'rate']}
                              label="Stawka"
                              formik={formik}
                              startDecorator={<Payments fontSize="small" />}
                              endDecorator="PLN"
                              sx={{ flex: '1', minWidth: '1px' }}
                            />
                            <CustomInputControl
                              name={['tasks', index, 'notes']}
                              label={
                                <Typography level="body-sm" textColor="text.primary">
                                  Uwagi&nbsp;
                                  <Typography level="body-xs" textColor="text.tertiary">
                                    - opcjonalnie
                                  </Typography>
                                </Typography>
                              }
                              formik={formik}
                              sx={{ flex: '1', minWidth: '1px' }}
                            />
                          </Stack>
                          <Stack spacing={2} marginBottom={2}>
                            <FieldArray
                              name={['tasks', index, 'links']}
                              render={(linksArrayHelpers) => (
                                <>
                                  <Typography
                                    level="title-xs"
                                    color="neutral"
                                    endDecorator={
                                      <Chip
                                        size="sm"
                                        color="primary"
                                        variant="soft"
                                        onClick={() => linksArrayHelpers.push('')}
                                      >
                                        Dodaj kolejny
                                      </Chip>
                                    }
                                  >
                                    Lista linków:
                                  </Typography>
                                  <Stack
                                    sx={{
                                      display: 'grid',
                                      gridTemplateColumns: {
                                        xs: 'repeat( auto-fit, minmax(320px, 1fr) )',
                                        xl: 'repeat( 4, 1fr )',
                                      },
                                      gap: 2,
                                      marginBottom: 2,
                                    }}
                                  >
                                    {formik.values.tasks[index].links &&
                                      formik.values.tasks[index].links.length > 0 &&
                                      formik.values.tasks[index].links.map((link, linkIndex) => (
                                        <CustomInputControl
                                          key={linkIndex}
                                          name={['tasks', index, 'links', linkIndex]}
                                          label={`Link #${linkIndex + 1}`}
                                          formik={formik}
                                          sx={{ flex: '1', minWidth: '1px' }}
                                          endDecorator={
                                            linkIndex !== 0 ? (
                                              <IconButton
                                                color="danger"
                                                onClick={() => linksArrayHelpers.remove(linkIndex)}
                                              >
                                                <RemoveCircleOutline />
                                              </IconButton>
                                            ) : null
                                          }
                                        />
                                      ))}
                                  </Stack>
                                </>
                              )}
                            />
                          </Stack>
                          <Stack spacing={2}>
                            <Typography level="title-xs" color="neutral">
                              Załączniki:
                            </Typography>
                            <Stack
                              sx={{
                                display: 'grid',
                                gridTemplateColumns: {
                                  xs: 'repeat( auto-fit, minmax(320px, 1fr) )',
                                  xl: 'repeat( 4, 1fr )',
                                },
                                gap: 2,
                                marginBottom: 2,
                              }}
                            >
                              {uploadedFiles &&
                                uploadedFiles.map((file, fileIndex) => (
                                  <FileUpload
                                    isLocal={!file.id}
                                    key={fileIndex}
                                    fileName={file.fileName}
                                    fileSize={file.fileSize}
                                    handleDelete={() => setDeleteFileObject(file)}
                                    handleDownload={() => handleDownloadFile(file)}
                                  />
                                ))}
                            </Stack>
                            <CustomUploadFileControl
                              name={['tasks', index, 'files']}
                              multiple={true}
                              accept={null}
                              formik={formik}
                              label={null}
                            />
                            <Stack
                              sx={{
                                display: 'grid',
                                gridTemplateColumns: {
                                  xs: 'repeat( auto-fit, minmax(320px, 1fr) )',
                                  xl: 'repeat( 4, 1fr )',
                                },
                                gap: 2,
                                marginBottom: 2,
                              }}
                            >
                              {formik.values.tasks[index].files &&
                                formik.values.tasks[index].files.map((file, fileIndex) => (
                                  <FileUpload
                                    isLocal={!file.id}
                                    key={fileIndex}
                                    fileName={file.name}
                                    fileSize={file.size}
                                    handleDelete={() => {
                                      formik.setFieldValue(
                                        ['tasks', index, 'files'],
                                        formik.values.tasks[index].files.filter((f) => f.path !== file.path)
                                      )
                                    }}
                                  />
                                ))}
                            </Stack>
                          </Stack>
                        </Sheet>
                      </div>
                    ))}
                  </Stack>
                )}
                {!singleMode && (
                  <CardActions sx={{ alignSelf: 'flex-start', pt: 0, my: 1 }}>
                    <Button
                      type="button"
                      onClick={() => arrayHelpers.push({ ...newTaskType2Object })}
                      size="sm"
                      variant="solid"
                    >
                      Dodaj kolejne zadanie
                    </Button>
                  </CardActions>
                )}
              </>
            )}
          />
          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 3 }}>
              <Button type="submit" disabled={!formik.dirty} size="sm" variant="solid" loading={loading}>
                {singleMode ? 'Zaktualizuj zadanie' : 'Dodaj zadania do projektu'}
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>
      </form>
    </FormikProvider>
  )
}

export const task2Schape = PropTypes.shape({
  lp: PropTypes.string.isRequired,
  pointNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pointType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  numberOfCameras: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  numberOfDirections: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  date: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  sdr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  notes: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
  files: PropTypes.array,
  publishedAt: PropTypes.string,
})

TasksFormType2SubView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialTasks: PropTypes.arrayOf(task2Schape),
  loading: PropTypes.bool,
  singleMode: PropTypes.bool,
}

export default TasksFormType2SubView
