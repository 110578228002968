import { WarningRounded } from '@mui/icons-material'
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog } from '@mui/joy'
import PropTypes from 'prop-types'

const DeleteModal = ({
  open,
  setObject,
  handleDelete,
  loading = false,
  children,
  btnText = 'Usuń',
  title = 'Potwierdzenie',
}) => {
  return (
    <Modal open={Boolean(open)} onClose={() => setObject(false)}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRounded />
          {title}
        </DialogTitle>
        <Divider />
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleDelete} loading={loading}>
            {btnText}
          </Button>
          <Button variant="plain" color="neutral" onClick={() => setObject(false)} loading={loading}>
            Anuluj
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}

DeleteModal.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.array]).isRequired,
  setObject: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  btnText: PropTypes.string,
  title: PropTypes.string,
}

export default DeleteModal
