import { store } from 'app/store'
import axios from 'axios'
import { logout } from 'features/authSlice'
import globalRouter from 'globalRouter'
import routes from 'routes'
import { API_BASE_URL } from 'utils/config'
import { handleErrorMessage } from 'utils/helpers'

const BASE_URL = API_BASE_URL

const axiosInstanceUseToken = (config) => {
  const token = store.getState().auth.userToken
  config.headers.Authorization = token ? `Bearer ${token}` : null
  return config
}

const axionErrorInterceptorLogic = (error) => {
  console.log(error)
  if (error.response?.status == 401 && globalRouter.navigate) {
    store.dispatch(logout())
    globalRouter.navigate(routes.login)
  }
  if (error.response?.status == 403 && globalRouter.navigate)
    globalRouter.navigate(routes.home, { state: { error: handleErrorMessage(error, 'Brak dostępu') } })
  return Promise.reject(error)
}

// Standard
const customAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
  timeout: 60000,
})

customAxios.interceptors.request.use(axiosInstanceUseToken)
customAxios.interceptors.response.use((response) => response, axionErrorInterceptorLogic)

// With Files
const customAxiosFile = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  transformRequest: [
    (data) => {
      const formData = new FormData()
      Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) data[key].forEach((file) => formData.append(`${key}`, file))
        else formData.append(key, data[key])
      })
      return formData
    },
  ],
  withCredentials: false,
  timeout: 60000,
})

customAxiosFile.interceptors.request.use(axiosInstanceUseToken)
customAxiosFile.interceptors.response.use((response) => response, axionErrorInterceptorLogic)

export default customAxios
export { customAxiosFile }
