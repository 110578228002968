// ProtectedRoute.js
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import routes from 'routes'
import PageLoader from 'components/PageLoader'
import { ROLES } from 'utils/config'

const ProtectedRoute = ({ allowedRoles = [], children }) => {
  const location = useLocation()
  const { userInfo, userToken } = useSelector((s) => s.auth)

  const isNotActiveUser = () =>
    userInfo && userInfo.role === ROLES.user.value && !userInfo.isAccountConfirmed && location.pathname != routes.user

  const isAllowedRole = allowedRoles.length ? allowedRoles.includes(userInfo?.role) : true

  // TODO - Tymczasowe zabezpieczenie ? albo na stałe ?
  if (isNotActiveUser(userInfo)) return <Navigate to={routes.user} state={location.state} />

  // If no token then u must login
  if (!userToken) return <Navigate to={routes.login} />

  // If no user, but token exist then w8 for getMe in <App />, then it will rerender
  if (!userInfo) return <PageLoader />

  // If token and user provided, but not allowed to enter then redirect away
  if (!isAllowedRole) return <Navigate to={routes.home} state={location.state} />

  return (
    <>
      <Outlet />
      {children}
    </>
  )
}

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  allowedRoles: PropTypes.array,
}
export default ProtectedRoute
