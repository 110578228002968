import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useLocation, useParams } from 'react-router-dom'
import { Upload } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/joy'
import { cleanCurrentTask, getProjectDetails, getTaskDetailsForUsers } from 'features/projectsSlice'
import routes from 'routes'
import Breadcrumbs from 'components/Breadcrumbs'
import MainLayout from 'components/templates/MainLayout'
import PageLoader from 'components/PageLoader'
import TaskInfoType1SubView from 'views/common/TaskInfoType1SubView'
import TaskInfoType2SubView from 'views/common/TaskInfoType2SubView'

const getTaskName = (project, task) => {
  let name = ''
  if (!project || !task) return '?'
  if (project.type === 1) name = `${task.lp || task.id} - ${task.localization}`
  else if (project.type === 2) name = `${task.lp || task.id}`
  return name
}

const UserTaskDetailsView = () => {
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { projectId, taskId } = useParams()

  const { currentTask, currentProject, loading } = useSelector((s) => s.projects)

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    dispatch(getTaskDetailsForUsers({ projectId, taskId }))
    return () => dispatch(cleanCurrentTask())
  }, [])

  const getTaskInfo = () => {
    switch (currentProject.type) {
      case 1:
        return <TaskInfoType1SubView project={currentProject} task={currentTask} />
      case 2:
        return <TaskInfoType2SubView project={currentProject} task={currentTask} />
      default:
        break
    }
  }

  if (!projectId || !taskId) return <Navigate to={routes.userProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  if (!currentProject || !currentTask) return <MainLayout></MainLayout>

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.userProjects },
            {
              label: currentProject?.name || state?.from || projectId,
              to: routes.userProjectsDetails.replace(':projectId', projectId),
            },
            { label: getTaskName(currentProject, currentTask) },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Zadanie:&nbsp;
          <Typography color="primary">{currentTask?.localization}</Typography>
        </Typography>
        {}
        <Button
          size="sm"
          component={Link}
          to={routes.userProjectsDetailsTaskUpload.replace(':projectId', projectId).replace(':taskId', currentTask.id)}
          color="primary"
          state={{ from: currentProject?.name }}
          variant="outlined"
          startDecorator={<Upload fontSize="small" />}
        >
          Wyślij rozwiązanie
        </Button>
      </Box>

      {getTaskInfo()}
    </MainLayout>
  )
}

export default UserTaskDetailsView
