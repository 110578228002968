import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { closeSidebar } from 'utils/muiHelpers'
import { MAIN_MENU, ROLES } from 'utils/config'
import { Box, Divider, IconButton, List, ListItem, ListItemContent, Typography, Sheet } from '@mui/joy'
import { KeyboardArrowDown, LogoutRounded, PermContactCalendar } from '@mui/icons-material'
import { logout } from 'features/authSlice'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import routes from 'routes'
import ColorSchemeToggle from 'components/ColorSchemeToggle'
import PageLoader from './PageLoader'
import { isDateAfterOrToday } from 'utils/helpers'

// eslint-disable-next-line react/prop-types
const Toggler = ({ defaultExpanded = false, renderToggle, children }) => {
  const [open, setOpen] = useState(defaultExpanded)

  return (
    <>
      {renderToggle({ open, setOpen })}
      <Box
        sx={[
          {
            display: 'grid',
            transition: '0.2s ease',
            '& > *': {
              overflow: 'hidden',
            },
          },
          open ? { gridTemplateRows: '1fr' } : { gridTemplateRows: '0fr' },
        ]}
      >
        {children}
      </Box>
    </>
  )
}

const Sidebar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userInfo, serverTime } = useSelector((s) => s.auth)
  const { tasks: myTasks, success: tasksFetchSuccess } = useSelector((s) => s.tasks)

  if (!userInfo) return <PageLoader />

  const menu = MAIN_MENU[userInfo?.role]
  const isMenuDisabled = userInfo && userInfo.role === ROLES.user.value && !userInfo.isAccountConfirmed
  const injectUserTasks = userInfo && userInfo.role === ROLES.user.value && tasksFetchSuccess

  const handleLogout = () => {
    dispatch(logout())
    navigate(routes.login)
  }

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <IconButton variant="soft" color="primary" size="sm">
          <img
            width={24}
            height={24}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADmUlEQVRYhe2XXYhVVRTHf+caISKTRApCbkZEHcJURHoqHXzwwUQ3EtFD4J63sChCRGQcJIZBImR0FBUV2kVExVAb8SWCoI+X6GsosdswiWx9GC7DoCIiMsztYa3jHI/n3Hvm+lgLDpuz9zp7/dd/7/Vx4L8uSVVFY/0iwAIGqHVgaxaoA5dicDPzAmCsfxb4BujpwHBefgRejsHdBnii4kfn1XgDGOvQcA14AXgROAq8CRUYMNZ3A/8AU8CGGNxkhwAw1j8H/A7cB5bG4O49AsBYXwOWAQt1agvwEfAlsL+CnbsxuEYLED8gLKyNwY0nucX1amxjBUNlMouc894Y3LXc/jWEzW5geQxuspZZfAa5aI9jHOSstwBfG+sX5tZeUeNj6VFmw2kfQv33wOomLE0f4IjqfJudL3qA54HLwBrg9TkHP6wBA/p6NJ3PRsFWHQ/E4CaysI31x4F3gN4EumNwv7RgYMpYPwB8BbwEXJDpZA+wDrgCjGbpyoO5k99RY3ZY9Qfy6wVyV8cn1YHsd4MxuNkiAL/p2Fey6QgwDew01m9qA2Cvjj/raIH1QL1JczSrmAVwBpgB3jDWL8vvWJUFY30P8CrC5Mdm90NnP3g99M1k9R8AiMGNA58BiymP91PATWCXsb4sWvqR4zwdg5siSXYhkVUHvsgr54vKEMLCPg3LhyQGd5MWLBjr1wCvId4fWyHepxE0lC1ChQBicCnKViyMICxYTVxZGUC8PxuDayRJshPxPmX3ESkqq0NINmvFwgg5FnLef7DCtve+EEAM7grCQhfwbtFHwAmEhT3G+nU6l579uRhcIyHZAWwCJoBPS/YproZatf5Ub1bG4KYLdAaBwwq2H/gLqXKraDYbJMlPwGagLwbnywAUdjbKwiitWRgGbiP5/Txz3k+SJDvU+ATwSZnxUgAqg8hdeNtY/3QByGkkLGtAL5L93tflBzm/7OzbAojBXUZ6gC6kDhTJMHOp+yJSB95COp+rtPG+JQCVLAtLCta70HyPRMAt4KS+H4nB3X8sADG4P4AALKH4LhxSAOMKdBFwD+iPwbX1Hqr1hBuBX5ELt1LzQNor/q2GV6t6N1CPwU1VMQ4VuuIY3Jix/iJS0Q4iXgO8h3h/KgZ3Q+duFGzRUqr+F6QsAFxCGtbtyM1fmwEwb1lQRelWPUw+1WNngG3I/8EqtGjF4L7r1DjM49cMwFjfC+xW45+3ac3+l0ryL3HyO9i/WjTRAAAAAElFTkSuQmCC"
            alt="VV"
          />
        </IconButton>
        <Typography level="title-lg">Pomiary</Typography>
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
      </Box>
      <Box
        sx={{
          mt: 4,
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          {menu?.map((menuItem) => {
            if (menuItem.nested)
              return (
                <ListItem key={menuItem.label} nested>
                  <Toggler
                    renderToggle={({ open, setOpen }) => (
                      <ListItemButton onClick={() => setOpen(!open)}>
                        {menuItem.icon}
                        <ListItemContent>
                          <Typography level="title-sm">{menuItem.label}</Typography>
                        </ListItemContent>
                        <KeyboardArrowDown sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                      </ListItemButton>
                    )}
                  >
                    <List sx={{ gap: 0.5 }}>
                      {menuItem.nested.map((nestedItem) => (
                        <ListItem key={nestedItem.label} sx={{ mt: 0.5 }}>
                          <ListItemButton
                            component={NavLink}
                            to={nestedItem.to}
                            end={nestedItem.end}
                            disabled={isMenuDisabled}
                          >
                            {nestedItem.icon}
                            <ListItemContent>
                              <Typography
                                level="title-sm"
                                textColor={isMenuDisabled ? 'neutral.softDisabledColor' : 'natural'}
                              >
                                {nestedItem.label}
                              </Typography>
                            </ListItemContent>
                            {/* <Typography level="body-sm">5</Typography> */}
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Toggler>
                </ListItem>
              )
            return (
              <ListItem key={menuItem.label} nested={menuItem.nested}>
                <ListItemButton component={NavLink} to={menuItem.to} end={menuItem.end} disabled={isMenuDisabled}>
                  {menuItem.icon}
                  <ListItemContent>
                    <Typography level="title-sm" textColor={isMenuDisabled ? 'neutral.softDisabledColor' : 'natural'}>
                      {menuItem.label}
                    </Typography>
                  </ListItemContent>
                  {menuItem.injectUsersTasksCount && injectUserTasks && (
                    <Typography level="body-xs" color="success">
                      {myTasks.filter((t) => isDateAfterOrToday(t.date, serverTime)).length}
                    </Typography>
                  )}
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
          }}
        >
          <ListItem>
            <ListItemButton component={NavLink} to={routes.profile} disabled={isMenuDisabled}>
              <PermContactCalendar />
              <ListItemContent>
                <Typography level="title-sm" textColor={isMenuDisabled ? 'neutral.softDisabledColor' : 'natural'}>
                  Mój profil
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography title={`${userInfo.firstName} ${userInfo.lastName.charAt(0)}.`} noWrap level="title-sm">
            {userInfo.firstName} {userInfo.lastName.charAt(0)}.
          </Typography>
          <Typography title={userInfo.email} noWrap level="body-xs">
            {userInfo.email}
          </Typography>
        </Box>
        <IconButton onClick={handleLogout} size="sm" variant="plain" color="neutral">
          <LogoutRounded />
        </IconButton>
      </Box>
    </Sheet>
  )
}

export default Sidebar
