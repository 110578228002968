import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Error } from '@mui/icons-material'
import { Alert, Box, Card, CardContent, Link, Stack, Typography } from '@mui/joy'
import { cleanupRequestStatus, getProjectDetails } from 'features/projectsSlice'
import {
  handleErrorMessage,
  isDateAfterOrToday,
  isDateBeforeOrToday,
  isTaskStatusOpen,
  standarizeStatus,
} from 'utils/helpers'
import { DATE_FORMAT, dateFilters, dateFiltersOptions, openFilters, openFiltersOptions, STATUS } from 'utils/config'
import { projectService } from 'services/projectsService'
import { getUserTasksHeaders } from 'utils/headers/getUserTasksHeaders'
import routes from 'routes'
import usePagination from 'hooks/usePagination'
import useSearchParamValue from 'hooks/useSearchParamValue'
import Breadcrumbs from 'components/Breadcrumbs'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import PageLoader from 'components/PageLoader'
import FilterSelect from 'components/tables/FilterSelect'
import EnchantedTable from 'components/tables/EnchantedTable'

const UserProjectsDetailsView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pagination = usePagination()
  const { projectId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)

  const [dateFilter, setDateFilter] = useSearchParamValue('date', dateFilters.current.value)
  const [openFilter, setOpenFilter] = useSearchParamValue('status', '')

  const { currentProject, loading } = useSelector((s) => s.projects)
  const { userInfo, serverTime } = useSelector((s) => s.auth)

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const filteredTasks = useMemo(() => {
    if (!currentProject || !currentProject.tasks) return []

    let tempProjects = currentProject.tasks || []

    if (dateFilter === dateFilters.current.value)
      tempProjects = tempProjects.filter((t) => isDateAfterOrToday(t.date, serverTime))

    if (openFilter === openFilters.open.value)
      tempProjects = tempProjects.filter((t) => isTaskStatusOpen(t, serverTime))

    return tempProjects
  }, [currentProject, serverTime, dateFilter, openFilter])

  const handleAction = (action, successMessage) => {
    setInnerLoading(true)
    action
      .then((res) => {
        console.log(res)
        enqueueSnackbar(successMessage, { variant: 'success' })
        dispatch(getProjectDetails(projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleApply = (task) =>
    handleAction(projectService.userTaskApply(projectId, task.id), 'Zostałeś zapisany do zadania')

  const handleApplyReserve = (task) =>
    handleAction(
      projectService.userTaskApplyReserve(projectId, task.id),
      'Zostałeś zapisany do listy rezerwowej zadania'
    )

  const handleLeaveReserve = (task) =>
    handleAction(
      projectService.userTaskLeaveReserve(projectId, task.id),
      'Zrezygnowałeś z obecności na liście rezerowej.'
    )

  const handleGoToTaskDetailsPage = (taskId) =>
    navigate(routes.userProjectsDetailsTask.replace(':projectId', projectId).replace(':taskId', taskId), {
      state: { from: currentProject.name },
    })

  if (!projectId) return <Navigate to={routes.userProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  if (!currentProject) return <MainLayout></MainLayout>

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Projekty', to: routes.userProjects }, { label: currentProject?.name || '?' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          columnGap: 3,
          rowGap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Projekt:&nbsp;
          <Typography color="primary">{currentProject?.name}</Typography>
        </Typography>
        {currentProject?.coordinationPhone && (
          <Typography level="h4" component="h3" color="primary">
            Tel. koordynacyjny:{' '}
            <Link underline="hover" href={`tel:${currentProject.coordinationPhone}`}>
              {String(currentProject.coordinationPhone)
                .match(/.{1,3}/g)
                .join(' ')}
            </Link>
          </Typography>
        )}
      </Box>

      {currentProject.infoBanner && (
        <Alert startDecorator={<Error />} variant="soft" color="warning" sx={{ whiteSpace: 'pre-line' }}>
          {currentProject.infoBanner}
        </Alert>
      )}

      <Card orientation="horizontal" variant="outlined">
        <CardContent>
          <Stack rowGap={0.5} columnGap={3} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Lokalizacja:
              </Typography>{' '}
              {currentProject.location}
            </Typography>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Data rozpoczęcia:
              </Typography>{' '}
              {dayjs(currentProject.startDate).format(DATE_FORMAT)}
            </Typography>
            <Typography level="body-sm">
              <Typography color="primary" fontWeight={500}>
                Data zakończenia:
              </Typography>{' '}
              {dayjs(currentProject.endDate).format(DATE_FORMAT)}
            </Typography>
            {currentProject.type === 1 && (
              <Typography level="body-sm">
                <Typography color="primary" fontWeight={500}>
                  Stawka godzinowa brutto:
                </Typography>{' '}
                {currentProject.hourlyRate} zł/h
              </Typography>
            )}
          </Stack>
          <Typography level="body-sm">
            <Typography color="primary" fontWeight={500}>
              Opis projektu:
            </Typography>{' '}
            {currentProject.description}
          </Typography>
        </CardContent>
      </Card>

      <FiltersWrapper
        sx={{ ml: { sm: 'auto' } }}
        renderFilters={
          <>
            <FilterSelect
              label="Filtrowanie po statusie"
              placeholder={openFilters.all.label}
              options={openFiltersOptions}
              value={openFilter}
              setValue={setOpenFilter}
            />
            <FilterSelect
              label="Filtrowanie po dacie"
              placeholder={dateFilters.all.label}
              options={dateFiltersOptions}
              value={dateFilter}
              setValue={setDateFilter}
            />
          </>
        }
      />

      <EnchantedTable
        headers={getUserTasksHeaders(currentProject)}
        data={filteredTasks}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        buttonActions={[
          {
            label: 'Zapisz się',
            color: 'success',
            type: 'button',
            variant: 'solid',
            func: handleApply,
            loading: innerLoading,
            display: (task) => {
              if (currentProject.type === 2) return false

              if (isDateBeforeOrToday(task.date, serverTime)) return false
              if (standarizeStatus(task.status) === STATUS.done.value) return false
              return !task.assignment
            },
          },
          {
            label: 'Lista rezerwowa',
            color: 'primary',
            type: 'button',
            func: handleApplyReserve,
            loading: innerLoading,
            display: (task) => {
              if (currentProject.type === 2) return false

              if (standarizeStatus(task.status) === STATUS.done.value) return false
              if (task.reserveList && task.reserveList.some((id) => id === userInfo.id)) return false
              if (task.assignment && task.assignment.userId === userInfo.id) return false
              return task.assignment
            },
          },
          {
            label: 'Opuść listę',
            color: 'warning',
            type: 'button',
            func: handleLeaveReserve,
            loading: innerLoading,
            display: (task) => {
              if (currentProject.type === 2) return false

              if (standarizeStatus(task.status) === STATUS.done.value) return false
              if (task.assignment && task.assignment.userId === userInfo.id) return false
              return task.reserveList && task.reserveList.some((id) => id === userInfo.id)
            },
          },
          {
            label: 'Zobacz zadanie',
            color: 'primary',
            type: 'button',
            func: (task) => handleGoToTaskDetailsPage(task.id),
            loading: innerLoading,
            display: (task) => task.assignment && task.assignment.userId === userInfo.id,
          },
        ]}
        noDataText="Brak zadań do wyświetlenia."
      />
    </MainLayout>
  )
}

export default UserProjectsDetailsView
