import PropTypes from 'prop-types'
import {
  Box,
  Divider,
  Stack,
  Typography,
  Card,
  AccordionGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from '@mui/joy'

const EngagementDataSubView = ({ userInfo }) => {
  return (
    <Card>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">
          Aktywność:&nbsp;
          <Typography level="body-sm" color="success">
            {userInfo.engagement?.reduce((acc, curr) => acc + curr.tasks.length, 0) || '0'}
          </Typography>
        </Typography>
      </Box>
      <Divider />
      <Stack direction="column" sx={{ mt: 0, mb: 0 }}>
        <AccordionGroup disableDivider>
          {userInfo.engagement.map((eng) => (
            <Accordion key={eng.projectName}>
              <AccordionSummary>
                <Typography level="title-sm">
                  {eng.projectName}:&nbsp;
                  <Typography color="success">{eng.tasks.length}</Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List marker="circle" size="sm" sx={{ ml: 2 }}>
                  {eng.tasks.map((task) => (
                    <ListItem key={task.id}>Nr&nbsp;{task.lp || task.id}</ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </AccordionGroup>
      </Stack>
    </Card>
  )
}

EngagementDataSubView.propTypes = {
  userInfo: PropTypes.shape({
    engagement: PropTypes.array,
  }).isRequired,
}

export default EngagementDataSubView
