import PropTypes from 'prop-types'
import { FormControl, FormLabel, Input } from '@mui/joy'
import { Search } from '@mui/icons-material'
import { useState } from 'react'

const FilterSearch = ({ label, value, setValue, placeholder = 'Szukaj...' }) => {
  const [innerValue, setInnerValue] = useState(value)
  return (
    <FormControl sx={{ flex: 1, minWidth: '1px' }} size="sm">
      {label && <FormLabel>{label}</FormLabel>}
      <Input
        value={innerValue}
        onChange={(e) => setInnerValue(e.target.value)}
        onBlur={() => setValue(innerValue)}
        onKeyDown={(e) => e.key === 'Enter' && e.currentTarget.blur()}
        size="sm"
        placeholder={placeholder}
        startDecorator={<Search />}
      />
    </FormControl>
  )
}
FilterSearch.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
}
export default FilterSearch
