import { Link, Typography } from '@mui/joy'
import { Link as RouterLink } from 'react-router-dom'
import { formatMoney } from '../helpers'
import routes from 'routes'
import UserTooltip from 'components/UI/UserTooltip'

const commonLp = { label: 'Nr', propName: 'lp', className: 'width-xs' }

const commonStatus = {
  label: 'Status',
  propName: 'assignment2',
  type: 'assigmentModeAdmin',
  rowClassName: 'text-center',
  className: 'text-center',
}

const commonAssignmentUser = {
  sortableBy: 'computedAssignmentFullName',
  sortableSide: 'left',
  label: 'Pracownik',
  propName: 'assignment',
  rowClassName: 'text-center',
  className: 'text-center width-xl',
  type: 'computed',
  fn: (task) => {
    const user = task.assignment
    if (!user) return <Typography level="body-xs">–</Typography>
    return (
      <UserTooltip user={user}>
        <Link component={RouterLink} to={routes.adminUsersDetails.replace(':userId', user.userId)} level="title-sm">
          <Typography color="primary" level="body-xs">
            {user.firstName} {user.lastName}
            {task.reserveList && (
              <Typography color="warning" level="body-xs">
                &nbsp;+{task.reserveList.length}
              </Typography>
            )}
          </Typography>{' '}
        </Link>
      </UserTooltip>
    )
  },
}

export const getAdminTasksHeaders = (project) => {
  let headers = [commonLp]

  if (project.type === 1)
    headers.push(
      {
        sortableBy: 'date',
        sortableSide: 'right',
        label: 'Data',
        propName: 'date',
        type: 'date',
        rowClassName: 'text-center',
        className: 'text-center width-sm',
      },
      { label: 'Godziny', propName: 'hours', rowClassName: 'text-center', className: 'text-center' },
      {
        sortableBy: 'hoursCount',
        sortableSide: 'right',
        label: 'Ilość godzin',
        propName: 'hoursCount',
        rowClassName: 'text-center',
        className: 'text-center width-sm',
      },
      {
        sortableBy: 'computedSalary',
        sortableSide: 'left',
        label: 'Wynagrodzenie brutto',
        type: 'computed',
        propName: 'computedSalary',
        rowClassName: 'text-center',
        className: 'text-center',
        fn: (data) => {
          if (!project || !project.hourlyRate || !data.hoursCount) return '-'
          return <div className="money">{formatMoney(project.hourlyRate * Number(data.hoursCount))}</div>
        },
      },
      {
        sortableBy: 'localization',
        sortableSide: 'left',
        label: 'Lokalizacja',
        propName: 'localization',
        rowClassName: 'text-center',
        className: 'text-center',
      },
      commonAssignmentUser,
      commonStatus
    )
  else if (project.type === 2)
    headers.push(
      {
        sortableBy: 'date',
        sortableSide: 'right',
        label: 'Termin',
        propName: 'date',
        type: 'date',
        rowClassName: 'text-center',
        className: 'text-center width-sm',
      },
      { label: 'Nr punktu', propName: 'pointNumber', rowClassName: 'text-center', className: 'text-center' },
      { label: 'Typ punktu ', propName: 'pointType', rowClassName: 'text-center', className: 'text-center' },
      { label: 'Długość ', propName: 'duration', rowClassName: 'text-center', className: 'text-center' },
      { label: 'SDR ', propName: 'sdr', rowClassName: 'text-center', className: 'text-center' },
      {
        label: 'Wynagrodzenie brutto',
        type: 'computed',
        propName: 'not_used',
        rowClassName: 'text-center',
        className: 'text-center',
        fn: (data) => formatMoney(data.rate),
      },
      commonAssignmentUser,
      commonStatus
    )

  return headers
}
