import { useMemo, useState } from 'react'
import { ArrowDropDown, MoreHorizRounded } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dropdown,
  IconButton,
  Link,
  ListDivider,
  Menu,
  MenuButton,
  MenuItem,
  Sheet,
  Table,
  Typography,
} from '@mui/joy'
import { ArrowDownward } from '@mui/icons-material'
import { visuallyHidden } from '@mui/utils'
import { EnhancedTableToolbarTypesSchema, tablePropTypesSchema } from './helpers'
import { getComparator } from 'utils/helpers'
import BaseTableList from 'components/tables/BaseTableList'
import TableRowContent from './TableRowContent'

const EnhancedTableToolbar = (props) => {
  const { selected, bulkActions } = props
  return (
    <Box
      sx={[
        {
          display: selected.length > 0 ? 'flex' : 'none',
          alignItems: 'center',
          py: 1,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 2 },
          bgcolor: 'primary.softActiveBg',
          borderTop: '1px solid var(--joy-palette-divider)',
          position: 'sticky',
          bottom: '0',
        },
      ]}
    >
      <Typography level="title-sm" sx={{ flex: '1 1 100%' }} component="div">
        {selected.length > 0 ? `Wybrano: ${selected.length}` : ''}
      </Typography>
      <Dropdown>
        <MenuButton size="sm" endDecorator={<ArrowDropDown />} sx={{ backgroundColor: 'background.body' }}>
          <Typography level="title-sm">Wybierz&nbsp;akcje</Typography>
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }} placement="bottom-end">
          {bulkActions.map((action) => (
            <Sheet key={action.label}>
              {action.divider && <ListDivider />}
              <MenuItem
                color={action.color || 'primary'}
                onClick={() => action.func(selected)}
                disabled={selected.length === 0}
              >
                {action.label} ({selected.length})
              </MenuItem>
            </Sheet>
          ))}
        </Menu>
      </Dropdown>
    </Box>
  )
}

EnhancedTableToolbar.propTypes = EnhancedTableToolbarTypesSchema

const EnchantedTable = (props) => {
  const { headers, data = [], actions, buttonActions, bulkActions, noDataText } = props

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState(null)
  const [selected, setSelected] = useState([])

  const dataToDisplay = useMemo(() => {
    if (!orderBy) return [...(data || [])]
    return [...data].sort(getComparator(order, orderBy))
  }, [data, order, orderBy])

  const handleSort = (property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleSelectAll = (e) => {
    if (e.target.checked) return setSelected(dataToDisplay.map((i) => i))
    setSelected([])
  }

  const handleSelect = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  if (!data.length)
    return (
      <Alert variant="soft" color="neutral" size="md">
        {noDataText ? noDataText : 'Brak danych do wyświetlenia.'}
      </Alert>
    )

  return (
    <>
      <Sheet
        className="ProjectsTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
          display: { xs: 'none', lg: 'block' },
        }}
      >
        <Table
          results={dataToDisplay.length}
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          noWrap
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level)',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-selectedBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
            '& thead th': {
              '--TableCell-paddingY': '12px',
              '--TableCell-paddingX': '6px',
              backgroundColor: 'var(--joy-palette-background-surface)',
            },
            '& thead th:first-of-type': { paddingLeft: '20px' },
            '& thead th:last-of-type': { paddingRight: '20px' },
            '& tr > td:first-of-type': { paddingLeft: '20px' },
            '& tr > td:last-of-type': { paddingRight: '20px' },
            '& th.checkbox, & td.checkbox': { paddingRight: '20px', paddingLeft: '20px', width: '60px' },
            '& thead th.width-xs': { width: '80px' },
            '& thead th.width-xm': { width: '100px' },
            '& thead th.width-sm': { width: '120px' },
            '& thead th.width-md': { width: '140px' },
            '& thead th.width-lg': { width: '160px' },
            '& thead th.width-xl': { width: '180px' },
            '& thead th.width-xxl': { width: '200px' },
            '& thead th.width-auto': { width: 'auto' },
            '& thead th.min-width-auto': { width: 'auto' },
            '& .text-center': { textAlign: 'center' },
            '& .text-right': { textAlign: 'right' },
            '& .actions': { textAlign: 'center' },
            minWidth: '800px',
          }}
        >
          <thead>
            <tr>
              {bulkActions && (
                <th className="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < dataToDisplay.length}
                    checked={dataToDisplay.length > 0 && selected.length === dataToDisplay.length}
                    onChange={handleSelectAll}
                    slotProps={{ input: { 'aria-label': 'zaznacz wszystkie' } }}
                    sx={{ verticalAlign: 'sub' }}
                  />
                </th>
              )}
              {headers.map((header) => {
                const active = orderBy === header.sortableBy
                return (
                  <th
                    key={header.propName}
                    className={header.className}
                    aria-sort={active ? { asc: 'ascending', desc: 'descending' }[order] : undefined}
                  >
                    {header.sortableBy ? (
                      <Link
                        underline="none"
                        textColor={active ? 'danger.500' : undefined}
                        component="button"
                        onClick={() => handleSort(header.sortableBy || header.propName)}
                        startDecorator={
                          header.sortableSide === 'left' && (
                            <ArrowDownward
                              sx={{ position: 'absolute', left: '-1em', top: '50%', opacity: active ? 1 : 0 }}
                            />
                          )
                        }
                        endDecorator={
                          header.sortableSide === 'right' && (
                            <ArrowDownward
                              sx={{ position: 'absolute', right: '-1em', top: '50%', opacity: active ? 1 : 0 }}
                            />
                          )
                        }
                        sx={{
                          position: 'relative',
                          fontWeight: 'lg',
                          '& svg': {
                            transition: '0.2s',
                            fontSize: 'lg',

                            transform:
                              order === 'desc' ? 'translateY(-50%) rotate(0deg)' : 'translateY(-50%) rotate(180deg)',
                          },
                          '&:hover': { '& svg': { opacity: 1 } },
                        }}
                      >
                        {header.label}
                        {active ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </Link>
                    ) : (
                      header.label
                    )}
                  </th>
                )
              })}
              {(actions || buttonActions) && (
                <th style={{ width: actions ? 100 : 'auto', textAlign: 'center' }}>Akcje</th>
              )}
            </tr>
          </thead>
          <tbody>
            {dataToDisplay.map((row, index) => {
              const isItemSelected = selected.includes(row)
              const labelId = `enhanced-table-checkbox-${index}`
              return (
                <tr
                  key={row.id || index}
                  className="hilightCell"
                  style={
                    isItemSelected
                      ? {
                          '--TableCell-dataBackground': 'var(--TableCell-selectedBackground)',
                          '--TableCell-headBackground': 'var(--TableCell-selectedBackground)',
                        }
                      : {}
                  }
                >
                  {bulkActions && (
                    <td scope="row" className="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        slotProps={{ input: { 'aria-labelledby': labelId } }}
                        sx={{ verticalAlign: 'top' }}
                        onChange={(e) => handleSelect(e, row)}
                      />
                    </td>
                  )}
                  {headers.map((header) => (
                    <td key={header.propName + row.id} className={header.rowClassName}>
                      <TableRowContent row={row} header={header} />
                    </td>
                  ))}
                  {(actions || buttonActions) && (
                    <td className="actions">
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                          alignItems: 'center',
                          justifyContent: buttonActions ? 'center' : 'flex-start',
                        }}
                      >
                        {buttonActions &&
                          buttonActions.length &&
                          buttonActions.map((btnAction) => {
                            if (btnAction.display && !btnAction.display(row)) return ''
                            return (
                              <Button
                                key={btnAction.label}
                                size="xs"
                                variant={btnAction.variant ? btnAction.variant : 'outlined'}
                                color={btnAction.color || ''}
                                onClick={() => btnAction.func(row)}
                                loading={btnAction.loading || false}
                                startDecorator={btnAction.decor ? btnAction.decor : null}
                                disabled={btnAction.checkIfDisable ? btnAction.checkIfDisable(row) : false}
                              >
                                {btnAction.label}
                              </Button>
                            )
                          })}
                        {actions && actions.length && (
                          <Dropdown>
                            <MenuButton
                              sx={{ m: 'auto' }}
                              slots={{ root: IconButton }}
                              slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
                            >
                              <MoreHorizRounded />
                            </MenuButton>
                            <Menu size="sm" sx={{ minWidth: 140 }} placement="bottom-end">
                              {actions.map((action) => {
                                if (action.display && !action.display(row)) return null
                                return (
                                  <Sheet key={action.label}>
                                    {action.divider && <ListDivider />}
                                    <MenuItem
                                      color={action.color || 'primary'}
                                      onClick={() => action.func(row)}
                                      disabled={action.checkIfDisable ? action.checkIfDisable(row) : false}
                                    >
                                      {action.label}
                                    </MenuItem>
                                  </Sheet>
                                )
                              })}
                            </Menu>
                          </Dropdown>
                        )}
                      </Box>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
        {bulkActions && <EnhancedTableToolbar selected={selected} bulkActions={bulkActions} />}
      </Sheet>
      {/* MOBILE ONLY */}
      <BaseTableList {...props} />
    </>
  )
}

EnchantedTable.propTypes = tablePropTypesSchema

export default EnchantedTable
