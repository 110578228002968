import PropTypes from 'prop-types'
import { FormikProvider } from 'formik'
import { Info } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardOverflow, Divider, Stack, Typography } from '@mui/joy'
import CustomUploadFileControl from 'components/forms/CustomDragAndDropFileControl'
import FileUpload from 'components/FileUpload'

const UserFilesUploadSubView = ({ formik, loading }) => (
  <FormikProvider value={formik}>
    <form onSubmit={formik.handleSubmit}>
      <Card
        sx={{
          display: 'flex',
          maxWidth: '700px',
          width: '100%',
          mx: 'auto',
          px: { xs: 2, md: 3 },
          py: { xs: 2, md: 3 },
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md" startDecorator={<Info />} color="primary">
            Wgraj pliki z rozwiązaniem
            <Typography level="body-sm" color="danger">
              &nbsp;(max. 5 MB)
            </Typography>
          </Typography>
        </Box>
        <Divider />
        <Stack spacing={2} sx={{ my: 1 }}>
          <CustomUploadFileControl
            name="files"
            accept={{
              'text/csv': ['.csv'],
              'application/vnd.ms-excel': ['.xls', '.xlsx'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
              'application/pdf': ['.pdf'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/png': ['.png'],
            }}
            multiple
            formik={formik}
            label={null}
            maxSize={5_000_000}
          />
          {formik.values.files &&
            formik.values.files.map((file, i) => (
              <FileUpload
                key={i}
                fileName={file.name}
                fileSize={file.size}
                handleDelete={() => {
                  formik.setFieldValue(
                    'files',
                    formik.values.files.filter((f) => f.path !== file.path)
                  )
                }}
              />
            ))}
        </Stack>
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button type="submit" disabled={!formik.dirty} size="sm" variant="solid" loading={loading}>
              Wyślij
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </form>
  </FormikProvider>
)

UserFilesUploadSubView.propTypes = {
  formik: PropTypes.object.isRequired,
  loading: PropTypes.bool,
}

export default UserFilesUploadSubView
