import parseHtml from 'html-react-parser'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Box, Button, Divider, Link, Typography, Stack } from '@mui/joy'
import { cleanupRequestStatus, loginUser } from 'features/authSlice'
import { Info, Warning } from '@mui/icons-material'
import { authService } from 'services/authService'
import { CUSTOM_ERROR_CODES, ROLES } from 'utils/config'
import routes from 'routes'
import loginSchema from 'validations/auth/loginSchema'
import CustomInputControl from 'components/forms/CustomInputControl'
import AuthLayout from 'components/templates/AuthLayout'
import PageLoader from 'components/PageLoader'
import { useSnackbar } from 'notistack'
import { handleErrorMessage } from 'utils/helpers'

const getErrorMsg = (err) => {
  if (err == CUSTOM_ERROR_CODES.EMAIL_NOT_VERIFIED) return 'E-mail nie został zweryfikowany'
  return err
}
const LoginView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { state } = useLocation()

  const [innerLoading, setInnerLoading] = useState(null)

  const { loading, error, userInfo } = useSelector((s) => s.auth)

  useEffect(() => {
    return () => dispatch(cleanupRequestStatus())
  }, [])

  // redirect authenticated user to profile screen
  useEffect(() => {
    if (!userInfo) return
    if (userInfo.role === ROLES.user.value) {
      if (userInfo.isAccountConfirmed && !userInfo.birthDate) navigate(routes.user)
      else navigate(routes.userMyTasks)
    }
    if (userInfo.role === ROLES.admin.value) navigate(routes.adminProjects)
  }, [navigate, userInfo])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: (values) => dispatch(loginUser(values)),
  })

  const handleResendConfirmation = () => {
    setInnerLoading(true)

    authService
      .resendConfirmationEmail({ email: formik.values.email })
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Link został wysłany ponownie.', { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  if (userInfo) return <PageLoader />

  return (
    <AuthLayout>
      <Typography component="h1" level="h3">
        Logowanie
      </Typography>
      <Stack sx={{ gap: 4, mt: 2 }}>
        {error && (
          <Stack sx={{ gap: 2 }}>
            <Alert color="danger" variant="soft" startDecorator={<Warning />}>
              {getErrorMsg(error)}
            </Alert>
            {error == CUSTOM_ERROR_CODES.EMAIL_NOT_VERIFIED && (
              <Button
                type="button"
                fullWidth
                variant="outlined"
                disabled={innerLoading}
                loading={innerLoading}
                onClick={handleResendConfirmation}
              >
                Wyślij link aktywujący
              </Button>
            )}
          </Stack>
        )}
        {state?.message && (
          <Alert color="success" variant="soft" startDecorator={<Info />}>
            <Typography level="body-sm">{parseHtml(state.message)}</Typography>
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <CustomInputControl name="email" label="E-mail" formik={formik} fullWidth />
          <CustomInputControl name="password" label="Hasło" formik={formik} fullWidth type="password" />
          <Stack sx={{ gap: 4, mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                // justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Link level="title-sm" component={RouterLink} to={routes.resetPassword}>
                Zapomniałeś hasła?
              </Link>
            </Box>
            <Button type="submit" fullWidth disabled={loading} loading={loading}>
              Zaloguj
            </Button>
          </Stack>
        </form>
        <Divider
          sx={(theme) => ({
            [theme.getColorSchemeSelector('light')]: {
              color: { xs: '#FFF', md: 'text.tertiary' },
            },
          })}
        >
          lub
        </Divider>
        <Typography level="body-sm">
          Jesteś nowy?{' '}
          <Link component={RouterLink} to={routes.register} level="title-sm">
            Zapisz się!
          </Link>
        </Typography>
      </Stack>
    </AuthLayout>
  )
}

export default LoginView
