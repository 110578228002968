import { CustomYup } from 'validations/CustomYup'

const newTaskType1Schema = CustomYup.object().shape({
  lp: CustomYup.string().required(),
  date: CustomYup.string().required(),
  hours: CustomYup.string().required(),
  hoursCount: CustomYup.string().required(),
  localization: CustomYup.string().required(),
  notes: CustomYup.string(),
  publishedAt: CustomYup.string().nullable(),
})

const newTaskType2Schema = CustomYup.object().shape({
  lp: CustomYup.string().required(),
  pointNumber: CustomYup.string().required(),
  pointType: CustomYup.string().required(),
  numberOfCameras: CustomYup.number().min(0, 'Minimum 1').required(),
  numberOfDirections: CustomYup.number().min(0, 'Minimum 1').required(),
  date: CustomYup.string().required(),
  duration: CustomYup.string().required(),
  sdr: CustomYup.string().required(),
  rate: CustomYup.number().min(0, 'Stawka nie może być mniejsza niż 0.').required(),
  notes: CustomYup.string(),
  publishedAt: CustomYup.string().nullable(),
  links: CustomYup.array()
    .of(CustomYup.string().required())
    .min(1, 'Podanie linku jest wymagane.')
    .required()
    .nullable(),
  files: CustomYup.array().nullable(),
})

export const addTasksType1Schema = CustomYup.object().shape({
  tasks: CustomYup.array().of(newTaskType1Schema).min(1, 'Musisz uzupełnic przynajmniej 1 zadanie.'),
})

export const addTasksType2Schema = CustomYup.object().shape({
  tasks: CustomYup.array().of(newTaskType2Schema).min(1, 'Musisz uzupełnic przynajmniej 1 zadanie.'),
})
